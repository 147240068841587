import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams } from '@ionic/angular';

import { LanguageService } from './service';
import { TranslatePipe } from './pipe';
export * from './service';
export * from './pipe';

@NgModule({
	imports: [
		IonicModule,
	  	FormsModule,
		ReactiveFormsModule,
		HttpClientModule
	],
	exports : [
		TranslatePipe
	],
	declarations:[
		TranslatePipe
	]
})
export class LanguageModule {
	static forRoot(): ModuleWithProviders<LanguageModule> {
		return {
			ngModule: LanguageModule,
			providers: [LanguageService],
		};
	}
}