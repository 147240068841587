import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { LoadingController,ToastController,NavParams,MenuController,ModalController } from '@ionic/angular';
import { Subscription } from 'dissys';

import { APIService } from '../api/module';
import { LanguageService } from '../language/module';
import { BaseComponent } from '../object-editor/common';

@Component({
	selector: 'auth-modal',
	templateUrl: './modal.html',
	styleUrls: ['./modal.scss'],
})
export class Modal extends BaseComponent{

	protected authSub : Subscription = null;

	constructor(
		activatedRoute: ActivatedRoute,
		translate: LanguageService,
		modalController : ModalController,
		loadingctrl : LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		public menuctrl : MenuController,
		public api : APIService,
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
		);
	}

	ionViewWillEnter(){
		this.menuctrl.enable(false, 'main-navgation-menu');
		this.authSub = this.api.auth.onAuth.subscribe(auth=>{
			this.closeModal();
		});
	}

	ionViewWillLeave(){
		if(this.authSub){
			this.authSub.unsubscribe();
			this.authSub = null;
		}
	}

	async login(cred){
		this.showLoading();
		try{
			let d = await this.api.auth.authorizeByCredentials(cred.form.value.username,cred.form.value.password);
		}
		catch(e){
			if(e.error && e.error.error){
				console.error(e.error.error.msg);
				this.presentToast(`msg_error_${e.error.error.tag}`);
			}
			else{
				console.log(e);
				this.presentToast('msg_error_bad_auth_cred');
			}
		}
		finally{
			this.hideLoading();
		}
	}

	async closeModal() {
		await this.modalController.dismiss();
	}

	async presentToast(tag : string) {
		let txt = await this.translate.get(tag);
		const toast = await this.toastCtrl.create({
			message: txt,
			duration: 2000
		});
		toast.present();
	}

	gotoHome(){
		this.closeModal();
		this.router.navigateByUrl('/');
	}
}
