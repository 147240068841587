import { Component, OnInit, ViewChild, ViewChildren,Input,AfterViewInit,ElementRef,QueryList,forwardRef } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { ModalController,LoadingController,NavParams,MenuController,IonMenu } from '@ionic/angular'
import { MenuI,Components,Side } from '@ionic/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'

import { APIService } from '../api/module';
import { LanguageService } from '../language/module';
import { InstanceSelector } from '../instance-selector/module';
import { NavItem } from './common';

@Component({
  selector: 'navigation-sub-menu',
  templateUrl: './navigation-sub-menu.component.html',
  //styleUrls: ['./navigation-sub-menu.scss'],
})
export class NavigationSubMenuComponent implements AfterViewInit{

	protected _items : NavItem[];
	protected window = window;

	constructor(
		protected api : APIService
		,protected router : Router
		,protected isel : InstanceSelector
	){
	}

	ngAfterViewInit(){
	}

	@Input()
	set items(items:NavItem[]){
		let ni = []
		for(let i of items){
			if(i.perms){
				if(!this.api.auth.authed)
					continue;
				let ok = true;
				for(let perm of i.perms){
					/*if(!this.api.identity.hasPermSync(perm)){
						ok = false;
						break;
					}*/
				}
				if(!ok)
					continue
			}
			ni.push(i);
		}
		this._items = items;
	}

	get items(){
		return this._items;
	}

	protected submenus : {[key:string]:boolean} = {};

	toggleMenu(event,item){
		let name = item.label;
		if(this.submenus[name] === undefined)
			this.submenus[name] = true;
		else
			this.submenus[name] = !this.submenus[name];

		if(!item.isOpen)
			item.isOpen = true;
		else
			item.isOpen = false;
		event.stopPropagation();
		event.preventDefault();
	}

}