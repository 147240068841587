import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, Renderer2, ComponentFactoryResolver,forwardRef,Type, Directive, ComponentRef, ViewChild, ElementRef, TemplateRef, ViewContainerRef, TypeDecorator } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController, MenuController } from '@ionic/angular'
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClassMeta, ObjectMeta, objectMeta, Loader, ObjDB, ClassMapperMeta, ExpandDesc } from 'dissys';
import { BaseComponent, Constructor } from './common';
import * as common from './common';
import { LanguageService } from '../language/module';

export type VisualizerSelector = (meta:ClassMeta)=>boolean;
export const VisualizerSelectors = {
	Type : function(...types : Array<ClassMeta|string|{new(...args):any}>) : VisualizerSelector{
		return (meta:ClassMeta)=>types.reduce((a,v)=>a||meta.isSubtypeOf(v),false);
	},
	Or : function(...selectors : VisualizerSelector[]){
		return (meta:ClassMeta)=>selectors.reduce((a,v)=>a||v(meta),false);
	},
	And : function(...selectors : VisualizerSelector[]){
		return (meta:ClassMeta)=>selectors.reduce((a,v)=>a&&v(meta),true);
	}
};

type RegistryEntry = {
	component: Type<ObjectVisualizerComponent>,
	selectors: Array<VisualizerSelector>,
	p:number,
};

class NotFound extends Error{}


@Component({})
export abstract class ObjectVisualizerBaseComponent<T = any> extends BaseComponent implements OnInit{

	protected typeMeta : ClassMeta = null;
	protected typeInfo : string | ClassMeta | {new(...args):any} = null;
	protected _readonly = false;
	protected initPromResolve;
	protected initProm : Promise<void> = new Promise(r=>{this.initPromResolve = r;});
	protected expand : ExpandDesc;
	protected _db ?: ObjDB = null;
	protected injectors;
	protected _provider ?: Loader<T>|Array<any>|Set<any> = null;

	@Output() readonly onChange = new EventEmitter<any>();
	@Output() readonly onTouched = new EventEmitter<any>();
	@Output() readonly onOpenIntend = new EventEmitter<{type:ClassMeta|{new(...args):any},cb:(value)=>void,config?:{[key:string]:any}}>();

	@Input()
	set db(value : ObjDB){
		this._db = value;
	}

	get db(){
		return this._db;
	}

	@Input()
	set provider(value : Loader<T>|Array<any>|Set<any>){
		this._provider = value;
	}

	get provider(){
		return this._provider;
	}

	@Input()
	set type(value : string|ClassMeta|{new(...args)}){
		this.typeInfo = value;
	}

	get type() : ClassMeta{
		return this.typeMeta
	}

	get invalid() : boolean{
		return !this.valid;
	}

	get valid() : boolean{
		return true;
	}

	@Input()
	set value(val : T){
		this.writeValue(val);
	}

	get value() : T{
		return this.readValue();
	}

	@Input()
	set readonly(b : boolean){
		this._readonly = b;
	}

	get readonly(){
		return this._readonly;
	}

	get ready() : Promise<void>{
		return this.initProm;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		protected componentFactoryResolver: ComponentFactoryResolver

	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
		);
	}

	abstract writeValue(val : T): void;
	abstract readValue(): T;

	ngOnInit(){
		this.init().then(()=>this.initPromResolve());
	}

	isready() : boolean{
		return this._db != null && this.typeInfo != null;
	}

	protected async init() : Promise<void>{
		await this.db.ready;
		let r = this.getClassMeta(this.typeInfo);
		this.typeMeta = r[0];
		if(!this._provider) this._provider = r[1];
		if(this.value){
			try{
				let meta = ClassMeta.getMeta(this.value);
				if(meta.isSubtypeOf(this.typeMeta)) this.typeMeta = meta;

			}
			catch(e){}			
		}
	}

	registerOnChange(fn: any): void{
		this.onChange.subscribe(fn);
	}

	registerOnTouched(fn: any): void{
		this.onTouched.subscribe(fn);
	}

	setDisabledState(isDisabled: boolean): void{
		this.readonly = isDisabled;
	}

	protected loadComponent<T>(container : ViewContainerRef, component : Type<T>) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(component);
		container.clear();
		const componentRef = container.createComponent<T>(componentFactory);
		return componentRef;	
	}

	protected getClassMeta(type : string | ClassMeta | {new(...args):any} | object) : [ClassMeta,Loader]{
		let tname = typeof type === 'string' || type instanceof String ? <string>type : type instanceof ClassMapperMeta ? type.mapped_qualname : type instanceof ClassMeta ? type.qualname : typeof type === 'function' || type instanceof Function ? type.name : type.constructor.name;
		let provider : Loader;
		let meta : ClassMeta;
		if(typeof type === 'string' || type instanceof String){
			if(!this.db) throw "could not resolve type " + type + ' because no db definded';
			provider = this.db[<string>type];
			if(!provider){
				console.error(`no provider for ${type} in db`);
				meta = this.db.context[<string>type];
			}
			else meta = provider.meta;
		}
		else if(type instanceof ClassMapperMeta){
			meta = type;
			if(this.db) provider = this.db[type.mapped_qualname];
		}
		else if(type instanceof ClassMeta){
			if(this.db){
				provider = this.db[type.qualname];
				meta = provider ? provider.meta : type;
			}
			else{
				meta = type;
			}
		}
		else{
			let v : Function = typeof type === 'function' || type instanceof Function ? type : type.constructor;
			if('__metadata__' in v){
				meta = (<any>v).__metadata__;
			}
			else if(this.db){
				provider = this.db[v.name];
				meta = provider.meta;
			}
			else{
				throw "could not resolve type " + tname + ' because no db definded and given type '+v.name+' does not provide __metadata__ property';
			}
		}
		return [meta,provider];
	}
}

@Component({})
export abstract class ObjectVisualizerComponent<T = any> extends ObjectVisualizerBaseComponent{

	private static componentRegistry : RegistryEntry[] = [];
	private static configRegistry : {config:{[key:string]:any},selectors:VisualizerSelector[]}[] = [];

	static registerComponent(ctor, selector : VisualizerSelector, ...selectors : VisualizerSelector[]);
	static registerComponent(ctor, p : number, selector : VisualizerSelector, ...selectors : VisualizerSelector[]);
	static registerComponent(ctor, ...args : any[]){
		let p = 0.5;
		if(typeof args[0] === 'number'){
			p = args[0];
			args.shift();
		}
		ObjectVisualizerComponent.componentRegistry.push({
			component : ctor,
			selectors : args,
			p:p
		});
	}

	static getComponent(meta:ClassMeta){
		let e = null;
		for(let entry of ObjectVisualizerComponent.componentRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta),true)){
				if(e === null || e.p < entry.p) e = entry;
			}
		}
		if(e) return e.component;
		console.error(meta);
		throw new NotFound(`no component found for ${meta.qualname}`);
	}

	static getConfig(meta:ClassMeta){
		for(let entry of ObjectVisualizerComponent.configRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta),true)) return entry.config;
		}
		console.error(meta);
		throw new NotFound(`no component found for ${meta.qualname}`);
	}

	static registerConfig(config : {[key:string]:any}, ...selectors : VisualizerSelector[]){
		ObjectVisualizerComponent.configRegistry.push({
			config : config,
			selectors : selectors,
		});
	}

	public _placeholder = '';

	@Input()
	set placeholder(txt:string){
		this._placeholder = txt;
	}

	get placeholder(){
		return this._placeholder;
	}

}

export function ObjectVisualizer(selector : VisualizerSelector, ...selectors : VisualizerSelector[]);
export function ObjectVisualizer(p : number, selector : VisualizerSelector, ...selectors : VisualizerSelector[]);
export function ObjectVisualizer(...args){
	return <T>(ctor : T)=>{
		args.unshift(ctor);
		ObjectVisualizerComponent.registerComponent.apply(ObjectVisualizerComponent,args);
		return ctor
	};
} 

@Component({
	selector:'object-visualizer',
	template:'<ng-template #compslot></ng-template>',
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectVisualizerComponentLoader),
		}
	],
})
export class ObjectVisualizerComponentLoader<T = any> extends ObjectVisualizerComponent{

	protected cfg;
	protected componentInstance : ComponentRef<ObjectVisualizerComponent> = null;
	protected _value;
	protected __overrides__;

	@ViewChild('compslot', {static: true, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;

	@Input()
	set config(c){
		this.cfg = c;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver
		);
	}

	protected async init(){
		await super.init();
		this.loadPlugins();
	}

	reset(){
		this.loadPlugins();
	}

	writeValue(value){
		this._value = value;
		if(this.componentInstance) this.componentInstance.instance.writeValue(value);
	}

	readValue(){
		return this.componentInstance ? this.componentInstance.instance.value : this._value;
	}

	get db(){
		return this.componentInstance ? this.componentInstance.instance.db : this._db;
	}

	set db(value){
		this._db = value;
		if(this.componentInstance)
			this.componentInstance.instance.db = value;
	}

	protected loadPlugins() {
		let comp : Type<ObjectVisualizerComponent>;
		if(this.value && Object.getPrototypeOf(this.value).constructor.__metadata__){
			let tm = Object.getPrototypeOf(this.value).constructor.__metadata__
			if(!this.typeMeta || this.typeMeta.isBasetypeOf(tm)) this.typeMeta = tm;
		}		
		if(this.typeMeta){
			comp = ObjectVisualizerComponent.getComponent(this.typeMeta);
		}		
		if(comp && this.pluginHost){
			if(this.componentInstance){
				for(let i in this.__overrides__) try{delete this[i];}catch(e){}
				this.componentInstance.destroy();
			}
			const componentRef = this.loadComponent(this.pluginHost,comp);
			this.componentInstance = componentRef;
			//componentRef.instance.data = adItem.data;
			this.componentInstance.instance.onOpenIntend.subscribe((d)=>{
				/*let subComp : Type<any>;
				if(!('component' in d)){
					if(d.type instanceof ClassMeta){
						subComp = ObjectVisualizerComponent.getByMeta(d.type);
					}
					else{
						subComp = ObjectVisualizerComponent.getByType(d.type);
					}
				}
				else subComp = d.component;*/
				this.showModal(
					ObjectVisualizerModalComponentLoader,
					false,
					(val)=>{
						if(val && val.data !== undefined)
							d.cb(val.data);
					},
					{type:d.type,db:this.db,...(d.config||{})}
				);
			});
			let d = {};



			(<any>this.componentInstance.instance).onChange = this.onChange;
			(<any>this.componentInstance.instance).onTouched = this.onTouched;
			this.componentInstance.instance.setOptions({
				db:this._db,
				value:this._value,
				type:this.typeMeta,
				...this.cfg
			})
			
			/*for(let i of Object.getOwnPropertyNames(componentRef.instance)){
				//console.log('define',i)
				if(i.substring(0,2) == '__' || i in d) continue;
				try{
					Object.defineProperty(this,i,{
						get:()=>{
							console.log('getter override',this,i,componentRef.instance[i]);
							if(typeof componentRef.instance[i] == 'function' || componentRef instanceof Function){
								return (...args)=>{
									return componentRef.instance[i](...args)
								}
							}
							else
								return componentRef.instance[i];
						},
						set:(value)=>{componentRef.instance[i] = value; this['_'+name] = componentRef.instance[i];},
					});
					d[i] = true;
					console.debug(`patched attribute ${i}`);
				}
				catch(e){
					console.error(`failed patching ${i}`);
				}
			}
			let stack = [componentRef.instance.constructor];
			while(stack.length > 0){
				let j = stack.shift();
				console.log('proto',j)
				//if(j.constructor && j.constructor != Object) stack.push(j.constructor);
				if(Object.getPrototypeOf(j)) stack.push(Object.getPrototypeOf(j));
				if(j.prototype) for(let i of Object.getOwnPropertyNames(j.prototype)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__'|| i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{
								console.log('getter override base',this,i,componentRef);
								if(typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function){
									return (...args)=>{
										return componentRef.instance[i](...args)
									}
								}
								else
									return componentRef.instance[i];
							},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}
				for(let i of Object.getOwnPropertyNames(j)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__' || i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{console.log('getter override',this,i,componentRef);return typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function ? (...args)=>{return componentRef.instance[i](...args);} : componentRef.instance[i];},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}				
			}
			this.__overrides__ = d;
			//if(cfg) for(let i in cfg) componentRef.instance[i] = cfg[i];*/
		}
	}

}

@Component({
	selector:'object-visualizer-modal',
	templateUrl:'object-visualizer-modal.component.html',
	styleUrls:[
		'./common.scss',
		'./object-visualizer-modal.component.scss',
	],
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectVisualizerModalComponentLoader),
		}
	],
})
export class ObjectVisualizerModalComponentLoader<T = any> extends ObjectVisualizerComponentLoader<T>{
	@ViewChild('compslot', {static: false, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;
	protected _dataloaded: Promise<any>;
	protected addNew : boolean = false;
	protected len = 0;
	protected componentProps : any;
	
	//ionViewWillEnter(){
	//	if(this.db && this.typeMeta) this.provider = this.db.getLoader(this.typeMeta);
	//}

	ngOnInit(){
		
	}

	showAddNew(){
		this.addNew = true;
		setTimeout(()=>this.loadPlugins(),1000);
	}
}
