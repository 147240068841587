import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams } from '@ionic/angular';

import { Module as ObjectEditorModule } from '../object-editor/module';
import { LanguageModule } from '../language/module';
import { NavigationMenuModule } from '../navigation-menu/module';

import { InspectorComponent } from './inspector.component';
import { ModuleComponent } from './module.component';
export * from './inspector.component';
export * from './module.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		LanguageModule,
		ObjectEditorModule,
		NavigationMenuModule,
	],
	declarations: [
		InspectorComponent,
		ModuleComponent,
	],
	entryComponents:[
		ModuleComponent
	],
	exports:[
		InspectorComponent,
		ModuleComponent,
	],
	providers:[
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [
	]
})
export class Module {}