import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, ViewContainerRef, ComponentFactoryResolver,forwardRef,Type, Directive, QueryList } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, AbstractControl, ControlValueAccessor,NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClassMeta, ObjectMeta, objectMeta, PropertyMeta, ParameterMeta, listMeta, dictMeta, setMeta, NoneTypeMeta, fixBinary } from 'dissys';
import { ObjectEditor, ObjectEditorComponent, EditorSelectors } from './object-editor.component';
import { ObjectPropertyEditorComponent } from './object-property-editor.component';
import { DynComponentLoaderComponent, Modes, Section } from './common';
import { TypeConfig, PropRules } from './type-config';

@ObjectEditor(EditorSelectors.Type(ObjectMeta,objectMeta,Object,'object'))
@Component({
	selector: 'object-base-editor',
	templateUrl:'./object-base-editor.component.html',
	styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ObjectBaseEditor),
	    }
	],
})
export class ObjectBaseEditor extends ObjectEditorComponent{

	protected formgroup = new FormGroup({});
	protected _value;
	protected subscriptions : Subscription[] = [];
	protected mode : 'create'|'edit';
	protected inited : Promise<void> = null;
	protected preventChangeEmit : boolean = false;
	protected constuctParamMap : {[key:string]:ParameterMeta|PropertyMeta} = {};
	protected constuctAttrMap : {[key:string]:PropertyMeta|PropertyMeta} = {};
	protected propMap :  {create:{[key:string]:ParameterMeta|PropertyMeta},edit:{[key:string]:ParameterMeta|PropertyMeta}} = {create:{},edit:{}};
	protected sections : {create:Array<Section>,edit:Array<Section>} = {create:[],edit:[]};
	protected sectionMap :  {create:{[key:string]:Section},edit:{[key:string]:Section}} = {create:{},edit:{}};
	protected controls :  {create:{[key:string]:AbstractControl},edit:{[key:string]:AbstractControl}} = {create:{},edit:{}};
	protected injectors : Array<string>;
	protected trigger = 1;
	protected readonlyProps = [];
	
	@ViewChildren('comps') protected comps : QueryList<ObjectEditorComponent>;
	@ViewChildren('fileDownloadHelper') protected downloadHelper;

	readValue(){
		return this._value;
	}

	writeValue(value){
		if(!this.inited){
			this._value = value;
		}
		else if(value === undefined || value === null){
			for(let i in this.value){
				if(i in this.formgroup.controls)
					this.formgroup.controls[i].value = null;
			}
		}
		else if(value.constructor === Object){
			this.preventChangeEmit = true;
			try{
				if(this.mode == 'create'){
					this._value = value;
					for(let i in this.formgroup.controls)
						this.formgroup.controls[i].value = value[i];
					
				}
				else{
					for(let i in this.formgroup.controls)
						this.formgroup.controls[i].value = value[i];
				}
			}
			finally{
				this.preventChangeEmit = false;
			}
			this.onChange.emit(this._value);
		}
		else if(!this.typeMeta.instanceOf(value)) throw `given value ${value} is not an instance of ${this.typeMeta.qualname}`;
		else if(this.mode == 'create'){
			let r = this.getClassMeta(value);
			this.typeMeta = r[0];
			this.provider = r[1];
			this._value = value;
			this.inited = null;
			this.init();
		}
		else if(this.typeMeta.instanceOf(value,false)){
			this._value = value;
			for(let i in this.formgroup.controls)
				this.formgroup.get(i).setValue(value[i]);
		}
		else{
			let r = this.getClassMeta(value);
			this.typeMeta = r[0];
			this.provider = r[1];
			this._value = value;
			this.inited = null;
			this.init();
		}
	}

	isready() : boolean{
		return super.isready() && this.comps != null;
	}

	protected setType(type : ClassMeta){
		this.typeMeta = type;
		this.typeInfo = type.qualname;
		this.inited = null;
		this.initProm = new Promise(r=>{this.initPromResolve = r;});
		this.propMap = {create:{},edit:{}};
		this.sections = {create:[],edit:[]};
		this.sectionMap = {create:{},edit:{}};
		this.controls = {create:{},edit:{}};
		this.formgroup = new FormGroup({});
		this.trigger++;
		this.init();
	}

	protected init(){
		if(!this.inited)
			this.inited = super.init().then(()=>{
				console.log('TYPEMETA',this.typeMeta)
				if(this.typeMeta.isabstract && this.typeMeta.children.length == 1) this.typeMeta = this.typeMeta.children[0];
				this.readonlyProps = []
				this.sections = {create:[],edit:[]};
				this.sectionMap = {create:{},edit:{}};
				let section : Section = {label:'__required__',type:'simple',props:[],subeditors:[]}; 
				this.sections.create.push(section);
				this.sectionMap.create.__required__ = section;
				section = {label:'__optional__',type:'simple',props:[],subeditors:[]}; 
				this.sections.create.push(section);
				this.sectionMap.create.__optional__ = section;

				if(this.value === null || this.value === undefined){
					this.mode = 'create';
					this._value = {__class__:this.typeMeta.qualname};
					for(let i of this.typeMeta.construct_params) this._value[i.name] = i.has_default ? i.default : undefined;
					this.onChange.emit(this._value);
				}
				else if(!this.value.id){
					this.mode = 'create';
				}
				else{
					this.mode = 'edit';
				}

				let tconfig = TypeConfig.get(this.typeMeta);
				let propRules = {};
				for(let prop in this.typeMeta.attributes){
					let v = 0;
					if(tconfig.props) for(let pv of tconfig.props)
						v = pv(this.typeMeta.attributes[prop],v);
					propRules[prop] = v;
				}

				for(let i of this.typeMeta.construct_params){
					if(this.injectors && this.injectors.indexOf(i.name) > -1) continue;
					else if(
						(propRules[i.name] & PropRules.Hide) == PropRules.Hide
					) continue;
					this.propMap.create[i.name] = i;
					if(NoneTypeMeta.isBasetypeOf(i.type)){

					}
					else if(i.type.isSubtypeOf('abc.Emitter')){

					}
					else if(
						listMeta.isBasetypeOf(i.type)
					){
						let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
						this.controls.create[i.name] = new FormControl(this.value[i.name]);
						this.sectionMap.create[i.name] = {
							label:i.name,
							type:'complex',
							props:[],
							subeditors:[{
								component: e.component,
								loader: e.loader,
								ctlname: i.name,
								type: i,
								control: this.controls.create[i.name],
							}]
						};
						this.sections.create.push(this.sectionMap.create[i.name]);
					}
					else if(dictMeta.isBasetypeOf(i.type) && this.db.context['wackytype.structure.KnownDict'].isBasetypeOf(i.type)){
						let fgcontrols = {}
						let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
						this.controls.create[i.name] = new FormControl(this.value[i.name]);
						this.sectionMap.create[i.name] = {
							label:i.name,
							type:'complex',
							props:[],
							subeditors:[{
								component: e.component,
								loader: e.loader,
								ctlname:i.name,
								type:i,
								control:this.controls.create[i.name],
							}]
						};
						this.sections.create.push(this.sectionMap.create[i.name]);
					}
					else{
						let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
						if(!e.component) console.log('ERROR no component for ',i.type);
						if(i.optional || i.has_default){
							this.controls.create[i.name] = new FormControl(this._value[i.name]);
							this.sectionMap.create['__optional__'].props.push({
								control: this.controls.create[i.name],
								loader: e.loader,
								component: e.component,
								type: i,
							});
						}
						else{
							this.controls.create[i.name] = new FormControl(this._value[i.name],Validators.required);
							this.sectionMap.create['__required__'].props.push({
								control: this.controls.create[i.name],
								loader: e.loader,
								component: e.component,
								type: i,
							});
						}						
					}
				}
				//add inputs for attributes not covered by the construction parameters
				for(let k in this.typeMeta.attributes){
					let i = this.typeMeta.attributes[k];
					if(this.injectors && this.injectors.indexOf(i.name) > -1) continue;
					else if(
						(propRules[i.name] & PropRules.Hide) == PropRules.Hide
					) continue;
					//if(!(i.name in this.controls.create)){
					if(!(i.name in this.propMap.create)){
						let ok = true;
						for(let km in i.mods) if([
							'wackytype.metadata.static',
							'wackytype.metadata.readonly',
							'wackytype.metadata.key',
							'wackytype.metadata.private',
							'wackytype.metadata.calculated',
						].indexOf(i.mods[km].qualname) > -1) {ok = false; break;}
						if(ok){
							this.propMap.create[i.name] = i;
							if(
								listMeta.isBasetypeOf(i.type)
							){
								let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
								this.controls.create[i.name] = new FormControl(this.value[i.name]);
								this.sectionMap.create[i.name] = {
									label:i.name,
									type:'complex',
									props:[],
									subeditors:[{
										component: e.component,
										loader: e.loader,
										ctlname: i.name,
										type: i,
										control: this.controls.create[i.name],
									}]
								};
								this.sections.create.push(this.sectionMap.create[i.name]);
							}
							else if(i.type.isSubtypeOf('abc.Emitter','dissys.Emitter')){}
							else if(setMeta.isBasetypeOf(i.type) || this.db.context['wackytype.structure.KnownDict'].isBasetypeOf(i.type)){
								let fgcontrols = {}
								let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
								this.controls.create[i.name] = new FormControl(this.value[i.name]);
								this.sectionMap.create[i.name] = {
									label:i.name,
									type:'complex',
									props:[],
									subeditors:[{
										component: e.component,
										loader: e.loader,
										ctlname: i.name,
										type: i,
										control: this.controls.create[i.name],
									}]
								};
								this.sections.create.push(this.sectionMap.create[i.name]);
							}
							else{
								let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
								if(!e.component) console.log('ERROR no component for ',i.type);
								if(i.optional || i.has_default || i.impref){
									this.controls.create[i.name] = new FormControl(this._value[i.name]);
									this.sectionMap.create['__optional__'].props.push({
										control: this.controls.create[i.name],
										loader: e.loader,
										type: i,
										component: e.component,
									});
								}
								else{
									this.controls.create[i.name] = new FormControl(this._value[i.name],Validators.required);
									this.sectionMap.create['__required__'].props.push({
										control: this.controls.create[i.name],
										loader: e.loader,
										type: i,
										component: e.component,
									});
								}						
							}
						}
					}
				}
				//sort out not needed empty sections
				this.sections.create = this.sections.create.filter(i=>i.props.length>0 || i.subeditors.length > 0);

			if(this.mode == 'edit'){
				section = {label:'__required__',type:'simple',props:[],subeditors:[]}; 
				this.sections.edit.push(section);
				this.sectionMap.edit.__required__ = section;
				section = {label:'__optional__',type:'simple',props:[],subeditors:[]}; 
				this.sections.edit.push(section);
				this.sectionMap.edit.__optional__ = section;

				for(let k in this.typeMeta.attributes){
					let i = this.typeMeta.attributes[k];
					if(this.injectors && this.injectors.indexOf(i.name) > -1) continue;
					else if(
						(propRules[i.name] & PropRules.Hide) == PropRules.Hide
					) continue;

					let ok = true;
					for(let km in i.mods) if([
						'wackytype.metadata.static',
						'wackytype.metadata.private',
					].indexOf(i.mods[km].qualname) > -1) {ok = false; break;}
					
					if(!ok) continue;
					this.propMap.edit[i.name] = i;
					if(
						listMeta.isBasetypeOf(i.type)
					){
						let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,<any>i);
						this.controls.edit[i.name] = new FormControl(this.value[i.name]);
						this.sectionMap.edit[i.name] = {
							label:i.name,
							type:'complex',
							props:[],
							subeditors:[{
								component: e.component,
								loader: e.loader,
								ctlname: i.name,
								type: i,
								control: this.controls.edit[i.name],
							}]
						};
						this.sections.edit.push(this.sectionMap.edit[i.name]);
					}
					else if(i.type.isSubtypeOf('abc.Emitter','dissys.Emitter')){}
					else if(
						setMeta.isBasetypeOf(i.type) || 
						this.db.context['wackytype.structure.KnownDict'].isBasetypeOf(i.type) ||
						this.db.context['wackytype.structure.UniformDict'].isBasetypeOf(i.type)
					){
						let fgcontrols = {}
						let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,i);
						this.controls.edit[i.name] = new FormControl(this.value[i.name]);
						this.sectionMap.edit[i.name] = {
							label:i.name,
							type:'complex',
							props:[],
							subeditors:[{
								component: e.component,
								loader: e.loader,
								ctlname:i.name,
								type:i,
								control: this.controls.edit[i.name],
							}]
						};
						this.sections.edit.push(this.sectionMap.edit[i.name]);
					}
					else{
						for(let km in i.mods) if([
							'wackytype.metadata.readonly',
						].indexOf(i.mods[km].qualname) > -1 || i.iskeycomp || (propRules[i.name] & PropRules.Readonly) == PropRules.Readonly){
							ok = false;
							this.readonlyProps.push(i);
							break;
						};
						if(ok){
							let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,i);
							if(!e.component) console.log('ERROR no component for ',i.type,e);
							if(i.optional || i.has_default){
								this.controls.edit[i.name] = new FormControl({ value: this._value[i.name], disabled: i.isreadonly || i.iskeycomp });
								this.sectionMap.edit['__optional__'].props.push({
									control: this.controls.edit[i.name],
									loader: e.loader,
									component: e.component,
									type: i,
								});
							}
							else{
								this.controls.edit[i.name] = new FormControl({ value: this._value[i.name], disabled: i.isreadonly || i.iskeycomp },Validators.required);
								this.sectionMap.edit['__required__'].props.push({
									control: this.controls.edit[i.name],
									loader: e.loader,
									component: e.component,
									type: i,
								});
							}
						}
					}
				}

				//sort out not needed empty sections
				this.sections.edit = this.sections.edit.filter(i=>i.props.length>0 || i.subeditors.length > 0);
			}

			this.formgroup = new FormGroup(this.controls[this.mode]);
			this.formgroup.valueChanges.subscribe(d=>{
				let c = 0;
				console.log('FORM changes',d)
				for(let i in d){
					if(this.formgroup.get(i).dirty){
						console.log('Control changed',i,d[i]);
						this._value.__isdirty__ = true;
						this.formgroup.get(i).markAsUntouched();
						this._value[i] = d[i];
						c++;
					}
				}
				this.formgroup.markAsUntouched();
				if(this._value.constructor === Object)
					this._value.__class__ = this.typeMeta.qualname;
				if(c>0){
					this.onTouched.emit();
					this.onChange.emit(this._value);
				}
				this.formgroup.markAsUntouched();
			});
			
			this.comps.changes.subscribe(d=>{
				for(let i of this.subscriptions) i.unsubscribe();
				this.subscriptions = [];
				for(let i of this.comps){
					if((<any>i).componentRef) (<any>i).componentRef.instance.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
				}
			});
			for(let i of this.comps){
				if((<any>i).componentRef) (<any>i).componentRef.instance.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
			}
		});

		return this.inited;
	}
	
	protected async execMethod(prop : PropertyMeta, e ?: Event){
		if(e){
			e.stopPropagation();
			e.preventDefault();
		}
		this.showLoading();
		console.debug('exec method',prop,'on',this.value);
		let returnType = prop.type.attributes.__return__.default.type;
		try{
			if(!returnType || returnType.isBasetypeOf('builtins.NoneType')){
				await this._value[prop.name](); 
			}
			else if(returnType.isBasetypeOf('builtins.str')){
				let value = await this._value[prop.name]();
				let blob = new Blob([value], { type: 'text/plain; charset=utf-8' });
				this.downloadHelper.first.nativeElement.href = URL.createObjectURL(blob);
				(<HTMLLinkElement>this.downloadHelper.first.nativeElement).setAttribute('download',this._value.name);
				(<HTMLLinkElement>this.downloadHelper.first.nativeElement).click();

			}
			else if(returnType.isSubtypeOf('fpdf.fpdf.FPDF')){
				let base64data = await this._value[prop.name]();
				base64data = base64data.substring(base64data.indexOf('data:')+5);
				let blob = new Blob([fixBinary(atob(base64data))], { type: 'application/pdf; charset=utf-8' });
				this.downloadHelper.first.nativeElement.href = URL.createObjectURL(blob);
				(<HTMLLinkElement>this.downloadHelper.first.nativeElement).setAttribute('download',this._value.name);
				(<HTMLLinkElement>this.downloadHelper.first.nativeElement).click();
			}
			else{
				console.error('no action defined');
			}
		}
		catch(e){
			console.error(`error while exec method ${prop.name}:`);
			console.error(e);
			this.presentToast('error');
		}
		this.hideLoading();
	}
}



		
