import { Component, OnInit , AfterViewInit, Input, Output, Renderer2, EventEmitter, ViewChildren, ComponentFactoryResolver,forwardRef,Type, Directive, ElementRef, ComponentRef, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController,IonInput, MenuController } from '@ionic/angular'
import { Validators, FormBuilder, FormGroup, FormControl, AbstractControl,ValidationErrors } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClassMeta, ParameterMeta, PropertyMeta, ObjectMeta, objectMeta, IntMeta, FloatMeta, StrMeta, ListMeta, boolMeta, SetMeta, setMeta, ExpandDesc } from 'dissys';
import { BaseComponent } from './common';
import { ObjectVisualizerComponent, ObjectVisualizerComponentLoader } from './object-visualizer.component';
import { ObjectBaseVisualizer } from './object-base-visualizer.component';
import * as common from './common';
import { LanguageService } from '../language/module';

import { ObjectEditorComponent, ObjectEditorComponentLoader, ObjectEditorBaseComponent } from './object-editor.component';
import { ObjectBaseEditor } from './object-base-editor.component';

const DefProp = new PropertyMeta('',null);

export type PropertyVisualizerSelectors = (meta:ClassMeta,prop:PropertyMeta)=>boolean;
export type PropertyVisualizerLoader = (component:object)=>void;
export const PropertyVisualizerSelectors = {
	Type : function(...types : Array<ClassMeta|string|{new(...args):any}>){
		return (meta:ClassMeta,prop:PropertyMeta)=>types.reduce((a,v)=>a||prop.type.isSubtypeOf(v),false);
	},
	Prop : function PropSelector(...props : string[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>props.reduce((a,v)=>a||prop.name == v,false);
	},
	Or : function(...selectors : PropertyVisualizerSelectors[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>selectors.reduce((a,v)=>a||v(meta,prop),false);
	},
	And : function(...selectors : PropertyVisualizerSelectors[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>selectors.reduce((a,v)=>a&&v(meta,prop),true);
	},
	Name : function(...names : string[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>names.reduce((a,v)=>a||v==prop.name,false);
	}
};

type RegistryEntry = {
	component: Type<ObjectPropertyVisualizerComponent>,
	selectors: Array<PropertyVisualizerSelectors>,
	p:number,
	loader:PropertyVisualizerLoader,
};

class NotFound extends Error{}

@Component({})
export abstract class ObjectPropertyVisualizerComponent<T = any> extends ObjectEditorBaseComponent{

	protected propertyMeta : PropertyMeta = DefProp;
	protected _placeholder : string = '';
	protected _object : any = null;

	@Input()
	set placeholder(txt:string){
		this._placeholder = txt;
	}

	get placeholder(){
		return this._placeholder;
	}

	@Input()
	set object(o : any){
		this._object = o;
	}

	get object(){
		return this._object;
	}

	@Input()
	set prop(prop : PropertyMeta){
		this.propertyMeta = prop
	}

	get prop(){ return this.propertyMeta; }

	private static componentRegistry : RegistryEntry[] = [];
	private static configRegistry : {config:{[key:string]:any},selectors:PropertyVisualizerSelectors[]}[] = [];

	static registerComponent(ctor, selector : PropertyVisualizerSelectors, loader?:PropertyVisualizerLoader);
	static registerComponent(ctor, p : number, selector : PropertyVisualizerSelectors, loader?:PropertyVisualizerLoader);
	static registerComponent(ctor, ...args : any[]){
		let p = 0.5;
		if(typeof args[0] === 'number'){
			p = args[0];
			args.shift();
		}
		ObjectPropertyVisualizerComponent.componentRegistry.push({
			component : ctor,
			selectors : [args[0]],
			p:p,
			loader:args[1],
		});
	}

	static getComponent(meta:ClassMeta,prop:PropertyMeta) : RegistryEntry{
		let e = null;
		let reg = ObjectPropertyVisualizerComponent.componentRegistry;
		for(let i of reg){
			let entry = i;
			if(entry.selectors.reduce((a,v)=>a&&v(meta,prop),true)){
				if(e === null || e.p < entry.p) e = entry;
			}
		}
		if(e){ 	return e; }
		console.error(meta)
		console.error(prop)
		throw new NotFound(`no component found for ${meta.qualname}.${prop.name}`);
	}

	static getConfig(meta:ClassMeta,prop:PropertyMeta){
		for(let entry of ObjectPropertyVisualizerComponent.configRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta,prop),true)) return entry.config;
		}
		throw new NotFound(`no component found for ${meta.qualname}.${prop.name}`);
	}

	static registerConfig(config : {[key:string]:any}, ...selectors : PropertyVisualizerSelectors[]){
		ObjectPropertyVisualizerComponent.configRegistry.push({
			config : config,
			selectors : selectors,
		});
	}

	static extendExpand(name : string, expand : ExpandDesc){}
}

export function ObjectPropertyVisualizer(selector : PropertyVisualizerSelectors, onLoad ?: PropertyVisualizerLoader);
export function ObjectPropertyVisualizer(p : number, selector : PropertyVisualizerSelectors, onLoad ?: PropertyVisualizerLoader);
export function ObjectPropertyVisualizer(...args : any[]){
	return <T>(ctor : T)=>{
		args.unshift(ctor)
		ObjectPropertyVisualizerComponent.registerComponent.apply(ObjectPropertyVisualizerComponent,args);
		return ctor
	};
}


@Component({
	selector:'object-property-visualizer',
	template:'<ng-template #compslot></ng-template>',
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectPropertyVisualizerComponentLoader),
		}
	],
})
export class ObjectPropertyVisualizerComponentLoader<T = any> extends ObjectPropertyVisualizerComponent{

	protected cfg;
	protected componentInstance : ComponentRef<ObjectPropertyVisualizerComponent> = null;
	protected _value;
	protected usedPropMeta : PropertyMeta;
	protected __overrides__;
	protected loadedObjVis = false;

	@ViewChild('compslot', {static: true, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;

	@Input()
	set config(c){
		this.cfg = c;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		elementRef : ElementRef,
		componentFactoryResolver: ComponentFactoryResolver
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver
		);
	}

	protected async init(){
		await super.init();
		this.loadPlugins();
	}

	reset(){
		this.loadPlugins();
	}

	writeValue(value){
		this._value = value;
		if(this.componentInstance) this.componentInstance.instance.writeValue(value);
	}

	readValue(){
		return this.componentInstance ? this.componentInstance.instance.value : this._value;
	}

	get db(){
		return this.componentInstance ? this.componentInstance.instance.db : this._db;
	}

	set db(value){
		this._db = value;
		if(this.componentInstance)
			this.componentInstance.instance.db = value;
	}

	get prop(){
		return this.componentInstance ? this.componentInstance.instance.prop : this.usedPropMeta;
	}

	set prop(value){
		this.propertyMeta = value;
		this.usedPropMeta = value
		if(this.componentInstance && !this.loadedObjVis)
			this.componentInstance.instance.prop = value;
		else if(this.componentInstance && this.loadedObjVis && value)
			this.componentInstance.instance.type = value.type;
	}

	get type(){
		return this.componentInstance ? this.componentInstance.instance.type : this.typeMeta;
	}

	set type(value){
		this.typeInfo = value;
		if(this.componentInstance)
			this.componentInstance.instance.type = value;
	}

	get value(){
		return this.componentInstance ? this.componentInstance.instance.value : this._value;
	}

	set value(value){
		if(value === this._value) return;
		this._value = value;
		if(this.componentInstance){
			if(value && Object.getPrototypeOf(value).constructor.__metadata__ && Object.getPrototypeOf(value).constructor.__metadata__ != this.usedPropMeta.type)
				this.loadPlugins()
			else
				this.componentInstance.instance.value = value;
		}
	}

	protected loadPlugins() {
		let comp : Type<ObjectPropertyVisualizerComponent>;
		if(this._value && this.propertyMeta && this.propertyMeta.type && Object.getPrototypeOf(this._value).constructor.__metadata__ && Object.getPrototypeOf(this._value).constructor.__metadata__.isSubtypeOf(this.propertyMeta.type)){
			this.usedPropMeta = new PropertyMeta(this.propertyMeta);
			this.usedPropMeta.type = Object.getPrototypeOf(this._value).constructor.__metadata__
		}
		else{
			this.usedPropMeta = this.propertyMeta
		}
		if(this.typeMeta && this.usedPropMeta){
			try{
				comp = ObjectPropertyVisualizerComponent.getComponent(this.typeMeta,this.usedPropMeta).component;
			}
			catch(e){
				if(!(e instanceof NotFound)) throw e;
			}
		}

		if(!comp && this.usedPropMeta){
			comp = ObjectVisualizerComponent.getComponent(this.usedPropMeta.type);
			this.loadedObjVis = true;
		}		
		if(comp && this.pluginHost){
			if(this.componentInstance){
				for(let i in this.__overrides__) try{delete this[i];}catch(e){}
				this.componentInstance.destroy();
			}
			const componentRef = this.loadComponent(this.pluginHost,comp);
			this.componentInstance = componentRef;
			//componentRef.instance.data = adItem.data;
			this.componentInstance.instance.onOpenIntend.subscribe((d)=>{
				/*let subComp : Type<any>;
				if(!('component' in d)){
					if(d.type instanceof ClassMeta){
						subComp = ObjectPropertyVisualizerComponent.getByMeta(d.type);
					}
					else{
						subComp = ObjectPropertyVisualizerComponent.getByType(d.type);
					}
				}
				else subComp = d.component;*/
				console.error('notimplementedmodal')
				/*this.showModal(
					ObjectPropertyVisualizerModalComponentLoader,
					false,
					(val)=>{
						if(val && val.data !== undefined)
							d.cb(val.data);
					},
					{type:d.type,db:this.db,...(d.config||{})}
				);*/
			});
			let d = {};



			(<any>this.componentInstance.instance).onChange = this.onChange;
			(<any>this.componentInstance.instance).onTouched = this.onTouched;
			if(this.loadedObjVis){
				this.componentInstance.instance.setOptions({
					db:this._db,
					value:this._value,
					type:this.usedPropMeta.type,
					...this.cfg
				});
			}
			else{
				this.componentInstance.instance.setOptions({
					db:this._db,
					value:this._value,
					type:this.typeMeta,
					prop:this.usedPropMeta,
					...this.cfg
				});
			}
			
			/*for(let i of Object.getOwnPropertyNames(componentRef.instance)){
				//console.log('define',i)
				if(i.substring(0,2) == '__' || i in d) continue;
				try{
					Object.defineProperty(this,i,{
						get:()=>{
							console.log('getter override',this,i,componentRef.instance[i]);
							if(typeof componentRef.instance[i] == 'function' || componentRef instanceof Function){
								return (...args)=>{
									return componentRef.instance[i](...args)
								}
							}
							else
								return componentRef.instance[i];
						},
						set:(value)=>{componentRef.instance[i] = value; this['_'+name] = componentRef.instance[i];},
					});
					d[i] = true;
					console.debug(`patched attribute ${i}`);
				}
				catch(e){
					console.error(`failed patching ${i}`);
				}
			}
			let stack = [componentRef.instance.constructor];
			while(stack.length > 0){
				let j = stack.shift();
				console.log('proto',j)
				//if(j.constructor && j.constructor != Object) stack.push(j.constructor);
				if(Object.getPrototypeOf(j)) stack.push(Object.getPrototypeOf(j));
				if(j.prototype) for(let i of Object.getOwnPropertyNames(j.prototype)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__'|| i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{
								console.log('getter override base',this,i,componentRef);
								if(typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function){
									return (...args)=>{
										return componentRef.instance[i](...args)
									}
								}
								else
									return componentRef.instance[i];
							},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}
				for(let i of Object.getOwnPropertyNames(j)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__' || i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{console.log('getter override',this,i,componentRef);return typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function ? (...args)=>{return componentRef.instance[i](...args);} : componentRef.instance[i];},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}				
			}
			this.__overrides__ = d;
			//if(cfg) for(let i in cfg) componentRef.instance[i] = cfg[i];*/
		}
	}

}