import { HttpClientModule, HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable,EventEmitter, Optional, Inject } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { API, ObjDB, ClassMeta, AuthManager, ClassMapperMetaMeta, datetimeMeta, APISocket } from 'dissys';
import { HTTPService, Response } from '../http';
import { LanguageService } from '../language/service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { InstanceSelector } from '../instance-selector/module';

export class RequestError extends Error{
	constructor(public readonly r : Response){
		super(`got status ${r.status} ${r.msg}`);
	}
}

@Injectable({providedIn:'root'})
export class APIService extends API{

	protected loading_modal : Promise<HTMLIonLoadingElement>;
	protected msgmap = {};
	protected _info;
	protected configs = {};
	public defaultCurrency : string = 'EUR';

	constructor(
		protected http : HTTPService,
		protected storage : Storage,
		protected lang : LanguageService,
		protected loadingctrl: LoadingController,
		protected isel: InstanceSelector,
	){
		super('');
		console.log('API',this);
		this.isel.onChange.subscribe(async (d)=>{
			const url = d[0] + (d[0][d[0].length-1]=='/'?'':'/');
			const info = d[1];
			if(this._url === url) return;
			this._url = url;
			if(info) this._info = info
			else this._info = await this.loadApiInfo();
		});
		this.auth.onAuth.subscribe(async ()=>{
			//await this.loadClassMetadata('dissys/db/obj2rel');
			//this.showLoading();
			try{
				await this.db.ready;
			}
			catch(e){

			}
			try{
				for(let i in this.db.supportedTypes) this.registerTypeMetaData(this.db.supportedTypes[i]);
			}
			catch(e){
				console.error(`error while registering db types to api:`);
				console.error(e);
			}
			try{
				let lang = await this.httpRequest(`db/mbt.Translation({lang:db['mbt.Language']({code:'${this.lang.currentLang}'})[0]})`,'GET');
			}
			catch(e){
				console.error('error while loading language data:');
				console.error(e);
			}
			console.log('language data',lang);
			//this.hideLoading();
			this.getAppConfig('platform').then(cfg=>{
				this.defaultCurrency = cfg.values.currency_unit.label;
			});
		});

		//this.db.onLoadStart.subscribe(()=>this.showLoading());
		//this.db.onLoadEnd.subscribe(()=>this.hideLoading());
	}

	get info(){return this._info;}

	async loadApiInfo(){
		return (await this.http.request('GET',`${this.url}`,undefined,{'Accept':'application/json'})).body;
	}

	static async loadApiInfo(http : HTTPService, url : string){
		return (await http.request('GET',url,undefined,{'Accept':'application/json'})).body;
	}

	async getAppConfig(name ?: string){
		if(!(name in this.configs)) 
			this.configs[name] = (await this.db.getLoader('mbt.ApplicationConfig').select(null,{name:name},{'*':{values:{'*':{}}}}) ) [0];
		return this.configs[name];
	}

	httpRequest(url : string, method : string, headers ?: {[key:string]:string}, body ?: string, async_parsing = false) : Promise<string>{
		headers || (headers = {});
		if(this.auth.authed) headers.Authorization = 'JWT '+ this.auth.access_token;
		return super.httpRequest(url,method,headers,body,true);
	}

	protected async load(){
		//this.showLoading();
		try{
			await this.loadClassMetadata('dissys');
		}
		catch(e){
			console.error(e);
			throw e;
		}
		finally{
			//this.hideLoading();
		}
	}

	protected async onBeforeReady() : Promise<void>{
		await super.onBeforeReady();
	}

	protected async rawHttpRequest(url : string, method : string, headers : {[key:string]:string}, body : string) : Promise<any>{
		const res = await this.http.request(method,url,body,headers);
		if(res.status === 200){
			return res;
		}
		else{
			throw new RequestError(res);
		}
	}

	protected async storeKeyValue(key : string, value : string) : Promise<void>{
		return this.storage.set(key,value);
	}

	protected async deleteKeyValue(key : string) : Promise<void>{
		return this.storage.remove(key);
	}

	protected async restoreKeyValue(key : string) : Promise<string|null|undefined>{
		return this.storage.get(key);
	}
	
	setInstance(instanceData, url : string){
		(<any>this).url = url+'/';
		//this.load(); //this line caused double loading
	}

	moduleEnabled(modulename : string) : boolean{
		for(const mod of this.info.modules){
			if(mod.name === modulename) return !mod.hidden;
		}
		return false;
	}
	/*protected async showLoading() {
		if(!this.loading_modal){
			this.loading_modal = new Promise(async (r,e)=>{
				let modal = await this.loadingctrl.create({
				  message: await this.lang.get('please_wait'),
				  //duration: 2000
				});
				await modal.present();
				r(modal);
			});
		}
		return this.loading_modal;
	}

	protected async hideLoading(){
		if(this.loading_modal){
			let modal = await this.loading_modal;
			this.loading_modal = null;
			return modal.dismiss();
		}
		return new Promise<void>(r=>r());
	}*/

}

@Injectable({providedIn:'root'})
export class APISocketService extends APISocket{
	protected  webSocket: WebSocketSubject<any>;
	public readonly onConnect = new EventEmitter();
	public readonly onDisconnect = new EventEmitter();
	protected alivePingTimer = null;

	constructor(
		api : APIService
	){
		super(api);
		this.api.auth.onAuth.subscribe(()=>{
			this.connectedSocket();
		});
		if(this.api.auth.authed) this.connectedSocket();
	}

	protected sendData(data){
		this.webSocket.next(data);
	}

	protected connectedSocket(){
		let urlo = new URL(this.api.url);
		let wsurl = `${urlo.protocol=='https:'?'wss':'ws'}://${urlo.host}/__socket__`;
		console.log(`connect websocket to: ${wsurl}`)
		function dataTransmission(data) {
		  // Deine eigene Logik zur Datenübertragung hier
		  return data;
		}
		this.webSocket = webSocket({
			url:wsurl,
			protocol:this.api.auth.access_token,
			deserializer: (msg) => msg, // Deaktiviere die Deserialisierung
			serializer: dataTransmission,
		});

		
		this.onConnect.emit();
		this.alivePingTimer = setInterval(()=>{
			this.sendCMD('/auth/me');
		},30000);
		this.webSocket.subscribe(
			msg=>{
				console.log('websocket msg: ',msg);
				this.processMSG(msg.data);
			},
			err=>{
				if(err instanceof CloseEvent){
					this.onDisconnect.emit();
					this.webSocket = null;
					console.info('websocket connection closed');
					console.error(err);
					setTimeout(this.connectedSocket.bind(this),5000);
				}
				else if(!this.webSocket){
					console.info('websocket connection error');
					setTimeout(this.connectedSocket.bind(this),5000);
				}
				else{
					console.error('websocket error: ');
					console.error(err);
				}
			},
			()=>{
				if(this.webSocket){
					this.onDisconnect.emit();
					this.webSocket = null;
					console.info('websocket connection closed');
					setTimeout(this.connectedSocket.bind(this),5000);
					clearInterval(this.alivePingTimer)
				}
			}
		);
		debugger;
		const types = Object.values(this.api.db.supportedTypes).sort((a,b)=>b.mro_index-a.mro_index);
		debugger;
		const res = {};
		for(const i of types) res['<'+i.overriddenQualname+'>'] = i.minExpand;
		this.setGlobal('expand',res);

	}
}