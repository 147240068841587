import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams, } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgxRerenderModule } from 'ngx-rerender';

import { LanguageModule } from '../language/module';
import { PropertyAccessModule } from '../property-access';

import { BaseComponent, DynComponentLoaderComponent, PaginatorComponent } from './common';
import { ObjectVisualizerComponent, ObjectVisualizer, ObjectVisualizerComponentLoader, ObjectVisualizerModalComponentLoader } from './object-visualizer.component';
import { ObjectEditorComponent, ObjectEditor, ObjectEditorComponentLoader, ObjectEditorModalComponentLoader } from './object-editor.component';
import {
	ObjectPropertyEditorComponent,
	ObjectPropertyEditorComponentLoader,
	FloatObjectPropertyEditorComponent,
	IntObjectPropertyEditorComponent,
	BoolObjectPropertyEditorComponent,
	StrObjectPropertyEditorComponent,
	TextObjectPropertyEditorComponent,
	ListPropertyBaseEditorComponent,
	SetPropertyBaseEditorComponent,
	KnownDictPropertyEditorComponent,
	UniformDictPropertyEditorComponent,
	ObjectPropertyBaseEditorComponent,
	DatetimeObjectPropertyEditorComponent,
	BytesObjectPropertyEditorComponent,
} from './object-property-editor.component';
import { ObjectBaseEditor } from './object-base-editor.component';
import {
	ObjectBaseVisualizer,
	BooleanObjectValueVisualizer,
	AtomicObjectValueVisualizer,
	DateObjectVisualizer,
	KnownDictPropertyVisualizerComponent,
	UniformDictPropertyVisualizerComponent,
	SetObjectVisualizerComponent,
} from './object-base-visualizer.component';
import {
	ObjectPropertyVisualizerComponent,
	ObjectPropertyVisualizerComponentLoader,
} from './object-property-visualizer.component';
import { TypesSelector } from './types-selector.component';
import { ObjectTableComponent } from './object-table.component';
export { TypeConfig } from './type-config';

export * from './common';
export * from './object-visualizer.component';
export * from './object-editor.component';
export * from './object-property-editor.component';
export * from './object-base-editor.component';
export * from './object-base-visualizer.component';
export * from './object-property-visualizer.component';
export * from './object-table.component';
export * from './type-config';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		LanguageModule,
		PropertyAccessModule,
		NgxRerenderModule,
	],
	declarations: [
		BaseComponent,
		DynComponentLoaderComponent,
		ObjectVisualizerComponentLoader,
		ObjectEditorComponentLoader,
		ObjectEditorModalComponentLoader,
		ObjectBaseEditor,
		ObjectBaseVisualizer,
		ObjectTableComponent,
		ObjectVisualizerModalComponentLoader,
		//property editors
		ObjectPropertyEditorComponentLoader,
		FloatObjectPropertyEditorComponent,
		IntObjectPropertyEditorComponent,
		BoolObjectPropertyEditorComponent,
		StrObjectPropertyEditorComponent,
		TextObjectPropertyEditorComponent,
		ListPropertyBaseEditorComponent,
		SetPropertyBaseEditorComponent,
		KnownDictPropertyEditorComponent,
		UniformDictPropertyEditorComponent,
		ObjectPropertyBaseEditorComponent,
		DatetimeObjectPropertyEditorComponent,
		BytesObjectPropertyEditorComponent,
		//property visualizer
		AtomicObjectValueVisualizer,
		DateObjectVisualizer,
		BooleanObjectValueVisualizer,
		KnownDictPropertyVisualizerComponent,
		UniformDictPropertyVisualizerComponent,
		SetObjectVisualizerComponent,
		//object property visualizer
		ObjectPropertyVisualizerComponentLoader,
		PaginatorComponent,
		TypesSelector,
	],
	entryComponents:[
		
	],
	exports:[
		BaseComponent,
		DynComponentLoaderComponent,
		ObjectVisualizerComponentLoader,
		ObjectEditorComponentLoader,
		ObjectEditorModalComponentLoader,
		ObjectBaseEditor,
		ObjectBaseVisualizer,
		ObjectTableComponent,
		ObjectVisualizerModalComponentLoader,
		//property editors
		ObjectPropertyEditorComponentLoader,
		FloatObjectPropertyEditorComponent,
		IntObjectPropertyEditorComponent,
		BoolObjectPropertyEditorComponent,
		StrObjectPropertyEditorComponent,
		TextObjectPropertyEditorComponent,
		ListPropertyBaseEditorComponent,
		SetPropertyBaseEditorComponent,
		KnownDictPropertyEditorComponent,
		UniformDictPropertyEditorComponent,
		ObjectPropertyBaseEditorComponent,
		DatetimeObjectPropertyEditorComponent,
		BytesObjectPropertyEditorComponent,
		//property visualizer
		AtomicObjectValueVisualizer,
		DateObjectVisualizer,
		BooleanObjectValueVisualizer,
		KnownDictPropertyVisualizerComponent,
		UniformDictPropertyVisualizerComponent,
		SetObjectVisualizerComponent,
		//object property visualizer
		ObjectPropertyVisualizerComponentLoader,
		PaginatorComponent,
		TypesSelector,
	],
	providers:[
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [
	]
})
export class Module {
}