import { ModalController, LoadingController, ToastController, NavParams, MenuController } from '@ionic/angular';
import { Component, OnInit, Directive, ElementRef, Renderer2, Optional, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ComponentFactoryResolver, forwardRef, Type, ComponentRef, ViewContainerRef, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { ClassMeta, PropertyMeta, ParameterMeta } from 'dissys';
import { AbstractControl } from '@angular/forms';

import { PageBase } from '../page-base';
import { LanguageService } from '../language/module';

export type Constructor<T> = Function & { prototype: T }

@Component({})
export class BaseComponent extends PageBase{

	public constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		protected navparam : NavParams,
		@Optional() @Inject('LoadIndicatorComponent') loadIndicatorComponent ?: {new(...args):any},
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			loadIndicatorComponent,
		);
	}

	setOptions(d){
		for(let i in d){
			this[i] = d[i];
		}
	}

	protected pullRouteArgs(){
		if(this.activatedRoute){
			this.activatedRoute.data.subscribe(d=>{
				this.setOptions(d);
			});
			this.activatedRoute.paramMap.subscribe(d=>{
				this.setOptions((<any>d).params);
			});
			this.activatedRoute.queryParams.subscribe(d=>{
				if(d && d.params)
					this.setOptions((<any>d).params);
				if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state)
					this.setOptions(<any>this.router.getCurrentNavigation().extras.state);
				if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.queryParams)
					this.setOptions(<any>this.router.getCurrentNavigation().extras.queryParams);
			});
		}
	}

	ngOnInit(){
		if(this.navparam){
			this.setOptions(this.navparam.data);
			this.navparam.data = {};			
		}
	}

	setLang(event){
	  //console.log(event);
	  //this.translate.setDefaultLang(event.detail.value);
	  //this.translate.use(event.detail.value);
	}

	protected toggleFadeElement(ele : ElementRef<any>){
		return !('max-height' in ele.nativeElement.style) || ele.nativeElement.style['max-height'] == '0px' ? this.fadeOutElement(ele) : this.fadeInElement(ele);
	}
	
	protected fadeOutElement(ele : ElementRef<any>){
		let lastH = ele.nativeElement.clientHeight;
		let c = 2;
		let cb = ()=>{
			ele.nativeElement.style['max-height'] = c.toString()+'px';
			c+=20;
			if(lastH == ele.nativeElement.clientHeight)
				return;
			lastH = ele.nativeElement.clientHeight
			setTimeout(cb,10);
		};
		ele.nativeElement.style['max-height'] = c.toString()+'px';
		c+=20;
		setTimeout(cb,1);
	}

	protected fadeInElement(ele : ElementRef<any>){
		let lastH = ele.nativeElement.clientHeight;
		let c = ele.nativeElement.clientHeight;
		let cb = ()=>{
			if(c<0) return;
			ele.nativeElement.style['max-height'] = c.toString()+'px';
			c-=20;
			setTimeout(cb,10);
		};
		cb();
	}

	protected openModal(modal : string, e ?: Event){
		if(e){
			e.stopPropagation();
			e.preventDefault();
		}
		this[modal].isOpen = true;
	}

	protected closeModal(modal : string, e ?: Event){
		if(e){
			e.stopPropagation();
			e.preventDefault();
		}
		this[modal].isOpen = false;
	}
}

@Component({
	selector:'dyncomp',
	template:'<ng-template #compslot></ng-template>',
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DynComponentLoaderComponent),
		}
	],
	//schemas:[NO_ERRORS_SCHEMA]
})
export class DynComponentLoaderComponent<T = any> implements OnInit{

	protected cfg;
	protected loaded : boolean = false;
	protected componentType : Type<any>;
	protected componentRef : ComponentRef<any> = null;
	protected _disabled = false;
	protected loader;

	@ViewChild('compslot', {static: true, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;

	@Input()
	set config(c){
		this.cfg = c;
		if(this.cfg && this.componentRef) for(let i in this.cfg) this.componentRef.instance[i] = this.cfg[i];
	}

	@Input()
	set component(c : Type<any>){
		let change = c != this.componentType;
		this.componentType = c;
		if(change){ this.loaded = false; this.ngOnInit(); }
	}

	writeValue(s){this.componentRef.instance.writeValue(s);}
	readValue(){return this.componentRef.instance.readValue();}
	registerOnChange(s){this.componentRef.instance.registerOnChange(s);}
	registerOnTouched(s){this.componentRef.instance.registerOnTouched(s);}

	get value(){ return this.componentRef.instance.readValue();}
	set value(v){ this.componentRef.instance.writeValue(v);}
	get onChange(){ return this.componentRef.instance.onChange;}
	set onChange(v){ this.componentRef.instance.onChange = v;}
	get onTouched(){ return this.componentRef.instance.onTouched;}
	set onTouched(v){ this.componentRef.instance.onTouched = v;}

	setDisabledState(state){
		this._disabled = state;
		if(this.componentRef) this.componentRef.instance.setDisabledState(state);
	}

	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver
	){
		
	}

	ngOnInit(){
		if(!this.componentType || this.loaded) return;
		this.loaded = true;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory<typeof this.componentType>(this.componentType);
		this.pluginHost.clear();
		this.componentRef = this.pluginHost.createComponent<typeof this.componentType>(componentFactory);
		let componentRef = this.componentRef;
		let cfg = this.cfg;
		(<any>componentRef.instance).setOptions(cfg);
		if(this.loader) this.loader(componentRef);
		/*let d = {};
		for(let i of Object.getOwnPropertyNames(this.componentRef.instance)){
			//console.log('define',i)
			if(i[0] == '_' || i in d) continue;
			d[i] = true;
			try{
				Object.defineProperty(this,i,{
					get:()=>{
						console.log('getter override',this,i,componentRef);
						if(typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function){
							return (...args)=>{
								return componentRef.instance[i](...args)
							}
						}
						else
							return componentRef.instance[i];
					},
					set:(value)=>{componentRef.instance[i] = value;this['_'+i] = componentRef.instance[i];},
				});
				console.debug(`patched attribute ${i}`);
			}
			catch(e){
				console.error(`failed patching ${i}`);
			}
		}
		let stack = [componentRef.instance.constructor];
		while(stack.length > 0){
			let j = stack.shift();
			console.log('proto',j)
			//if(j.constructor && j.constructor != Object) stack.push(j.constructor);
			if(Object.getPrototypeOf(j)) stack.push(Object.getPrototypeOf(j));
			if(j.prototype) for(let i of Object.getOwnPropertyNames(j.prototype)){
				//console.log('base define',i)
				if(i[0] == '_' || i in d) continue;
				d[i] = true;
				try{
					Object.defineProperty(this,i,{
						get:()=>{
							console.log('getter override base',this,i,componentRef);
							if(typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function){
								return (...args)=>{
									return componentRef.instance[i](...args)
								}
							}
							else
								return componentRef.instance[i];
						},
						set:(value)=>{componentRef.instance[i] = value;this['_'+i] = componentRef.instance[i];},
					});
					console.debug(`patched attribute ${i}`);
				}
				catch(e){
					console.log(`failed patching ${i}`);
				}
			}
			for(let i of Object.getOwnPropertyNames(j)){
				//console.log('base define',i)
				if(i[0] == '_' || i in d) continue;
				d[i] = true;
				try{
					Object.defineProperty(this,i,{
						get:()=>{console.log('getter override',this,i,componentRef);return typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function ? componentRef.instance[i].bind(componentRef.instance) : componentRef.instance[i];},
						set:(value)=>{componentRef.instance[i] = value;this['_'+i] = componentRef.instance[i];},
					});
					console.debug(`patched attribute ${i}`);
				}
				catch(e){
					console.error(`failed patching ${i}`);
				}
			}				
		}*/
		//if(cfg) for(let i in cfg) componentRef.instance[i] = cfg[i];
	}

}

export class PageChangeEvent extends Event{
	constructor(readonly pageNumber : number){
		super('page-change-event',{cancelable:true});
	}
}

@Component({
	selector: 'paginator',
	template: `
		<ion-label color="primary" *ngIf="count == 1">{{count}} {{'entry'|translate}}</ion-label>
		<ion-label color="primary" *ngIf="count != 1">{{count}} {{'entries'|translate}}</ion-label>
		<div scrollX="true" #pagelist *ngIf="pages.length > 1" (wheel)="onMWheel($event)">
			<ion-button fill="clear" size="small" *ngFor="let i of pages" [class.selected]="currentPage == i" (click)="pageEmitter.emit(i);onPageChanged.emit(i);">{{i}}</ion-button>
		</div>
	`,
	styles:[`
		:host{
			display: flex;
			flex: 0 0 auto;
			flex-direction: row;
		}

		:host > ion-label{
			display: inline;
			vertical-align: middle;
			line-height: 35px;
			font-size: 13px;
			display: flex;
	 		flex: 0 0 auto;
	 		margin-right: 5px;
		}

		:host > div > ion-button.selected{
			font-weight: bold;
			background-color: var(--background-hover);
			color: #fff;
		}

		:host > div{
			overflow-x: scroll;
			display: flex;
			flex: 1 1 auto;
			flex-wrap: nowrap;
		}
	`]
})
export class PaginatorComponent{

	@Output()
	readonly onPageChange = new EventEmitter<PageChangeEvent>();
	@Output()
	readonly onPageChanged = new EventEmitter<number>();

	protected readonly pageEmitter = new EventEmitter<number>();
	protected currentPage : number = 0;
	protected _count : number = 0;
	protected _pageSize : number = 0;
	protected pages = [];
	@ViewChild('pagelist',{static:false}) protected pagelist : ElementRef<HTMLDivElement>;

	@Input()
	set count(value : number){
		if(value == this._count) return;
		this._count = value;
		this.reload();
	}

	get count(){
		return this._count;
	}

	@Input()
	set pageSize(value : number){
		if(value == this._pageSize) return;
		this._pageSize = value;
		this.reload();
	}

	get pageSize(){
		return this._pageSize;
	}

	@Input()
	set page(value : number){
		if(value == this.currentPage) return;
		const event = new PageChangeEvent(value);
		this.onPageChange.emit(event);
		if(event.defaultPrevented) return;
		this.currentPage = value;
		this.onPageChanged.emit(value);
	}

	@Output()
	readonly pageChange = new EventEmitter<number>();

	onMWheel(event : WheelEvent){
		this.pagelist.nativeElement.scrollLeft += event.deltaY;
	}

	ngOnInit(){
		this.reload();
	}

	reload(){
		this.pages = [];
		if(this.count > 0 && this.pageSize > 0)
		for(let i = 1; i <= Math.floor(this.count/this.pageSize)+1; i++) this.pages.push(i);

	}
}

/*
export interface SubEditorOpenIntend{
	open(meta : ClassMeta, value : any = null, ctx = null) : Promise<any|void>;
}

export interface SubEditorCloseIntend{
	close(data = undefined) : Promise<void>;
}

export class SubEditorModalCloseIntend implements SubEditorCloseIntend{
	protected modal : HTMLIonModalElement;

	//constructor(protected modal: HTMLIonModalElement){}

	close(data = undefined) : Promise<void>{
		return new Promise((res)=>{
			if(this.modal){
				this.modal.dismiss(data).then(()=>res());
			}
			else{
				res();
			}

		});
	}
}

export class SubE

export class SubEditorModalOpenIntend implements SubEditorOpenIntend{

	constructor(
		protected translate: LanguageService,
		protected modalController : ModalController,
		protected db : Database<any>
	){

	}

	open(meta : ClassMeta, value : any = null, ctx = null, extraParam = null) : Promise<any|void>{

		
		let loader = db.getLoader(meta);

		extraParam = extraParam||{};

		return new Promise((res,rej)=>{
			let closeIntend = new SubEditorModalCloseIntend<MC,M>(res,<any>multiple,'label_take_over');
			if(mode == 'view')
				this.createModal<MC,M>(ModelManipulatorSelectEditor,true,d=>{
					if(d!==undefined)
						res(d.data);
					else res();
				},{
					provider:channel,
					value:mdata,
					//multiple:this._multiple,
					readonly:true,
					disabled:false,
					selectMode:'none',
					mode:mode,
					viewContext:viewCtx,
					metadata:metadata,
					closeIntend:closeIntend,
					openIntend:this,
					extraParam:extraParam,
					...extraParam
				}).then(modal=>{closeIntend.modal=modal;modal.present();});
			else if(mode == 'edit')
				this.createModal<MC,M>(ModelManipulatorSelectEditor,true,d=>{
					if(d!==undefined)
						res(d.data);
					else res();
				},{
					provider:channel,
					//multiple:this._multiple,
					value:mdata,
					readonly:false,
					disabled:false,
					selectMode:'none',
					mode:mode,
					viewContext:viewCtx,
					metadata:metadata,
					closeIntend:closeIntend,
					openIntend:this,
					extraParam:extraParam,
					...extraParam
				}).then(modal=>{closeIntend.modal=modal;modal.present();});
			else if(mode == 'create')
				this.createModal<MC,M>(ModelManipulatorSelectEditor,true,d=>{
					if(d!==undefined)
						res(d.data);
					else res();
				},{
					provider:channel,
					//multiple:this._multiple,
					readonly:false,
					disabled:false,
					selectMode:'existing',
					mode:mode,
					viewContext:viewCtx,
					metadata:metadata,
					closeIntend:closeIntend,
					openIntend:this,
					extraParam:extraParam,
					...extraParam
				}).then(modal=>{closeIntend.modal=modal;modal.present();});
		});
	}

	protected async createModal<MC extends ModelClass, M extends InstanceType<MC>>(component,closeable=true,dismissCallback:(data:OverlayEventDetail<M|M[]>)=>void = undefined,param = {}){
		let modal : HTMLIonModalElement = null;
		if(closeable)
			modal = await this.modalController.create({
				component: component
				,componentProps: param
			});
		else
			modal = await this.modalController.create({
				component: component
				,backdropDismiss:false
				,swipeToClose:false
				,keyboardClose: false
				,componentProps: param
			});

		if(dismissCallback)
			modal.onDidDismiss().then(data=>{
				dismissCallback(data);
			});
		return modal;
	}
}
*/


export type Section = {
	label:string;
	type:'simple'|'complex';
	props:{
		control: AbstractControl;
		loader: (...args)=>void;
		component: Type<any>;
		type: PropertyMeta|ParameterMeta;
	}[];
	subeditors: Array<{
		component: Type<any>;
		loader: (...args)=>void;
		ctlname: string;
		control: AbstractControl;
		type: PropertyMeta|ParameterMeta;
	}>;
};

export enum Modes{
	create,edit
}