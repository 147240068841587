import { NgModule, Component, Inject, ElementRef, ViewChild, Injectable, Optional, EventEmitter, Renderer2, RendererFactory2, ComponentFactoryResolver, } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, RouterStateSnapshot, ActivatedRouteSnapshot, Route, UrlSegment } from '@angular/router';

import { Guard as AuthGuard, Page as AuthPage } from './auth/module';
import { Guard as InstanceGuard, InstanceSelectorPage, InstanceSelector } from './instance-selector/module';

import { AppComponent } from './app.component';
import { TestPage } from './test.component';
//import { StartPage, RedirectComponent } from './start.page';
import { Router } from '@angular/router';
import { CanLoad, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuController, ModalController, IonModal } from '@ionic/angular';

import { PreloadGuard,PreloadingPage,NotFoundPage } from './common';
import { APIService } from './api/module';



const routes: Routes = [
	{
		path:'',
		pathMatch:'full',
		canLoad:[PreloadGuard,InstanceGuard,],
		canActivate: [AuthGuard],
		loadChildren: ()=>import('./modules/startpage/module').then(m=>m.Module),
	},
	{
		path:'test',
		pathMatch: 'full',
		canLoad: [InstanceGuard,PreloadGuard],
		component: TestPage
	},
	{
		path:'auth',
		pathMatch: 'full',
		canLoad: [InstanceGuard,PreloadGuard],
		component: AuthPage
	},
	{
		path:'select-instance',
		pathMatch: 'full',
		component: InstanceSelectorPage
	},
	{
		path:'initializing',
		pathMatch: 'full',
		canLoad: [InstanceGuard],
		component: PreloadingPage
	},
	{
		path:'**',
		pathMatch:'full',
		canActivate:[InstanceGuard,PreloadGuard],
		component: NotFoundPage
		/*redirectTo: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
			const matchedUrl = state.url;
			return { url: '/', queryParams: { matchedUrl } };
		}*/
		//redirectTo: '/?matchedUrl=:url',
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
