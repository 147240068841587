import { HttpClientModule, HttpClient, HttpRequest, HttpResponse as ngHttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable,EventEmitter, Optional, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { Capacitor } from '@capacitor/core';

export type BodyData =
string | number | boolean | null | undefined
| Int16Array
| Uint16Array
| Int32Array
| Uint32Array
| Float32Array
| Float64Array
//| BigInt64Array
//| BigUint64Array

export type Response = {status:number,headers:{[key:string]:string},msg:string,body:any};

@Injectable({providedIn:'root'})
export class HTTPService{

	protected textDecoder = new TextDecoder('utf-8');

	constructor(
		protected platform : Platform,
		@Optional() protected browserHttp : HttpClient,
		@Optional() protected nativeHttp : HTTP,
	){

	}

	get(url : string) { return this.request('GET',url); }
	post(url : string, data) { return this.request('POST',url,data); }
	put(url : string, data) { return this.request('POST',url,data); }
	delete(url : string, data?) { return this.request('POST',url,data); }
	patch(url : string, data) { return this.request('POST',url,data); }
	options(url : string) { return this.request('POST',url); }

	request(method : string, url : string, body ?: BodyData, headers ?: {[key:string]:string}, signal ?: AbortSignal, rtype ?: 'arraybuffer') : Promise<{status:number,headers:{[key:string]:string},msg:string,body:ArrayBuffer}>;
	request(method : string, url : string, body ?: BodyData, headers ?: {[key:string]:string}, signal ?: AbortSignal, rtype ?: 'json') : Promise<{status:number,headers:{[key:string]:string},msg:string,body:any}>;
	request(method : string, url : string, body ?: BodyData, headers ?: {[key:string]:string}, signal ?: AbortSignal, rtype ?: 'text') : Promise<{status:number,headers:{[key:string]:string},msg:string,body:string}>;
	request(method : string, url : string, body ?: BodyData, headers ?: {[key:string]:string}, signal ?: AbortSignal, rtype : 'arraybuffer'|'json'|'text'|'auto' = 'auto') : Promise<{status:number,headers:{[key:string]:string},msg:string,body:ArrayBuffer|JSON|string}>{
		if(Capacitor.isNative){
			return this.nativeHttp.sendRequest(url,{method:<any>method,headers:headers,data:<any>body}).then(res=>{
				return typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
			});
		}
		else if(window.fetch){
			const nheaders = new Headers();
			for(let key in headers){
				nheaders.set(key,headers[key]);
			}
			// Setzen Sie ggf. erforderliche Header hier

			const options: RequestInit = {
				method: method, // Ändern Sie die Methode je nach Bedarf
				headers: nheaders,
				signal, // Verwenden Sie das AbortSignal hier
			};

			if(body !== undefined){
				if(typeof body === 'string' ||
					body instanceof Int16Array ||
					body instanceof Uint16Array ||
					body instanceof Int32Array ||
					body instanceof Uint32Array ||
					body instanceof Float32Array ||
					body instanceof Float64Array
				){
					options.body = body;
				}
				else{
					options.body = JSON.stringify(body);
				}
			}

			const urlPattern = /^([^:]+:\/\/[^:/]+(?::\d+)?)(\/.*)$/;

			// URL extrahieren
			const matches = url.match(urlPattern);

			if (matches) {
			  const fullMatch = matches[0];        // Die gesamte Übereinstimmung
			  const protocolAndDomain = matches[1]; // Protokoll und Domain
			  const path = matches[2];              // Pfad

			  console.log("Gesamte Übereinstimmung:", fullMatch);
			  console.log("Protokoll und Domain:", protocolAndDomain);
			  console.log("Pfad:", path);
			  	return fetch(protocolAndDomain+encodeURI(path),options)
			  	.then(response=>{
			  		return response[rtype=='auto'||rtype=='arraybuffer'?'arrayBuffer':rtype]().then(d=>{
			  			const rheaders: { [key: string]: string } = {};
			  			for (const [key, value] of (<any>response.headers).entries()) rheaders[key] = value;
			  			if(rtype == 'auto' && 'content-type' in rheaders){
			  				d = this.processArrayBuffer(rheaders['content-type'],d);
			  			}
			  			return {status:response.status,msg:response.statusText,headers:rheaders,body:d};
			  		});
			  	});
			} else {
			  return fetch(encodeURI(url),options)
			  	.then(response=>{
			  		return response[rtype=='auto'||rtype=='arraybuffer'?'arrayBuffer':rtype]().then(d=>{
			  			const rheaders: { [key: string]: string } = {};
			  			for (const [key, value] of (<any>response.headers).entries()) rheaders[key] = value;
			  			if(rtype == 'auto' && 'content-type' in rheaders){
			  				d = this.processArrayBuffer(rheaders['content-type'],d);
			  			}
			  			return {status:response.status,msg:response.statusText,headers:rheaders,body:d};
			  		});
			  	});
			}
			
		}
		else{
			return new Promise((res,rej)=>{
				this.browserHttp.request(method,url,{ observe: 'response', responseType: rtype == 'auto'?'arraybuffer':rtype,headers:headers,body:body})
				.subscribe((response:ngHttpResponse<any>)=>{
					const rheaders: { [key: string]: string } = {};
					for(let key of response.headers.keys()) rheaders[key] = response.headers.get(key);
					if(rtype == 'auto' && 'Content-Type' in rheaders){
						res({status:response.status,msg:response.statusText,body:this.processArrayBuffer(rheaders['Content-Type'],response.body),headers:rheaders});
					}
					else{
						res({status:response.status,msg:response.statusText,body:response.body,headers:rheaders});
					}
				},err=>{
					rej(err);
				});
			});

		}
	}

	processArrayBuffer(mime : string, data : ArrayBuffer) : any{
		if(mime.startsWith('application/json')){
			const txt = this.textDecoder.decode(data);
			return JSON.parse(txt)
		}
		return data;
	}
}