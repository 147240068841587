import { Pipe, PipeTransform } from '@angular/core';
import { NgModule } from '@angular/core';

@Pipe({
	name: 'property'
})
export class PropertyAccessPipe implements PipeTransform {
	transform(obj: any, path: string): any {
	return path.split('.').reduce((prev, curr) => prev ? prev[curr] : null, obj);
	}
}

@NgModule({
	declarations: [
		PropertyAccessPipe
	],
	exports: [
		PropertyAccessPipe
	]
})
export class PropertyAccessModule{}