import { Injectable,Inject,Optional } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Route, UrlSegment } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';
import { MenuController } from '@ionic/angular';

import { InstanceSelector } from './service';

@Injectable({
	providedIn: 'root'
})
export class Guard implements CanActivate, CanLoad {

	constructor(
		protected router : Router
		,protected menuctrl : MenuController
		,protected isel : InstanceSelector
	){
		
	}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean>{
		await this.isel.inited;
		if(this.isel.hasURI()){
			return true;
		}
		else{
			this.router.navigate([this.isel.getSelectPageRoute()],{queryParams:{redirect:btoa(next.url.join('/'))}});
			return false;
		}
	}

	async canLoad(
		route: Route, segments: UrlSegment[]
	){
		await this.isel.inited;
		if(this.isel.hasURI()){
			return true;
		}
		else{
			//this.router.navigate([this.isel.getSelectPageRoute()],{queryParams:{redirect:btoa(next.url.join('/'))}});
			return false;
		}
	}

}
