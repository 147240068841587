//translate-config.service.ts
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Loader } from 'dissys';
import { HTTPService } from '../http';
 
@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	protected provider : Loader;
	protected langdata = {};
	protected _defaultLang : string;
	protected _lang : string;
	protected _translations = {};
	protected static _global : LanguageService = undefined;

	get currentLang(){
		return 'de';
		return this._lang || this.getDefaultLanguage() || this.getBrowserLang();
	}
 
	constructor(
		public readonly http : HTTPService,
	) {
		if(!LanguageService._global)
			LanguageService._global = this;
	}

	static global(){
		return LanguageService._global;
	}

	getBrowserLang(){
		let lang = navigator.language || (<any>navigator).userLanguage; 
		if(lang.indexOf('-') > -1) return lang.substring(0,lang.indexOf('-'));
		return lang;
	}
 
	getDefaultLanguage(){
		return this._defaultLang;
	}

	get defaultLang(){
		return this._defaultLang;
	}

	setDefaultLang(lang:string){
		if(lang.indexOf('-') > -1) lang = lang.substring(0,lang.indexOf('-'));
		this._defaultLang = lang;
	}
 
	setLanguage(lang) {
		if(lang.indexOf('-') > -1) lang = lang.substring(0,lang.indexOf('-'));
		this.use(lang);
	}

	use(lang:string){
		if(lang.indexOf('-') > -1) lang = lang.substring(0,lang.indexOf('-'));
		this._lang = lang;
	}

	setProvider(p:Loader){
		this.provider = p;
		this.reload();
	}

	reload(){
		return new Promise<void>((resolve,reject)=>{
			let c = 0;
			let m = 1;
			let lang = this.currentLang;
			if(!lang)
				return;
			this.getTranslation(lang).then(d=>{
				for(let i in d){
					this.langdata[i] = d[i];
				}
				//return this.langdata;
				this.setTranslation(lang, this.langdata,true);
				c++;
				if(c>=m) resolve();
			});
			if(this.provider){
				m++;
				this.provider.select(null,{'lang':lang},null).then(d=>{
					this.langdata = this.langdata || {};
					for(let i of d){
						let data = i.data;
						this.langdata[data.tag] = data.txt;
					}
					//this.langdata = langdata;
					this.setTranslation(lang, this.langdata,true);
				}).finally(()=>{
					c++;
					if(c>=m) resolve();
				});
			}
		});
	}

	get(tag:string,lang?:string){
		if(!lang)
			lang = this.currentLang;
		return new Promise<string>((r,re)=>{
			if(this._translations[lang]){
				r(this.getSync(tag,lang));
			}
			else{
				this.getTranslationPromise(lang).then(d=>{
					 r(this.getSync(tag,lang));
				}).catch(e=>re(e));
			}
		});
	}

	getTranslation(lang:string){
		return this.http.request('GET',`/assets/i18n/${lang}.json`,undefined,{'Accept':'application/json, */*'}).then(d=>{
			this._translations[lang] = d.body;
			return this._translations[lang];
		});
	}

	getTranslationPromise(lang:string){
		return new Promise<void>((res,rej)=>{
			//let o = this.http.get(`/assets/i18n/${lang}.json`,{responseType:'json'});
			//let u = o.subscribe(d=>{
			//  u.unsubscribe();
			//  this._translations[lang] = d;
			//  res();
			//},e=>{
			//  u.unsubscribe();
			//  console.error(`error on loading translations for lang ${lang}`,e);
			//  rej(e);
			//})
			res();
		});
	}

	setTranslation(lang:string,data,merge?:boolean){
		if(merge && this._translations[lang]){
			for(let i in data)
				this._translations[lang][i] = data[i];
		}
		else{
				this._translations[lang] = data;
		}
	}

	getSync(tag:string, lang?:string) : string{
		if(!tag) return '';
		else if(!(typeof tag === 'string')){
			console.error('given tag is not a string:');
			console.error(tag)
			return '';
		}
		let tt = tag.toLowerCase();
		let langDict = this._translations[lang||this.currentLang];
		if(!langDict) return tag;
		if(tag in langDict) return langDict[tag];
		if(tt in langDict) return langDict[tt];
		let parts = tt.split('.');
		parts.shift();
		while(parts.length > 0){
			let t = parts.join('.')
			if(t in langDict){
				return langDict[t];
			}
			else if(t.toLowerCase() in langDict){
				return langDict[t.toLowerCase()];
			}
			parts.shift();
		}
		parts = tt.split('.');
		while(parts.length > 0){
			for(let i in langDict){
				let z = i.toLowerCase().split('.').reverse();
				let ok = true;
				for(let j of Array.from(parts).reverse()){
					if(z.length == 0) break;
					if(j != z.shift()){
						ok = false;
						break;
					}					
				}
				if(ok) return langDict[i];
			}
			parts.shift();
		}
		return tag;
	}
 
}
 