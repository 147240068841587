import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { LanguageService } from './language/module';
import { Guard as AuthGuard } from './auth/module';
import { InstanceSelector } from './instance-selector/module';
import { APIService, APISocketService } from './api/module';
import { LoadController,NoAccessGuard } from './common';

import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	private authcheckState = false;
	protected modules = [];
	protected window = window;
	private readonly _readyProm = this.initializeApp();
	protected _initPath : string;

	get initPath(){
		return this._initPath;
	}

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private storage : Storage,
		private router : Router,
		protected translate : LanguageService,
		public readonly isel : InstanceSelector,
		public readonly api : APIService,
		public readonly apisock : APISocketService,
		protected activatedRoute: ActivatedRoute,
		protected lctl : LoadController,
		@Inject('AppVersion') public readonly version : string,
	) {
		isel.onChanged.subscribe(d=>{
			this.modules = this.api.info.modules.filter(i=>!i.hidden);
			let last = this.router.config[this.router.config.length-1];
			this.router.config.splice(this.router.config.length-1,1);
			const loaders = {};
			for(let i of this.api.info.modules){
				loaders[i.name] = import(`./modules/${i.name}/module`);
				const mod = i.name;
				if(i.hidden){
					this.router.config.push({
						path:i.name,
						canActivate: [NoAccessGuard],
						pathMatch:'prefix',
						loadChildren: function(){ return loaders[mod].then(d=>d.Module)},					
					});
				}
				else{
					this.router.config.push({
						path:i.name,
						canActivate: [AuthGuard],
						pathMatch:'prefix',
						loadChildren: function(){ return loaders[mod].then(d=>d.Module)},					
					});
				}
			}
			this.router.config.push(last);
		});
	}

	get ready() : Promise<void>{
		return this._readyProm;
	}

	initializeApp() {
		this._initPath = window.location.pathname;
		//this._initPath = '/usl';
		//window.history.replaceState({}, '', '/');
		return this.platform.ready().then(() => {
			this.storage.create().then(()=>{
				this.translate.setDefaultLang((<any>navigator).language || (<any>navigator).userLanguage);
				this.translate.use((<any>navigator).language || (<any>navigator).userLanguage);
				this.translate.reload().finally(()=>{	
					this.isel.init().then(()=>{
						this.lctl.load(()=>this.api.ready).then(()=>{
							//this.api.ready.then(()=>{
								this.statusBar.styleDefault();
								this.splashScreen.hide();

								this.router.navigateByUrl(this.initPath);
						});
						//});
					});
				});
			});				
		});
	}

}
