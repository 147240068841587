import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams } from '@ionic/angular';

import { LanguageModule } from '../language/module';

import { InstanceSelectorPage } from './page';
import { InstanceSelector } from './service';
export * from './page';
export * from './guard';
export * from './service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		LanguageModule,
	],
	declarations: [
		InstanceSelectorPage,
	],
	entryComponents:[
	],
	providers:[
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		InstanceSelector
	],
	bootstrap: [
	]
})
export class Module {}