import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, ComponentFactoryResolver,forwardRef,Type, Directive, ComponentRef, ViewChild, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController } from '@ionic/angular'
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClassMeta, ObjectMeta, objectMeta } from 'dissys';
import { BaseComponent } from '../object-editor/module';

@Component({
	selector:'inspector',
	templateUrl:'./inspector.component.html',
	//styleUrls:['./inspector.component.scss']
})
export class InspectorComponent extends BaseComponent{

}