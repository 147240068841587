import { Component, OnInit, ViewChild, OnDestroy, ViewChildren,Input,AfterViewInit,ElementRef,QueryList,forwardRef, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { ModalController,LoadingController,NavParams,MenuController,IonMenu } from '@ionic/angular'
import { MenuI,Components,Side } from '@ionic/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subscription } from 'rxjs'

import { LanguageService } from '../language/module';
import { APIService } from '../api/module';
import { InstanceSelector } from '../instance-selector/module';
import { AppComponent } from '../app.component';
import { NavItem } from './common';

@Component({
	selector: 'navigation-menu',
	templateUrl: './navigation-menu.html',
	styleUrls: ['./navigation-menu.scss'],
})
export class NavigationMenuComponent{

	protected _menuItems : NavItem[];
	protected _contentId : string;
	protected _pathPrefix : string = '';
	protected _menuId : string;
	protected _name : string;
	protected window = window;
	protected submenus : {[key:string]:boolean} = {};
	protected widthMediaQuery : MediaQueryList;
	protected mediaQueryListener = this.updateMenuPosition.bind(this);
	protected isXsScreen = false;

	@ViewChild('menu',{static:false,read: ElementRef}) protected menu : ElementRef;
	@ViewChild('pane',{static:true,read: ElementRef}) protected pane : ElementRef;
	@ViewChild('outlet',{static:true,read: ElementRef}) protected outlet : ElementRef;

	constructor(
		protected api : APIService,
		protected router : Router,
		protected mctrl : MenuController,
		protected renderer : Renderer2,
		protected ele : ElementRef,
		@Inject(AppComponent) protected app : AppComponent,
	){
		this.widthMediaQuery = window.matchMedia('(max-width: 600px)');
	}

	ngOnInit(){
		this.mctrl.enable(true,this.menuId);
	}

	ngAfterViewChecked(){
		if(this.router.routerState.snapshot.url == '/' || this.router.routerState.snapshot.url == ''){
			if(this.outlet.nativeElement.hasAttribute('aria-hidden') && (this.outlet.nativeElement.getAttribute('aria-hidden') == 'true' || this.outlet.nativeElement.getAttribute('aria-hidden') == '')){
				this.menu.nativeElement.classList.remove('menu-pane-visible');
				this.menu.nativeElement.classList.remove('show-menu');
			}
			if(this.pane.nativeElement.classList.contains('split-pane-visible')){
				this.menu.nativeElement.classList.add('menu-pane-visible');
				this.outlet.nativeElement.classList.remove('menu-content-open');
			}
			else{
				this.menu.nativeElement.classList.remove('menu-pane-visible');
				this.menu.nativeElement.classList.add('show-menu');
			}			
		}
	}

	protected updateMenuPosition(mq : MediaQueryList, e ?: MediaQueryListEvent){
	  if (mq.matches) {
	    // Wenn es ein kleiner Bildschirm ist, fügen Sie die CSS-Klasse "end-menu" hinzu
	    //this.renderer.addClass(this.el.nativeElement, 'end-menu');
	    //this.renderer.setAttribute(this.menu.nativeElement, 'side', 'end');
	    this.isXsScreen = true;
	  } else {
	    this.isXsScreen = false;
	    // Andernfalls entfernen Sie die CSS-Klasse "end-menu"
	    //this.renderer.removeClass(this.el.nativeElement, 'end-menu');
	    //this.renderer.setAttribute(this.menu.nativeElement, 'side', 'start');
	  }
	};

	ionViewWillEnter(){
		this.openMenu();

		// Überprüfen das Media Query zu Beginn und reagiere auf Änderungen
		this.updateMenuPosition(this.widthMediaQuery);
		this.widthMediaQuery.addListener(this.mediaQueryListener);
	}

	ionViewWillLeave(){
		this.widthMediaQuery.removeListener(this.mediaQueryListener);
	}
	
	openMenu(){
		return this.mctrl.open(this.menuId);
	}

	closeMenu(){
		return this.mctrl.close(this.menuId);
	}

	toggleMenu(){
		return this.mctrl.toggle(this.menuId);
	}

	@Input()
	set name(name : string){
		this._name = name;
		this._contentId = name+'-module-content';
		this._menuId = name+'-module-main';
	}

	get name(){
		return this._name;
	}

	@Input()
	set menuItems(items:NavItem[]){
		let ni = []
		for(let i of items){
			if(i.perms){
				if(!this.api.auth.authed)
					continue;
				let ok = true;
				for(let perm of i.perms){
					/*if(!this.api.identity.hasPermSync(perm)){
						ok = false;
						break;
					}*/
				}
				if(!ok)
					continue
			}
			ni.push(i);
		}
		this._menuItems = items;
	}

	get menuItems(){
		return this._menuItems;
	}

	get contentId(){
		return this._contentId;
	}

	get menuId(){
		return this._menuId;
	}

	@Input()
	set pathPrefix(b:string){
		this._pathPrefix = b;
	}

	get pathPrefix(){
		return this._pathPrefix;
	}

	logout(){
		this.api.auth.logout();
		this.router.navigate(['/']);
	}

	toggleSubNav(event : Event, item){
		let name = item.label;
		if(this.submenus[name] === undefined)
			this.submenus[name] = true;
		else
			this.submenus[name] = !this.submenus[name];
		if(!item.isOpen)
			item.isOpen = true;
		else
			item.isOpen = false;
		//event.stopPropagation();
		//event.preventDefault();
	}
}