import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, ViewContainerRef, ComponentFactoryResolver,forwardRef,Type, Directive, QueryList } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, AbstractControl, ControlValueAccessor,NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClassMeta, ObjectMeta, objectMeta, PropertyMeta, ParameterMeta, listMeta, dictMeta, setMeta, SetMeta, intMeta, floatMeta, boolMeta, strMeta,IntMeta,FloatMeta,StrMeta,DissysDate } from 'dissys';
import { ObjectVisualizer, ObjectVisualizerComponent, VisualizerSelectors } from './object-visualizer.component';
import { DynComponentLoaderComponent, Modes, Section } from './common';

@ObjectVisualizer(0.25,VisualizerSelectors.Type('bool','boolean','Boolean',Boolean,boolMeta))
@Component({
	selector: 'boolean-object-value-visualizer',
	template:`
		<ion-label *ngIf="value === null || value === undefined"></ion-label>
		<ion-label *ngIf="value !== null && value !== undefined">{{value.toString()|translate}}</ion-label>
	`,
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => BooleanObjectValueVisualizer),
	    }
	],
})
export class BooleanObjectValueVisualizer extends ObjectVisualizerComponent{

	protected _value;

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}
	
}

@ObjectVisualizer(0.25,VisualizerSelectors.Type(intMeta,floatMeta,strMeta,IntMeta,FloatMeta,StrMeta,String,Number,'str','string','int','number','float','Int','wackytype.structure.Int'))
@Component({
	selector: 'atomic-object-value-visualizer',
	template:`
	<ion-label>{{value}}</ion-label>`,
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => AtomicObjectValueVisualizer),
	    }
	],
})
export class AtomicObjectValueVisualizer extends ObjectVisualizerComponent{

	protected _value;

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}
	
}

@ObjectVisualizer(0.25,VisualizerSelectors.Type('datetime.datetime','datetime','Date','DateTime',Date))
@Component({
	selector: 'date-object-visualizer',
	template:`<ion-label>{{displayValue}}</ion-label>`,
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => DateObjectVisualizer),
	    }
	],
})
export class DateObjectVisualizer extends ObjectVisualizerComponent{

	protected _value : Date;
	protected displayValue : string;

	readValue(){
		return this._value;
	}

	writeValue(value : Date){
		this._value = value
		if(value){
			if(!(value instanceof Date)) value = new DissysDate(value);
			this.displayValue = value.toLocaleDateString('de-DE')+' '+value.toLocaleTimeString('de-DE')
		}
		else this.displayValue = '';
	}
	
}

@ObjectVisualizer(0.25,VisualizerSelectors.Type('wackytype.structure.KnownDict'))
@Component({
	selector: 'knowndict-property-visualizer',
	template:`
		<ion-item *ngFor="let prop of props">
			<ion-label color="primary">{{prop.name|translate}}</ion-label>
			<object-visualizer [db]="db" [type]="prop.type.type" [value]="_value[prop.name]"></object-visualizer>
		</ion-item>
	`,
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => KnownDictPropertyVisualizerComponent),
	    }
	],
	styles:[`
		:host{
			display: flex;
			flex: 1 1 auto;
			flex-wrap: wrap;
			flex-direction: row;
		}

		:host > *{
			display: flex;
			flex: 1 1 auto;
		}
	`]
})
export class KnownDictPropertyVisualizerComponent extends ObjectVisualizerComponent{

	protected _value;
	protected formgroup : FormGroup;
	protected props : Array<{component:Type<any>,type:PropertyMeta|ParameterMeta,name:string}>;
	protected subscriptions : Subscription[];

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}

	protected async init(){
		await super.init();
		let controls = {};
		if(!this._value) this._value = new this.typeMeta.codeObject();
		this.props = this.typeMeta.attributes.__attrtypes__.default.map(i=>{
			if(!(i.name in this._value)) this._value[i.name] = i.default;
			controls[i.name] = new FormControl(this._value[i.name]);
			return {
				component: ObjectVisualizerComponent.getComponent(this.typeMeta).component,
				type: i,
				name: i.name,
				value: this._value[i.name]
			};
		});
	}
}

@ObjectVisualizer(0.25,VisualizerSelectors.Type('wackytype.structure.UniformDict'))
@Component({
	selector: 'uniformdict-property-visualizer',
	template:`
		<ion-item *ngFor="let prop of props">
			<ion-label color="primary">{{prop.name|translate}}</ion-label>
			<object-visualizer [db]="db" [type]="prop.type.type" [value]="_value[prop.name]"></object-visualizer>
		</ion-item>
	`,
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => UniformDictPropertyVisualizerComponent),
	    }
	],
	styles:[`
		:host{
			display: flex;
			flex: 1 1 auto;
			flex-wrap: wrap;
			flex-direction: row;
		}

		:host > *{
			display: flex;
			flex: 1 1 auto;
		}
	`]
})
export class UniformDictPropertyVisualizerComponent extends ObjectVisualizerComponent{

	protected _value;
	protected formgroup : FormGroup;
	protected props : Array<{component:Type<any>,type:PropertyMeta|ParameterMeta,name:string}>;
	protected subscriptions : Subscription[];

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}

	protected async init(){
		await super.init();
		let controls = {};
		if(!this._value) this._value = new this.typeMeta.codeObject();
		this.props = this.typeMeta.attributes.__attrtypes__.default.map(i=>{
			if(!(i.name in this._value)) this._value[i.name] = i.default;
			controls[i.name] = new FormControl(this._value[i.name]);
			return {
				component: ObjectVisualizerComponent.getComponent(this.typeMeta).component,
				type: i,
				name: i.name,
				value: this._value[i.name]
			};
		});
	}
}

@ObjectVisualizer(0.25,VisualizerSelectors.Type(SetMeta,setMeta,Set))
@Component({
	selector: 'object-set-visualizer',
	template:`
		<object-visualizer *ngFor="let val of displayValues" style="width:100%;" [value]="val" [type]="ClassMeta.getMeta(val)" [db]="db" [config]="{injectors:injectors}"></object-visualizer>
	`,
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => SetObjectVisualizerComponent),
	    }
	],
})
export class SetObjectVisualizerComponent extends ObjectVisualizerComponent{

	protected _value : Set<any>;
	protected injectors : Array<string> = [];
	protected ClassMeta = ClassMeta;
	//protected _order : OrderDesc;
	protected displayValues : Array<any> = [];
	protected isreadonly = false;

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
		if(value){
			this.displayValues = Array.from(value);
			//this.applyOrder();
		}
		else{
			this.displayValues = [];
		}
	}

}

@ObjectVisualizer(0.20,VisualizerSelectors.Type(ObjectMeta,objectMeta,Object,'object'))
@Component({
	selector: 'object-base-visualizer',
	templateUrl:'./object-base-visualizer.component.html',
	styleUrls: ['./object-base-visualizer.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ObjectBaseVisualizer),
	    }
	],
})
export class ObjectBaseVisualizer extends ObjectVisualizerComponent{

	protected _value;
	protected displayProps = [];

	async init(){
		await super.init();
		//this is needed because for some reason init is fired multiple times
		this.displayProps = [];
		for(let i of Object.values(this.typeMeta.attributes)){
			let ok = true;
			if(dictMeta.isBasetypeOf(i.type) || listMeta.isBasetypeOf(i.type) || setMeta.isBasetypeOf(i.type)) continue;
			for(let km in i.mods) if([
				'wackytype.metadata.static',
				'wackytype.metadata.private',
			].indexOf(i.mods[km].qualname) > -1) {ok = false; break;}
			if(ok && (!this.injectors ||this.injectors.indexOf(i.name) == -1)){
				this.displayProps.push(i);
			}
		}
	}
	
	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value
	}
	
}

