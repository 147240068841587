import { NgModule, Component, Injector, AfterViewInit, Inject, ElementRef, ViewChild, Injectable, Optional, EventEmitter, Renderer2, RendererFactory2, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, RouterStateSnapshot, ActivatedRouteSnapshot, Route, UrlSegment } from '@angular/router';

import { Guard as AuthGuard, Page as AuthPage } from './auth/module';
import { Guard as InstanceGuard, InstanceSelectorPage, InstanceSelector } from './instance-selector/module';

import { AppComponent } from './app.component';
import { TestComponent } from './test.component';
//import { StartPage, RedirectComponent } from './start.page';
import { Router } from '@angular/router';
import { CanLoad, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuController, ModalController, IonModal } from '@ionic/angular';

import { APIService } from './api/module';
import { LanguageService } from './language/module';

@Injectable({
	providedIn: 'root'
})
export class PreloadGuard implements CanLoad, CanActivate {

	protected iselSub = this.isel.onChange.subscribe(()=>{
		const isGuardActive = this.isGuardActive(this.router.routerState.snapshot.root);

		 if (isGuardActive) {
			 // Führen Sie die Umleitung durch, da der Guard aktiv ist
			 //this.router.navigate(["/"], { queryParams: { redirect: btoa(this.router.routerState.snapshot.url) } });
		 }
	});

	protected deauthSub = this.api.auth.onDeauth.subscribe(()=>{
		const isGuardActive = this.isGuardActive(this.router.routerState.snapshot.root);

		 if (isGuardActive) {
			 // Führen Sie die Umleitung durch, da der Guard aktiv ist
			 //this.router.navigate(["/"], { queryParams: { redirect: btoa(this.router.routerState.snapshot.url) } });
		 }
	});

	protected iselReadyProm : Promise<void>;

	constructor(
		protected api : APIService,
		protected router : Router,
		protected menuctrl : MenuController,
		protected isel : InstanceSelector,
		protected lctl : LoadController,
	){
		
	}

	subscribeToData() {
  return new Promise((resolve, reject) => {
	const subscription = this.isel.onChange.subscribe( (data) => {
	  // Wenn Daten empfangen werden, erfüllen Sie die Promise mit den Daten
	  resolve(data);
	  
	  // Oder wenn ein Fehler auftritt, lehnen Sie die Promise ab
	  // reject(error);
	});

	// Optional: Fügen Sie einen Cleanup-Handler hinzu, um die Subscription zu beenden
	// und Ressourcen freizugeben, wenn sie nicht mehr benötigt wird.
	// subscription.unsubscribe();
  });
}
	
	async canLoad(
		route: Route, segments: UrlSegment[]
	) {
		if(this.api.isReady) return true;
		await this.subscribeToData();
		await this.lctl.load(async ()=>await this.api.ready);
		if(!this.api.isReady){
			//this.router.navigateByUrl('/initializing');
			return false;
		}
		return true;
	}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		if(this.api.isReady) return true;
		await this.subscribeToData();
		await this.lctl.load(async ()=>await this.api.ready);
		if(!this.api.isReady){
			//this.router.navigateByUrl('/initializing');
			return false;
		}
		return true;
	}

	// Hilfsfunktion, um zu überprüfen, ob der Guard auf der aktuellen Route aktiv ist
	private isGuardActive(route: ActivatedRouteSnapshot): boolean {
		if (route.routeConfig && route.routeConfig.canLoad && route.routeConfig.canLoad.includes(PreloadGuard)) {
			return true;
		}
		// Überprüfen Sie die Elternroute, wenn vorhanden
		if (route.parent) {
			return this.isGuardActive(route.parent);
		}
		return false;
	}
}

@Component({
	selector: 'preloading-page',
	template: `
			<load-indicator-component #loader></load-indicator-component>
			<!--<h6>{{api.info.owner.name}}</h6>
			<h6>{{'loading_init_data'|translate}}</h6>-->
			<h6>Initialisiere bitte warten</h6>
	`,
	styles: [`
		:host{
			position: fixed;
			display: flex;
			z-index: 999999;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100vw;
			height: 100vh;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background-color: #fff;

		}

		:host:after{
			position: absolute;
			content: "";
			background-repeat: no-repeat;
			background-position: center center;
			background-color: var(--ion-color-primary);
			opacity: 0.5;
			top:0;
			bottom: 0;
			right: 0;
			left:0;
			z-index: -1;
		}

		h6{
			font-size: 2em;
			color: #444;
			margin-top: 32px;
		}
	`]
})
export class PreloadingPage implements AfterViewInit {

	@ViewChild('loader',{static:true}) protected loader : LoadIndicatorComponent;
	private initRes;
	private initProm = new Promise<void>((res)=>{this.initRes=res;})

	constructor(
		protected api : APIService,
		protected router : Router,
		protected translate : LanguageService,
		//@Inject(AppComponent) protected app : AppComponent,
	) {
		
	}

	async load<R>(cb : (loadSup : LoadSupervision<R>)=>Promise<R>|R) : Promise<R>{
		await this.initProm;
		return await this.loader.addLoad(cb);
	}

	ngAfterViewInit(){
		this.initRes();
		//setTimeout(this.initRes);
	}

	//ionViewDidEnter(){
	//	this.loader.addLoad(async ()=>await this.api.ready).then(()=>{
	//		//if(this.app.initPath == '/initializing')
	//		//	this.router.navigateByUrl('/');
	//		//else
	//		//	this.router.navigateByUrl(this.app.initPath);
	//	});
	//}
}


export class LoadSupervision<RET> extends Promise<RET> {

	readonly state : ()=>[number,string];

	constructor(
		loader : (progress ?: (p:number)=>void, sigreg ?: (sig : AbortSignal)=>void) => Promise<RET>|RET){
		let resolve;
		let reject;
		super((res,rej)=>{
			resolve = res;
			reject = rej;
		});

		let state = 0;
		let currentProcess = 0;
		const subThens = [];
		const thenGetter = cb=>{
			return new Promise<RET>((...args:Array<any>)=>{
				subThens.push(args);
				if(state === 0){
					state = 1;

				}
			});
		};
		const stateGetter = ()=>{
			if(state == 0) return [0,'inited'];
			if(state == 1) return [1,'loading'];
			if(state == 2) return [2,'ready'];
			if(state == 3) return [3,'paused'];
			if(state == 4) return [4,'aborted'];
			if(state == 5) return [5,'error'];
		};
		Object.defineProperty(this, "then", {
		    value: thenGetter,
		    enumerable: true
		});
		Object.defineProperty(this, "state", {
		    get: stateGetter,
		    enumerable: true
		});
		Object.defineProperty(this, "currentProcess", {
		    get: ()=>currentProcess,
		    enumerable: true
		});
	}
};

@Injectable()
export class LoadController{
	private loadCounter = 0;
	private modalReady : Promise<HTMLIonModalElement>;
	readonly onLoadStart : EventEmitter<void> = new EventEmitter<void>();
	readonly onLoadReady : EventEmitter<void> = new EventEmitter<void>();
	readonly onLoadError : EventEmitter<void> = new EventEmitter<void>();
	readonly onLoadsReady : EventEmitter<void> = new EventEmitter<void>();
	private readonly loadDispatcher : Observable<void> = new Observable<void>();
	private renderer: Renderer2;
	private loadIndicator : LoadIndicatorComponent;
	protected loadIndicatorComponent : {new(...args) : LoadIndicatorComponent}
	protected loaderComponentRef : ComponentRef<PreloadingPage>;

	constructor(
		private rendererFactory: RendererFactory2,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector
		//@Optional() @Inject(LoadIndicatorComponent) protected loadIndicatorComponent ?: {new(...args) : LoadIndicatorComponent},
	){
		if(!this.loadIndicatorComponent) this.loadIndicatorComponent = LoadIndicatorComponent;
	}

	async load<R>(cb : (loadSup : LoadSupervision<R>)=>Promise<R>|R) : Promise<R>{
		if(!this.loaderComponentRef){
	   		this.renderer = this.rendererFactory.createRenderer(null, null);
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PreloadingPage);
			const componentRef = this.loaderComponentRef = componentFactory.create(this.injector);
			//this.renderer.setStyle(componentRef.location.nativeElement, 'display', 'none');
			document.body.insertBefore(componentRef.location.nativeElement, document.body.firstChild)
			componentRef.instance.ngAfterViewInit();
		}
		this.renderer.removeStyle(this.loaderComponentRef.location.nativeElement, 'display');
		try{
			return await this.loaderComponentRef.instance.load(cb);
		}
		finally{
			this.renderer.setStyle(this.loaderComponentRef.location.nativeElement, 'display', 'none');
		}
	}
}

@Component({
	selector: 'load-indicator-component',
	template: `
		<img #img src="/assets/icon/favicon.png"/>
		<div class="shine"></div>
	`,
	styles: [`
		:host{
			position: relative;
			overflow: hidden;
			display: flex;
			flex: 1 1 auto;
			max-width: 300px;
			max-height: 300px;
			flex-direction: column;
			--background-color-h: 1;
			--background-color-s: 0%;
			--background-color-l: 95%;
			--background-color-hsl: hsl(var(--background-color-h) var(--background-color-s) var(--background-color-l));
			--background-color-r: none;
			--background-color-g: 128;
			--background-color-b: 128;
			--background-color-rgb: rgb(var(--background-color-r),var(--background-color-g),var(--background-color-b));
			--background-color: var(--background-color-hsl, var(--background-color-rgb));
			position: relative;
			overflow: hidden;
			width: 500px;
			height: 500px;
			border-radius: 24%;
			background-color: var(--background-color);
			box-shadow: 10px 10px 50px #888;
			padding: 8px;
		}
		
		img{
			animation: imageAnimation 1s linear infinite alternate;
			max-width: 100%;
			height: auto;
			display: block;
		}

		.shine {
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(-45deg, transparent 0%, transparent 45%, rgba(255, 255, 255, 0.5) 55%, transparent 56%, transparent 100%);
			top: -100%;
			left: -100%;
			animation: shineAnimation 4s linear infinite;
		}

		@keyframes shineAnimation {
		  0% {
			transform: translate(0%, 0%);
		  }
		  25% {
			transform: translate(200%, 200%);
		  }
		  100%{
			transform: translate(200%, 200%);
		  }
		}

		@keyframes imageAnimation {
			0% {
				filter: brightness(50%); /* Anfangssättigung */
			}
			100% {
				filter: brightness(150%); /* Endsättigung */
			}
		}
	`]
})
export class LoadIndicatorComponent {

	@ViewChild('img',{static:true,read:ElementRef}) protected img : ElementRef;

	protected currentLoad = [];
	protected readyProm : Promise<any>;

	constructor(
		protected api : APIService,
		protected isel : InstanceSelector,
		protected router : Router,
		//@Inject(AppComponent) protected app : AppComponent,
	) {
		
	}

	addLoad(cb){
		this.currentLoad.push(cb);
		if(this.currentLoad.length == 1){
			this.readyProm = new Promise((res,rej)=>requestAnimationFrame(this.run.bind(this,res,rej)));
			//this.readyProm = this.run(()=>{},()=>{});
		}
		return this.readyProm;
	}

	async run(ready,error){
		//this.img.nativeElement.style.animationPlayState = 'running'; // Starte die Animation
		const res = [];
		let ok = true;
		const asyncs = [];
		while(this.currentLoad.length>0){
			const cb = this.currentLoad.shift();
			try {
				const r = cb();
				res.push(r); // Warte auf das Ende der asynchronen Operation
				asyncs.push(r);
				// Hier kannst du deinen weiteren Code ausführen
				// z.B. Navigation mit this.router.navigateByUrl(...)
			} catch (error) {
				console.error(error);
				ok = false;
				res.push(error);
			} finally {
				//this.img.nativeElement.style.animationPlayState = 'paused'; // Pausiere die Animation nach der Aufgabe
			}
		}
		for(let i of asyncs){
			try{
				res[res.indexOf(i)] = await i;
			}
			catch(error){
				console.error(error);
				ok = false;
				res[res.indexOf(i)] = error;
			}
		}
		if(ok) ready(res);
		else error(res);
	}
}

@Component({
	selector: 'not-found-page',
	template: `
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-label>{{ 'language' | translate }} </ion-label>
				<ion-select value="{{translate.defaultLang}}" okText="{{'btn_label_save' | translate}}" cancelText="{{'btn_label_cancel' | translate}}" (ionChange)="setLang($event)">
					<ion-select-option value="de">{{ 'language_de' | translate }}</ion-select-option>
					<ion-select-option value="en">{{ 'language_en' | translate }}</ion-select-option>
				</ion-select>
			</ion-buttons>
			<div class="instance-logo"></div>
			<ion-buttons slot="end">
				<ion-button (click)="router.navigateByUrl('/')">
					<ion-icon slot="icon-only" name="home"></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<ion-content padding>
		<div>
			<div>
				<h6>{{'page_not_found'|translate}}</h6>
			</div>
		</div>
	</ion-content>
	`,
	styles: [`
		:host{
		}

		ion-content > div{
			position: relative;
			display: flex;
			flex: 1 1 auto;
			min-height: 100%;
			min-width: 100%;
			justify-content: center;
			align-items: center;
		}

		ion-content > div > div{
			display: flex;
			flex: 0 0 content;
			padding: 8px 16px;
			border-radius: 20px;
			background-color: #fff;
			box-shadow: 5px 5px 40px #444;
		}

		h6{
			font-size: 2em;
			font-weight: 200;
		}

		ion-content > div:after{
			position: absolute;
			content: "";
			/*background-image: url(/instance-login-bg.jpg) !important;*/
			background-repeat: no-repeat;
			background-position: center center;
			background-color: var(--ion-color-primary);
			opacity: 0.5;
			top:0;
			bottom: 0;
			right: 0;
			left:0;
			z-index: -1;
		}
		
	`]
})
export class NotFoundPage {

	//@ViewChild('loader',{static:true}) protected loader : LoadIndicatorComponent;

	constructor(
		protected api : APIService,
		protected router : Router,
		protected translate : LanguageService,
		//@Inject(AppComponent) protected app : AppComponent,
	) {
		
	}


	//ionViewDidEnter(){
	//	this.loader.addLoad(async ()=>await this.api.ready).then(()=>{
	//		//if(this.app.initPath == '/initializing')
	//		//	this.router.navigateByUrl('/');
	//		//else
	//		//	this.router.navigateByUrl(this.app.initPath);
	//	});
	//}
}


@Injectable({
	providedIn: 'root'
})
export class NoAccessGuard implements CanActivate {
	
	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		return false;
	}
}
