import { Component, OnInit , AfterViewInit, Input, Output, EventEmitter, Renderer2, ViewChildren, ComponentFactoryResolver,forwardRef,Type, Directive, ElementRef, ComponentRef, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController,IonInput, MenuController } from '@ionic/angular'
import { Validators, FormBuilder, FormGroup, FormControl, AbstractControl,ValidationErrors } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
	ExpandDesc,
	ClassMeta,
	ParameterMeta,
	PropertyMeta,
	ObjectMeta,
	Loader,
	objectMeta,
	IntMeta,
	FloatMeta,
	floatMeta,
	StrMeta,
	ListMeta,
	listMeta,
	boolMeta,
	SetMeta,
	setMeta,
	bytesMeta,
	ObjDB,
	ClassMapperMeta
} from 'dissys';
import { ObjectEditorComponent, ObjectEditorComponentLoader, ObjectEditorBaseComponent } from './object-editor.component';
import { ObjectBaseEditor } from './object-base-editor.component';
import * as common from './common';
import { LanguageService } from '../language/module';
import { BaseComponent, Constructor } from './common';


const DefProp = new PropertyMeta('',null);

export type PropertySelector = (meta:ClassMeta,prop:PropertyMeta)=>boolean;
export type PropertyLoader = (component:object)=>void;
export const PropertySelectors = {
	Type : function(...types : Array<ClassMeta|string|{new(...args):any}>){
		return (meta:ClassMeta,prop:PropertyMeta)=>types.reduce((a,v)=>a||prop.type.isSubtypeOf(v),false);
	},
	Prop : function PropSelector(...props : string[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>props.reduce((a,v)=>a||prop.name == v,false);
	},
	Or : function(...selectors : PropertySelector[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>selectors.reduce((a,v)=>a||v(meta,prop),false);
	},
	And : function(...selectors : PropertySelector[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>selectors.reduce((a,v)=>a&&v(meta,prop),true);
	},
	Name : function(...names : string[]){
		return (meta:ClassMeta,prop:PropertyMeta)=>names.reduce((a,v)=>a||v==prop.name,false);
	}
};

type RegistryEntry = {
	component: Type<ObjectPropertyEditorComponent>,
	selectors: Array<PropertySelector>,
	p:number,
	loader:PropertyLoader,
};

class NotFound extends Error{}

@Component({})
export abstract class ObjectPropertyEditorComponent<T = any> extends ObjectEditorBaseComponent{

	protected propertyMeta : PropertyMeta = DefProp;
	protected _placeholder : string = '';
	protected _object : any = null;

	@Input()
	set placeholder(txt:string){
		this._placeholder = txt;
	}

	get placeholder(){
		return this._placeholder;
	}

	@Input()
	set object(o : any){
		this._object = o;
	}

	get object(){
		return this._object;
	}

	@Input()
	set prop(prop : PropertyMeta){
		this.propertyMeta = prop
		this.typeMeta = prop.type;
		this.typeInfo = prop.type.qualname;
	}

	get prop(){ return this.propertyMeta; }

	private static componentRegistry : RegistryEntry[] = [];
	private static configRegistry : {config:{[key:string]:any},selectors:PropertySelector[]}[] = [];

	static registerComponent(ctor, selector : PropertySelector, loader?:PropertyLoader);
	static registerComponent(ctor, p : number, selector : PropertySelector, loader?:PropertyLoader);
	static registerComponent(ctor, ...args : any[]){
		let p = 0.5;
		if(typeof args[0] === 'number'){
			p = args[0];
			args.shift();
		}
		ObjectPropertyEditorComponent.componentRegistry.push({
			component : ctor,
			selectors : [args[0]],
			p:p,
			loader:args[1],
		});
	}

	static getComponent(meta:ClassMeta,prop:PropertyMeta) : RegistryEntry{
		let e = null;
		let reg = ObjectPropertyEditorComponent.componentRegistry;
		for(let i of reg){
			let entry = i;
			if(entry.selectors.reduce((a,v)=>a&&v(meta,prop),true)){
				if(e === null || e.p < entry.p) e = entry;
			}
		}
		if(e){ 	return e; }
		console.error(meta)
		console.error(prop)
		throw new NotFound(`no component found for ${meta.qualname}.${prop.name}`);
	}

	static getConfig(meta:ClassMeta,prop:PropertyMeta){
		for(let entry of ObjectPropertyEditorComponent.configRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta,prop),true)) return entry.config;
		}
		throw new NotFound(`no component found for ${meta.qualname}.${prop.name}`);
	}

	static registerConfig(config : {[key:string]:any}, ...selectors : PropertySelector[]){
		ObjectPropertyEditorComponent.configRegistry.push({
			config : config,
			selectors : selectors,
		});
	}

	static extendExpand(name : string, expand : ExpandDesc){}
}

export function ObjectPropertyEditor(selector : PropertySelector, onLoad ?: PropertyLoader);
export function ObjectPropertyEditor(p : number, selector : PropertySelector, onLoad ?: PropertyLoader);
export function ObjectPropertyEditor(...args : any[]){
	return <T>(ctor : T)=>{
		args.unshift(ctor)
		ObjectPropertyEditorComponent.registerComponent.apply(ObjectPropertyEditorComponent,args);
		return ctor
	};
} 

@Component({
	selector:'object-property-editor',
	template:'<ng-template #compslot></ng-template>',
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectPropertyEditorComponentLoader),
		}
	],
})
export class ObjectPropertyEditorComponentLoader<T = any> extends ObjectPropertyEditorComponent{

	protected cfg;
	protected _prop;
	protected componentInstance : ComponentRef<ObjectPropertyEditorComponent> = null;
	protected _value;

	@ViewChild('compslot', {static: true, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;

	@Input()
	set config(c){
		this.cfg = c;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver
		);
	}

	protected async init(){
		await super.init();
		this.loadPlugins();
	}

	reset(){
		this.loadPlugins();
	}

	writeValue(value){
		this._value = value;
		if(this.componentInstance)
			this.componentInstance.instance.writeValue(value);
	}

	readValue(){
		if(this.componentInstance)
			return this.componentInstance.instance.readValue();
		return this._value;
	}

	get value(){
		return this.componentInstance ? this.componentInstance.instance.value : this._value;
	}

	set value(value){
		if(this.componentInstance)
			this.componentInstance.instance.value = value;
		this._value = value;
	}

	get db(){
		return this.componentInstance ? this.componentInstance.instance.db : this._db;
	}

	set db(value){
		this._db = value;
		if(this.componentInstance)
			this.componentInstance.instance.db = value;
	}

	get prop(){
		return this.componentInstance ? this.componentInstance.instance.prop : this._prop;
	}

	set prop(value){
		this._prop = value;
		if(this.componentInstance)
			this.componentInstance.instance.prop = value;
	}

	protected loadPlugins() {
		let comp : Type<ObjectPropertyEditorComponent>;
		let loader;
		if(this.typeMeta && this.prop){
			let e = ObjectPropertyEditorComponent.getComponent(this.typeMeta,this.prop);
			comp = e.component;
			loader = e.loader;
		}		
		if(comp){
			const componentRef = this.loadComponent(this.pluginHost,comp);
			this.componentInstance = componentRef;

			(<any>this.componentInstance.instance).onChange = this.onChange;
			(<any>this.componentInstance.instance).onTouched = this.onTouched;
			
			this.componentInstance.instance.setOptions({
				prop:this._prop,
				db:this._db,
				value:this._value,
				...this.cfg
			})
			if(loader) loader(componentRef);
			/*for(let i of Object.getOwnPropertyNames(componentRef.instance)){
				console.log('define',i)
				try{
					Object.defineProperty(this,i,{
						get:()=>{console.log('getter override',this,i,componentRef.instance[i]);return componentRef.instance[i];},
						set:(value)=>{componentRef.instance[i] = value;},
					});
				}
				catch(e){}
			}
			let stack = [componentRef.instance.constructor];
			let d = {};
			while(stack.length > 0){
				let j = stack.shift();
				console.log('proto',j)
				//if(j.constructor && j.constructor != Object) stack.push(j.constructor);
				if(Object.getPrototypeOf(j)) stack.push(Object.getPrototypeOf(j));
				if(j.prototype) for(let i of Object.getOwnPropertyNames(j.prototype)){
					console.log('base define',i)
					if(i in componentRef.instance) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{console.log('getter override',this,i,componentRef.instance[i]);return componentRef.instance[i];},
							set:(value)=>{componentRef.instance[i] = value;},
						});
					}
					catch(e){}
				}
				for(let i of Object.getOwnPropertyNames(j)){
					console.log('base define',i)
					if(i in componentRef.instance) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{console.log('getter override',this,i,componentRef.instance[i]);return componentRef.instance[i];},
							set:(value)=>{componentRef.instance[i] = value;},
						});
					}
					catch(e){}
				}				
			}*/
		}
		else{
			console.warn(`could not get component class for type ${this.typeMeta}`);
		}
	}
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type(boolMeta,Boolean,'boolean','bool'))
@Component({
	selector: 'bool-object-property-editor',
	template:'<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label><ion-checkbox [disabled]="readonly" [(ngModel)]="value" (click)="$event.preventDefault();$event.stopPropagation();onTouched.emit();" (change)="$event.preventDefault();$event.stopPropagation();" (ionChange)="$event.preventDefault();$event.stopPropagation();"></ion-checkbox>',
	//templateUrl: './bool-property-editor.html',
	//styleUrls: ['./object-base-editor.component.scss'],
	styles:[`
		ion-checkbox{
			margin-top: 10px !important;
			margin-bottom: 8px !important;
		}
	`],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => BoolObjectPropertyEditorComponent),
	    }
	],
})
export class BoolObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value = false;


	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value ? true : false;
		this.onTouched.emit();
		this.onChange.emit(value);
	}

	ionViewWillEnter(){
		this.onChange.emit(false);
	}
	
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type(bytesMeta,'builtins.bytes'))
@Component({
	selector: 'bytes-object-property-editor',
	template: `
		<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label>
		<input type="file" (change)="onFileChange($event)" />
	`,
	//templateUrl: './bool-property-editor.html',
	//styleUrls: ['./object-base-editor.component.scss'],
	styles:[`
	`],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => BytesObjectPropertyEditorComponent),
	    }
	],
})
export class BytesObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value : Uint8Array;

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
		this.onTouched.emit();
		this.onChange.emit(value);
	}

	protected async onFileChange(e : Event){
		this._value = new Uint8Array(await (event.target as HTMLInputElement).files[0].arrayBuffer());
		this.onTouched.emit();
		this.onChange.emit(this._value);
	}
}

import {} from '@ionic/core';

@ObjectPropertyEditor(0.25,PropertySelectors.Type(IntMeta))
@Component({
	selector: 'int-object-property-editor',
	template:'<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label><ion-input #input [readonly]="readonly" (change)="$event.preventDefault();$event.stopPropagation();" (keydown)="keydown($event)" (ionChange)="$event.preventDefault();$event.stopPropagation();writeByInput($event.detail.value);"></ion-input>',
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => IntObjectPropertyEditorComponent),
	    }
	],
})
export class IntObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	@ViewChild('input',{static:true}) inputElement : any;
	protected preventChangeEmit = 0;
	protected numberRe = new RegExp('[^0-9]','g');
	console = console;
	readValue(){
		return parseInt(this.inputElement.el.value);
	}

	writeValue(value){
		if(!Number.isInteger(value)) return;
		
		let emit = this.inputElement.el.value != value.toString();
		this.preventChangeEmit++;
		this.inputElement.el.value = value;
		if(emit){
			this.onChange.emit(this.readValue());
		}			
	}

	protected writeByInput(value : string){
		if(this.preventChangeEmit > 0){
			this.preventChangeEmit--;
			return;
		}
		let nv = value.replace(this.numberRe,'')
		let emit = true;
		if(nv!=value){
			emit = false
			this.preventChangeEmit+=1;
			this.inputElement.el.value = nv;
		}
		if(emit) this.onChange.emit(parseInt(nv));
	}

	protected keydown(e : KeyboardEvent, input : string){
		console.log(e);
		if(e.key === 'Backspace'){
			this.inputElement.el.value = (<any>this.inputElement.el.value).substring(0, (<any>this.inputElement.el.value).length - 1);
		}
		else if(['Tab','ArrowRight','ArrowLeft','ArrowDown','ArrowUp'].indexOf(e.key) >= 0){}
		else if((!e.ctrlKey || e.altKey) &&  e.key.match(this.numberRe)){
			e.stopPropagation();
			e.preventDefault();
			e.cancelBubble = true;
			this.presentToast('only_digit_allowed');
		}
	}
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type(FloatMeta,Number,floatMeta,'builtinsfloat'))
@Component({
	selector: 'float-object-property-editor',
	template:`
		<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label>
		<span>
			<span class="sd">
				<ion-input #predot [readonly]="readonly" value="{{predotval}}" styles="width:100%" (keydown)="keydown($event,'predot')" (change)="$event.preventDefault();$event.stopPropagation();" (ionChange)="$event.preventDefault();$event.stopPropagation();changePreDot($event.detail.value);" (keypress)="keypress($event,'postdot')">
				</ion-input>
				<span>{{predotval}}</span>
			</span><span [class.hidden]="postdotval == '' && (!showDot || !predotval)">.</span><ion-input #postdot value="{{postdotval}}" (ionFocus)="postDotFocus(true,$event)" (ionBlur)="postDotFocus(false,$event)" (keydown)="keydown($event,'postdot')" (keypress)="keypress($event,'postdot')" (change)="$event.preventDefault();$event.stopPropagation();" (ionChange)="$event.preventDefault();$event.stopPropagation();changePostDot($event.detail.value);">
			</ion-input>
		<span>`,
	//styleUrls: ['./object-base-editor.component.scss'],
	styles:[`
		:host > span{
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			align-items: baseline;
		}

		.hidden{
			visibility: hidden;
		}

		.sd {
			display:flex;
			flex-direction: column;
			flex: 0 0 0% !important;
		}
		.sd > span{ z-index:-9999; visibility:hidden; height:0px; flex:0 0 auto;}
	`],
	host: {
		"(click)": "onClick($event)"
	},
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => FloatObjectPropertyEditorComponent),
	    }
	],
})
export class FloatObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	@ViewChild('predot',{static:true,read: ElementRef}) preDot : ElementRef;
	@ViewChild('postdot',{static:true,read: ElementRef}) postDot : ElementRef;
	protected predotval = '';
	protected postdotval = '';
	protected numberRe = new RegExp('[^0-9]','g');
	protected preventChangeEmit = 0;
	protected document = document;
	protected showDot = false;

	readValue(){
		if(!this.predotval && !this.postdotval) return null;
		return parseFloat((this.predotval || '0') + '.' + (this.postdotval || '0'));
	}

	postDotFocus(v : boolean, e : Event){
		if(v == false) this.showDot = false;
		if(false && !this.showDot && this.postdotval == ''){
			e.stopPropagation();
			e.preventDefault();
			e.cancelBubble = true;
			this.preDot.nativeElement.querySelector('input').focus();
		}
		else{
			this.showDot = v;
		}
	}

	protected changePreDot(value){
		if(this.preventChangeEmit > 0){
			this.preventChangeEmit--;
			return;
		}
		let val = value == '' ? '' : value;
		let nv = val.replace(this.numberRe,'')
		//nv == '' && (nv = '0');
		let emit = true;
		if(nv!=value){
			emit = false
			this.preventChangeEmit+=1;
		}
		this.preDot.nativeElement.querySelector('input').value = nv;
		this.predotval = nv;
		if(emit)
			this.onChange.emit(this.readValue());
	}

	protected changePostDot(value){
		if(this.preventChangeEmit > 0){
			this.preventChangeEmit--;
			return;
		}
		let val = value
		let nv = val.replace(this.numberRe,'')
		//nv == '' && (nv = '0');
		this.postdotval = nv;
		let emit = true;
		if(nv!=val){
			emit = false
			this.preventChangeEmit+=1;
			this.postDot.nativeElement.querySelector('input').value = nv;
		}
		if(emit)
			this.onChange.emit(this.readValue());
	}

	protected onClick(e : Event){
		e.stopPropagation();
		e.preventDefault();
		let target = e.target as HTMLElement;
		if(!this.predotval)
			this.preDot.nativeElement.querySelector('input').focus();
		else
			target.focus();
	}

	writeValue(value){
		let v : string[];		
		if(Number.isInteger(value)){
			v = [value.toString(),''];
		}
		else if(typeof value === 'number' || <any>value instanceof Number){
			v = value.toString().split('.');
			v.push('');
		}
		else if(value === undefined || value === null){
			v = ['',''];
		}
		else{
			this.presentToast('only_digit_allowed');
			return
		}
		this.predotval = v[0];
		this.postdotval = v[1];
		if(this.preDot.nativeElement.querySelector('input')) this.preDot.nativeElement.querySelector('input').value = v[0];
		if(this.postDot.nativeElement.querySelector('input')) this.postDot.nativeElement.querySelector('input').value = v[1];
	}

	protected keydown(e : KeyboardEvent, input : string){
		if(e.key === 'Backspace'){
			const prev = this.preDot.nativeElement.querySelector('input').value;
			const posv = this.postDot.nativeElement.querySelector('input').value;
			if(input === 'predot' && prev.length > 0) {}
			else if(input == 'postdot' && posv.length > 0) {}
			else if(input == 'postdot' && posv.length == 0) {
				this.preDot.nativeElement.querySelector('input').dispatchEvent(new KeyboardEvent('keydown', e));
				this.preDot.nativeElement.querySelector('input').focus();
				e.stopPropagation();
				e.preventDefault();
				e.cancelBubble = true;
			}
		}
		else if(e.key == '-'){
			if(input != 'predot' || this.predotval != ''){
				e.stopPropagation();
				e.preventDefault();
				e.cancelBubble = true;
			}
		}
		else if(e.key == '.' || e.key == ','){
			e.stopPropagation();
			e.preventDefault();
			e.cancelBubble = true;
			if(input == 'predot'){
				this.showDot = true;
				this.postDot.nativeElement.querySelector('input').focus();
			}
		}
		else if(['Tab','ArrowRight','ArrowLeft','ArrowDown','ArrowUp'].indexOf(e.key) >= 0){}
		else if((!e.ctrlKey || e.altKey) &&  e.key.match(this.numberRe)){
			e.stopPropagation();
			e.preventDefault();
			e.cancelBubble = true;
			this.presentToast('only_digit_allowed');
		}
	}

	protected keypress(e : KeyboardEvent, input : string){
		console.log(e);
		if(e.key === 'Backspace'){
			const prev = this.preDot.nativeElement.querySelector('input').value;
			const posv = this.postDot.nativeElement.querySelector('input').value;
			if(input === 'predot' && prev.length > 0) {}
			else if(input == 'postdot' && posv.length > 0) {}
			else if(input == 'postdot' && posv.length == 0) {
				this.postDot.nativeElement.querySelector('input').dispatchEvent(new KeyboardEvent('keydown', e));
				e.stopPropagation();
				e.preventDefault();
				e.cancelBubble = true;
			}
		}
		else if(e.key == '-'){
			if(input != 'predot' || this.predotval != ''){
				e.stopPropagation();
				e.preventDefault();
				e.cancelBubble = true;
			}
		}
		else if(['Tab','ArrowRight','ArrowLeft','ArrowDown','ArrowUp'].indexOf(e.key) >= 0){}
		else if((!e.ctrlKey || e.altKey) &&  e.key.match(this.numberRe)){
			e.stopPropagation();
			e.preventDefault();
			e.cancelBubble = true;
		}
	}
}

@ObjectPropertyEditor(
	0.25,
	PropertySelectors.And(
		PropertySelectors.Type(StrMeta,String,'string'),
		(meta : ClassMeta, prop : PropertyMeta)=>prop.type.codeObject['__max_length__'] > 0 || (prop.type.attributes.__max_length__ && prop.type.attributes.__max_length__.default > 0),
	)
)
@Component({
	selector: 'str-object-property-editor',
	template:'<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label><ion-input [readonly]="readonly" (keydown)="keydown($event)" maxlength="{{propertyMeta.type.attributes.__max_length__.default}}" [(ngModel)]="value" (change)="$event.preventDefault();$event.stopPropagation();" (ionChange)="$event.preventDefault();$event.stopPropagation();writeByInput($event.detail.value);"></ion-input>',
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => StrObjectPropertyEditorComponent),
	    }
	],
})
export class StrObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value;

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		if((typeof value !== 'string' && !(value instanceof String)) || value.length > this.typeMeta.codeObject.__max_length__){
			return;
		}
		this._value = value;
		if(emit){
			this.onChange.emit(value);
		}
	}

	protected writeByInput(value){
		if(value.length > this.typeMeta.codeObject.__max_length__){
			this.presentToast('value_too_long');
			return;
		}
		this.onChange.emit(value);
		this._value = value;
	}

	protected keydown(e : KeyboardEvent){
		if(this.value && this.value.length == this.typeMeta.codeObject.__max_length__){
			this.presentToast('max_length_reached');
		}
	}
	
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type(StrMeta,String,'string'))
@Component({
	selector: 'text-object-property-editor',
	template:'<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label><ion-textarea #input [value]="_value" [readonly]="readonly" (change)="$event.preventDefault();$event.stopPropagation();" (ionChange)="$event.preventDefault();$event.stopPropagation();writeValue($event.detail.value)"></ion-textarea>',
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => TextObjectPropertyEditorComponent),
	    }
	],
})
export class TextObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value = '';

	@ViewChild('input',{static:true}) input : ElementRef<HTMLTextAreaElement>;

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		this._value = value;
		if(this.input.nativeElement) this.input.nativeElement.value = value;
		if(emit){
			//this.changeDetector.markForCheck();
			//this.changeDetector.detectChanges();
			this.onChange.emit(value);
		}
	}
	
}

@ObjectPropertyEditor(PropertySelectors.Type(ListMeta,listMeta,Array,'Array','array'))
@Component({
	selector: 'object-collection-property-editor',
	/*template:`
		<ion-list *ngIf="_mode == 'sub'">
			<ion-list-header><ion-label color="primary">{{prop.name|translate}}</ion-label></ion-list-header>
			<ion-item *ngFor="let i of _value">
				<ion-input [readonly]="true" [value]="i"></ion-input>
			</ion-item>
		</ion-list>
		<ion-list *ngIf="_mode == 'inline'">
			<ion-list-header><ion-label color="primary">{{prop.name|translate}}</ion-label></ion-list-header>
			<ion-item  *ngFor="let i of _value">
				<div style="flex-direction:column;display:flex;flex 1 1 auto;">
					<ion-input [readonly]="true" [value]="i" (click)="$event.stopPropagation();$event.preventDefault();toggleFadeElement(viz.elementRef);"></ion-input>
				<!--<object-editor #viz style="overflow: hidden;max-height:0px;" [type]="i.constructor" [value]="i"></object-editor>-->
					<object-editor #viz style="overflow: hidden;max-height:0px;" [type]="i.constructor" [value]="i"></object-editor>
				</div>
				<ion-icon *ngIf="i.__slidestate__" slot="end" name="chevron-down-outline"></ion-icon>
				<ion-icon *ngIf="!i.__slidestate__" slot="end" name="chevron-right-outline"></ion-icon>
			</ion-item>
			<ion-item>
				<ion-button expand="block" icon-only color="light" (click)="$event.preventDefault();$event.stopPropagation();addNew();"><ion-icon name="add-outline"></ion-icon></ion-button>
			</ion-item>
		</ion-list>
	`,*/
	template:`
		<ion-list>
			<ion-list-header>
				<ion-label color="primary">{{prop.name | translate}} ({{displayValues.length}})</ion-label>
			</ion-list-header>
			<ion-item *ngFor="let val of displayValues">
				<object-visualizer style="width:100%;" [value]="val" [type]="type.own_attributes.__valuetype__.default" [db]="db" [config]="{injectors:injectors}"></object-visualizer>
				<ion-button slot="end" size="small" fill="clear" *ngIf="!isreadonly && !prop.isreadonly" (click)="$event.stopPropagation();$event.preventDefault();removeItem(val);"><ion-icon slot="icon-only" name="trash-outline"></ion-icon></ion-button>
			</ion-item>
			<ion-item *ngIf="displayValues.length == 0">
				<ion-label style="color:#ddd;">{{'no_entries'|translate}}</ion-label>
			</ion-item>
			<ion-button expand="full" fill="clear" size="small" *ngIf="!isreadonly && !prop.isreadonly" (click)="$event.stopPropagation();$event.preventDefault();onOpenIntend.emit({cb:addItem.bind(this),type:propertyMeta.type.attributes.__valuetype__.default,config:{allowSelect:prop.impref==null,injectors:injectors}})"><ion-icon slot="icon-only" name="add"></ion-icon></ion-button>
		</ion-list>
	`,
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ListPropertyBaseEditorComponent),
	    }
	],
})
export class ListPropertyBaseEditorComponent extends ObjectPropertyEditorComponent{

	protected _value;
	protected _mode : 'inline'|'sub' = 'inline';
	protected injectors : Array<string> = [];
	protected ClassMeta = ClassMeta;
	protected Object = Object;
	protected slideStates = {};
	protected displayValues : Array<any> = [];
	protected isreadonly = false;
	protected _order : OrderDesc;

	@Input()
	set mode(m : 'inline'|'sub'){
		this._mode = m
	}

	@Input()
	set order(value : OrderDesc){
		this._order = value;
		this.applyOrder();
	}

	get order(){
		return this._order;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver,
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver,
		);
	}

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		console.error('alksiusadgusagdlkjsagdkj',value)
		this._value = value;
		if(value){
			this.displayValues = Array.from(value);
			this.applyOrder();
		}
		else{
			this.displayValues = [];
		}
		if(emit){
			this.onChange.emit(value);
		}
	}

	addNew(){
		this.onOpenIntend.emit({type:this.propertyMeta.type.codeObject.__valuetype__,cb:value=>this.writeValue(value)});
	}

	protected addItem(value){
		if(!this._value) this._value = new Set();
		this._value.add(value);
		this.displayValues.push(value);
		this.applyOrder();
		this.onTouched.emit();
		this.onChange.emit(this._value);
	}

	protected applyOrder(){
		if(!this.order) return;
		else if(this.order.length > 1) throw new Error('only one orderpropallowed at the moment');
		for(let o of this.order){
			if(typeof o === 'string'){
				this.displayValues = this.displayValues.sort((a,b)=>a[<any>o]<b[<any>o]?-1:1);
			}
			else{
				if((<any>o).order === 'asc'){
					this.displayValues = this.displayValues.sort((a,b)=>a[(<any>o).prop]<b[(<any>o).prop]?-1:1);
				}
				else if((<any>o).order === 'desc'){
					this.displayValues = this.displayValues.sort((a,b)=>a[(<any>o).prop]>b[(<any>o).prop]?-1:1);
				}
			}
		}		
	}

	ngOnInit(){
	}
}

export type OrderDesc = Array<string|{prop:string,order:'asc'|'desc'}>;

@ObjectPropertyEditor(0.25,PropertySelectors.Type(SetMeta,setMeta,Set))
@Component({
	selector: 'object-set-property-editor',
	template:`
		<ion-list>
			<ion-list-header>
				<ion-label color="primary">{{prop.name | translate}} ({{displayValues.length}})</ion-label>
			</ion-list-header>
			<ion-item *ngFor="let val of displayValues">
				<object-visualizer style="width:100%;" [db]="db" [type]="typeMeta.own_attributes.__valuetype__.default" [value]="val" [config]="{injectors:injectors}"></object-visualizer>
				<ion-button slot="end" size="small" fill="clear" *ngIf="!isreadonly && !prop.isreadonly" (click)="$event.stopPropagation();$event.preventDefault();removeItem(val);"><ion-icon slot="icon-only" name="trash-outline"></ion-icon></ion-button>
			</ion-item>
			<ion-item *ngIf="displayValues.length == 0">
				<ion-label style="color:#ddd;">{{'no_entries'|translate}}</ion-label>
			</ion-item>
			<ion-button expand="full" fill="clear" size="small" *ngIf="!isreadonly && !prop.isreadonly" (click)="$event.stopPropagation();$event.preventDefault();onOpenIntend.emit({cb:addItem.bind(this),type:propertyMeta.type.attributes.__valuetype__.default,config:{allowSelect:prop.impref==null,injectors:injectors}})"><ion-icon slot="icon-only" name="add"></ion-icon></ion-button>
		</ion-list>
	`,
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => SetPropertyBaseEditorComponent),
	    }
	],
})
export class SetPropertyBaseEditorComponent extends ObjectPropertyEditorComponent{

	protected _value : Set<any>;
	protected injectors : Array<string> = [];
	protected ClassMeta = ClassMeta;
	protected _order : OrderDesc;
	protected displayValues : Array<any> = [];
	protected isreadonly = false;

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver,
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver,
		);
	}

	readValue(){
		return this._value;
	}

	writeValue(value){
		if(value != null && !setMeta.instanceOf(value)) value = new setMeta.codeObject(value);
		let emit = this._value != value;
		this._value = value;
		if(value){
			this.displayValues = Array.from(value);
			this.applyOrder();
		}
		else{
			this.displayValues = [];
		}
		if(emit){
			this.onChange.emit(value);
		}
	}

	addNew(){
		this.onOpenIntend.emit({type:this.propertyMeta.type.attributes.__valuetype__.default,cb:value=>this.writeValue(value)});
	}

	@Input()
	set order(value : OrderDesc){
		this._order = value;
		this.applyOrder();
	}

	get order(){
		return this._order;
	}

	protected applyOrder(){
		if(!this.order) return;
		else if(this.order.length > 1) throw new Error('only one orderpropallowed at the moment');
		for(let o of this.order){
			if(typeof o === 'string'){
				this.displayValues = this.displayValues.sort((a,b)=>a[<any>o]<b[<any>o]?-1:1);
			}
			else{
				if((<any>o).order === 'asc'){
					this.displayValues = this.displayValues.sort((a,b)=>a[(<any>o).prop]<b[(<any>o).prop]?-1:1);
				}
				else if((<any>o).order === 'desc'){
					this.displayValues = this.displayValues.sort((a,b)=>a[(<any>o).prop]>b[(<any>o).prop]?-1:1);
				}
			}
		}		
	}

	protected addItem(value){
		if(!this._value) this._value = new Set();
		this._value.add(value);
		this.displayValues.push(value);
		this.applyOrder();
		this.onTouched.emit();
		this.onChange.emit(this._value);
	}

	protected async removeItem(value){
		if(!this._value) this._value = new Set();
		this.showLoading();
		try{
			if(this.provider instanceof Array){

			}
			else if(this.provider instanceof Loader){
				if(value.id) await this.provider.delete(value);
			}
			else if(this.object && this.db){
				let objmeta = ClassMeta.getMeta(this.object);
				if(objmeta instanceof ClassMapperMeta)
					await this.db.requestFunc(`${this.db.url}${objmeta.mapped_qualname}[${this.object.id}]()[0]/${this.prop.name}/remove(db/${this.prop.type.attributes.__valuetype__.default.qualname}[${value.id}]()[0])`,'GET');
			else
				await this.db.requestFunc(`${this.db.url}${objmeta.qualname}[${this.object.id}]()[0]/${this.prop.name}/remove(db/${this.prop.type.attributes.__valuetype__.default.qualname}[${value.id}]()[0])`,'GET');
			}
			this._value.delete(value);
			this.displayValues = Array.from(this._value);
			this.applyOrder();
			this.onTouched.emit();
			this.onChange.emit(this._value);
		}
		catch(e){
			console.error(e);
			this.presentToast('error');
		}
		finally{
			this.hideLoading();
		}
	}

	async init(){
		await super.init();
		if(this.prop.impref){
			this.injectors.push(this.prop.impref.attributes.prop.default);
		}
		if(!this.provider && this.prop && this.db && this.prop.impref){
			this.provider = this.db.getLoader(this.prop.type.attributes.__valuetype__.default.qualname);
		}
		console.log('PROP',this.prop);
		for(let mod of this.prop.mods){
			if(mod.name.startsWith('impref')){
				let ok = true;
				let refprop = mod.attributes.__Tparams__.default[1];
				for(let i of mod.attributes.__Tparams__.default[0].construct_params){
					if(i.name == refprop){
						ok = false;
						break;
					}
				}
				if(!ok) continue;
				if(mod.attributes.__Tparams__.default[0].attributes[refprop] && !mod.attributes.__Tparams__.default[0].attributes[refprop].isreadonly){
					continue;
				}
				this.isreadonly = true;
				break;
			}
		}
	}
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type('wackytype.structure.KnownDict'))
@Component({
	selector: 'knowndict-property-editor',
	template:`
		<ng-container *ngIf="formgroup">
			<ion-list [formGroup]="formgroup">
				<ion-list-header>
					<ion-label color="primary">{{prop.name | translate}} ({{Object.getOwnPropertyNames(_value).length}})</ion-label>
				</ion-list-header>
				<ion-item *ngFor="let prop of props">
					<dyncomp #comps [component]="prop.component" formControlName="{{prop.name}}" [config]="{db:db,prop:prop.type,readonly:readonly,value:prop.value}"></dyncomp>
				</ion-item>
			</ion-list>
			<ion-item *ngIf="Object.getOwnPropertyNames(_value).length == 0">
				<ion-label style="color:#ddd;">{{'no_entries'|translate}}</ion-label>
			</ion-item>
		</ng-container>
	`,
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => KnownDictPropertyEditorComponent),
	    }
	],
})
export class KnownDictPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value;
	protected formgroup : FormGroup;
	protected props : Array<{component:Type<any>,type:PropertyMeta|ParameterMeta,name:string}>;
	protected subscriptions : Subscription[];

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		if(!value || this.typeMeta.instanceOf(value)){
			this._value = value
		}
		else{
			this._value = new this.typeMeta.codeObject();
			for(let i in value) this._value[i] = value[i];
			if(this.formgroup) this.formgroup.patchValue(value);
		}
		if(emit){
			this.onChange.emit(value);
		}
	}

	protected async init(){
		await super.init();
		let controls = {};
		if(!this._value) this._value = new this.typeMeta.codeObject();
		this.props = this.typeMeta.attributes.__attrtypes__.default.map(i=>{
			if(!(i.name in this._value)) this._value[i.name] = i.default;
			controls[i.name] = new FormControl(this._value[i.name]);
			return {
				component: ObjectPropertyEditorComponent.getComponent(this.typeMeta,i).component,
				type: i,
				name: i.name,
				value: this._value[i.name]
			};
		});
		this.formgroup = new FormGroup(controls);
		this.subscriptions = [this.formgroup.valueChanges.subscribe(d=>{
			let c = 0;
			for(let i in d) if(this.formgroup.get(i).dirty) {this._value[i] = d[i]; c++};
			this.formgroup.markAsUntouched();
			if(c>0){
				this.onTouched.emit();
				this.onChange.emit(this._value);
			}
			this.formgroup.markAsUntouched();
		})];
	}

	ionViewWillLeave(){
		for(let sub of this.subscriptions) sub.unsubscribe();
		this.subscriptions = [];
	}
	
}


@ObjectPropertyEditor(0.25,PropertySelectors.Type('wackytype.structure.UniformDict'))
@Component({
	selector: 'uniformdict-property-editor',
	template:`
		<ng-container *ngIf="formgroup">
			<ion-list [formGroup]="formgroup">
				<ion-list-header>
					<ion-label color="primary">{{prop.name | translate}} ({{Object.getOwnPropertyNames(_value).length}})</ion-label>
				</ion-list-header>
				<ion-item *ngFor="let prop of _value | keyvalue">
					<dyncomp #comps [component]="_component" formControlName="{{prop.key}}" [config]="{db:db,prop:_type,readonly:readonly,value:prop.value}"></dyncomp>
				</ion-item>
				<ion-item *ngIf="Object.getOwnPropertyNames(_value).length == 0">
					<ion-label style="color:#ddd;">{{'no_entries'|translate}}</ion-label>
				</ion-item>
			</ion-list>
		</ng-container>
	`,
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => UniformDictPropertyEditorComponent),
	    }
	],
})
export class UniformDictPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected Object = Object;
	protected _value;
	protected _component;
	protected _type;
	protected formgroup : FormGroup;
	protected props : Array<{component:Type<any>,type:PropertyMeta|ParameterMeta,name:string}>;
	protected subscriptions : Subscription[];

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		if(!value || this.typeMeta.instanceOf(value)){
			this._value = value
		}
		else{
			this._value = new this.typeMeta.codeObject();
			for(let i in value) this._value[i] = value[i];
			if(this.formgroup) this.formgroup.patchValue(value);
		}
		if(emit){
			this.onChange.emit(value);
		}
	}

	protected async init(){
		await super.init();
		let controls = {};
		if(!this._value) this._value = new this.typeMeta.codeObject();
		this._value = {};
		this._type = this.typeMeta.attributes.__valuetype__.default;
		this._component = ObjectPropertyEditorComponent.getComponent(this.typeMeta,new PropertyMeta('adsasd',this._type));
		this.formgroup = new FormGroup(controls);
		this.subscriptions = [this.formgroup.valueChanges.subscribe(d=>{
			let c = 0;
			for(let i in d) if(this.formgroup.get(i).dirty) {this._value[i] = d[i]; c++};
			this.formgroup.markAsUntouched();
			if(c>0){
				this.onTouched.emit();
				this.onChange.emit(this._value);
			}
			this.formgroup.markAsUntouched();
		})];
	}

	ionViewWillLeave(){
		for(let sub of this.subscriptions) sub.unsubscribe();
		this.subscriptions = [];
	}
	
}


@ObjectPropertyEditor(PropertySelectors.Type('datetime'))
@Component({
	selector: 'base-object-property-editor',
	template:`
		<ion-label position="stacked" color="primary">{{prop.name|translate}}</ion-label>
		<ion-datetime-button (click)="triggerPopover($event)" datetime="{{idCounter}}{{prop.name}}">
			<span *ngIf="!_value" slot="date-target">&nbsp;</span>
			<span *ngIf="!_value" slot="time-target">&nbsp;</span>
		</ion-datetime-button>
		<ion-popover [isOpen]="popoverState" (willDismiss)="triggerPopover($event)" [keepContentsMounted]="true">
		  <ng-template>
			<ion-datetime id="{{idCounter}}{{prop.name}}" presentWith="" displayFormat="DD.MM.YYYY MM.HH" [readonly]="readonly" type="datetime" [(ngModel)]="_isoStr" (ionChange)="$event.preventDefault();$event.stopPropagation();valueChanged();"></ion-datetime>
		  </ng-template>
		</ion-popover>
	`,
	//<ion-input #input (change)="$event.preventDefault();$event.stopPropagation();" [value]="displayValue" (click)="$event.preventDefault();$event.stopPropagation();onOpenIntend.emit({target:this,type:prop.type,config:{value:value}})" [readonly]="true" (ionChange)="$event.preventDefault();$event.stopPropagation();writeValue($event.detail.value)"></ion-input>
	//styleUrls: ['./object-base-editor.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => DatetimeObjectPropertyEditorComponent),
	    }
	],
})
export class DatetimeObjectPropertyEditorComponent extends ObjectPropertyEditorComponent{

	protected _value : Date = null;
	protected Object = Object;
	protected static idCounter = 0;
	protected idCounter : number;
	protected popoverState = false;
	protected _isoStr : string;

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver
		);
		DatetimeObjectPropertyEditorComponent.idCounter++;
		this.idCounter = DatetimeObjectPropertyEditorComponent.idCounter;
	}

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		try{
			this._value = value
			console.log('DATETIME value',value,this.prop.type.codeObject);
			if(value && !this.prop.type.instanceOf(value))
				this._value = new this.prop.type.codeObject(value);
			console.log(this._value);
			if(this._value){
				this._isoStr = this._value.toISOString();
			}
			if(emit){
				this.onTouched.emit();
				this.onChange.emit(value);
			}
		}
		catch(e){
			console.error('salkdzsaidisazd',e);
		}
	}

	protected openSubeditor(){
		this.onOpenIntend.emit({cb:value=>this.writeValue(value),type:this.prop.type,config:{value:this._value}});
	}

	protected triggerPopover(e : Event){
		this.popoverState = !this.popoverState;
	}

	protected valueChanged(){
		this._value = new this.prop.type.codeObject(this._isoStr);
		this.onTouched.emit();
		this.onChange.emit(this._value);
	}
	
}

@ObjectPropertyEditor(0.25,PropertySelectors.Type(ObjectMeta,objectMeta,Object,'object'))
@Component({
	selector: 'base-object-property-editor',
	template:`
		<ion-label position="stacked" color="primary">{{type.mapped_qualname+'.'+prop.name|translate}}</ion-label>
		<div><object-visualizer tabindex="-1" *mcRerender="trigger" [db]="db" [type]="_value?ClassMeta.getMeta(_value):propertyMeta.type" style="display:block;width:100%;" [value]="_value" (click)="$event.preventDefault();$event.stopPropagation();openSubeditor();"></object-visualizer>
		<ion-icon color="primary" name="close-circle-outline" *ngIf="prop.optional && _value && !prop.isreadonly" (click)="_value = null;onChange.emit(null);"></ion-icon></div>
	`,
	styles:[`
			:host{
				align-self: start;
			}

			div{
				display: flex;
				flex:1;
				margin-top:9px;
				margin-bottom:7px;
				align-items: center;
			}
	`],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ObjectPropertyBaseEditorComponent),
	    }
	],
})
export class ObjectPropertyBaseEditorComponent extends ObjectPropertyEditorComponent{

	@ViewChild('input',{static:true}) input : ElementRef<HTMLInputElement>;
	protected _value;
	protected Object = Object;
	protected ClassMeta = ClassMeta;
	protected trigger = 1;
	protected injectors : Array<string> = [];

	readValue(){
		return this._value;
	}

	writeValue(value){
		let emit = this._value != value;
		if(value && ClassMeta.getMeta(value) != this.propertyMeta.type) this.trigger++;
		this._value = value;
		if(emit){
			this.onTouched.emit();
			this.onChange.emit(value);
		}
	}

	protected openSubeditor(){
		if(this.readonly) return;
		this.onOpenIntend.emit({
			cb:this.writeValue.bind(this),
			type:this.prop.type,
			config:{
				value:this.value,
				injectors:this.injectors,
				//filter:this.prop.
			}
		});
	}

	async init(){
		await super.init();
		if(this.prop.impref){
			this.injectors.push(this.prop.impref.attributes.prop.default);
		}
	}
}