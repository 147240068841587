import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, ComponentFactoryResolver,forwardRef,Type, Directive, QueryList, ViewChild,ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, ControlValueAccessor,NG_VALUE_ACCESSOR,ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
	ClassMeta,
	ObjectMeta,
	objectMeta,
	listMeta,
	ListMeta,
	dictMeta,
	setMeta,
	ExpandDesc,
	IntMeta,FloatMeta,floatMeta,intMeta,boolMeta,StrMeta,strMeta,
	mergeDeep,
	PropertyMeta,
} from 'dissys';
import { ObjectVisualizer, ObjectVisualizerComponent,VisualizerSelectors } from './object-visualizer.component';
import { DynComponentLoaderComponent } from './common';
import { Loader } from 'dissys';
import { TypeConfig, PropRules } from './type-config';

@ObjectVisualizer(VisualizerSelectors.Type(ListMeta))
@Component({
	selector: 'object-table',
	templateUrl:'./object-table.component.html',
	styleUrls: ['./object-table.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ObjectTableComponent),
	    }
	],
})
export class ObjectTableComponent extends ObjectVisualizerComponent implements AfterViewInit{

	protected propertyComponents : {[key:string]:Type<any>} = {};
	protected _value = [];
	protected subscriptions : Subscription[] = [];
	protected _filter = null;
	protected _extraCols = null;
	protected viscol = [];
	protected expand : ExpandDesc;
	protected _hiddenCols : Array<string> = [];
	protected ClassMeta = ClassMeta;
	protected canDel = true;
	protected pageSize = 20;
	protected searcols : string[];
	protected currentPage = 1;
	protected count = 0;
	protected pageFrame : [number,number] = [0,this.pageSize];

	//@ViewChildren('comps') protected comps :  QueryList<ObjectVisualizerComponent> 

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}

	async ngAfterViewInit(){
		this.expand = {};
		this.searcols = [];
		await this.init();
		let tconfig = TypeConfig.get(this.typeMeta);
		for(let prop in this.typeMeta.attributes){
			let v = 0;
			if(tconfig.props) for(let pv of tconfig.props)
				v = pv(this.typeMeta.attributes[prop],v);
			if(
				(v & PropRules.Hide) == PropRules.Hide ||
				(v & PropRules.Secondary) == PropRules.Secondary
			) this.hiddenCols.push(prop);
		}

		for(let i in this.typeMeta.attributes){
			let v = this.typeMeta.attributes[i];
			if(
				!v.isstatic && !v.isprivate &&
				this.hiddenCols.indexOf(v.name) < 0 &&
				!v.type.isSubtypeOf('abc.Emitter','dissys.Emitter') &&
				(!v.type.isSubtypeOf(listMeta) && !v.type.isSubtypeOf(setMeta) && !v.type.isSubtypeOf(dictMeta) || (TypeConfig.getAttr(this.typeMeta,v)&PropRules.Primary) == PropRules.Primary)
			){
				if(!v.type.isSubtypeOf(IntMeta,FloatMeta,floatMeta,intMeta,boolMeta,StrMeta,strMeta))
					this.expand[v.name] = {};
				this.searcols.push(v.name);
				this.viscol.push(v);
			}
		}
		if(this.extraCols){
			for(let i of this.extraCols){
				let extraExpand = {};
				let cur = extraExpand;
				let type = this.typeMeta;
				this.searcols.push(i);
				for(let j of i.split('.')){
					cur = cur[j] = {};
					type = type.attributes[j].type;
				}
				mergeDeep(this.expand,extraExpand);
				this.viscol.push(new PropertyMeta(i,type));
			}
		}
		/*this.comps.changes.subscribe(d=>{
			for(let i of this.subscriptions) i.unsubscribe();
			this.subscriptions = [];
			for(let i of this.comps){
				i.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
			}
		});
		for(let i of this.comps){
			i.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
		}*/
		this.expand = this.typeMeta.calculateExpand(this.expand);
		await this.reloadData();
	}

	async ionViewWillEnter(){
		await this.reloadData();
	}

	async reloadData(){
		if(this.provider instanceof Array){
			this.value = this.provider;
		}
		else if(this.provider instanceof Loader){
			let c = this.count = await this.provider.count(this._filter);
			let v = await this.provider.select(this.pageFrame,this._filter,{'*':this.expand});
			this.value = v;
		}
	}

	@Input()
	set hiddenCols(cols : Array<string>){
		this._hiddenCols = cols;
	}

	get hiddenCols(){
		return this._hiddenCols;
	}

	@Input()
	set filter(value){
		this._filter = value;
	}

	get filter(){
		return this._filter;
	}

	@Input()
	set extraCols(value){
		this._extraCols = value;
	}

	get extraCols(){
		return this._extraCols;
	}

	getMeta(obj){
		try{
			return ClassMeta.getMeta(obj);
		}
		catch(e){}
		return objectMeta;
	}

	async gotoPage(page : number){
		if(this.currentPage == page) return;
		this.pageFrame = [(page-1)*this.pageSize,page*this.pageSize];
		this.currentPage = page;
		this.showLoading();
		try{
			await this.reloadData();
		}
		finally{
			this.hideLoading();
		}
	}

	/*
		Helper function for Angular template for loops to prevent scrollbar from jumping after reload and optimized rerender times
	*/
	protected itemID(index : number, item :any){
		return item.id;
	}

	protected showEntry(entry){
		this.onOpenIntend.emit(<any>{value:entry});
	}

	protected async deleteObject(o){
		if(this.provider instanceof Array){
			let i = this.provider.indexOf(o);
			this.provider.splice(i,1);
		}
		else if(this.provider instanceof Loader){
			this.showLoading();
			try{
				await this.provider.delete(o);
				let i = this.value.indexOf(o);
				this.value.splice(i,1);
			}
			catch(e){
				this.presentToast('error');
			}
			finally{
				this.hideLoading();
			}
		}
	}

	async search(value : string){
		this.filter = this.typeMeta.calcFilter(value,this.searcols);
		this.pageFrame = [0,this.pageSize];
		this.showLoading();
		try{
			await this.reloadData();
		}
		finally{
			this.hideLoading();
		}
	}
}


@Component({
	selector: 'object-table-page',
	templateUrl:'./object-table.component.html',
	styleUrls: ['./object-table.component.scss'],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => ObjectTablePage),
	    }
	],
})
export class ObjectTablePage extends ObjectVisualizerComponent implements AfterViewInit{

	protected propertyComponents : {[key:string]:Type<any>} = {};
	protected _value = [];
	protected subscriptions : Subscription[] = [];
	protected _filter = null;
	protected _extraCols = null;
	protected viscol = [];

	//@ViewChildren('comps') protected comps :  QueryList<ObjectVisualizerComponent> 

	readValue(){
		return this._value;
	}

	writeValue(value){
		this._value = value;
	}

	async ngAfterViewInit(){
		this.viscol = [];
		for(let i in this.typeMeta.attributes){
			this.viscol.push(this.typeMeta.attributes[i]);
		}
		if(this.extraCols) this.viscol.push(...this.extraCols);
		/*this.comps.changes.subscribe(d=>{
			for(let i of this.subscriptions) i.unsubscribe();
			this.subscriptions = [];
			for(let i of this.comps){
				i.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
			}
		});
		for(let i of this.comps){
			i.onOpenIntend.subscribe((d)=>this.onOpenIntend.emit(d))
		}*/
		//if(this.provider instanceof Array){
		//	this.value = this.provider;
		//}
		//else if(this.provider instanceof Loader){
		//	let v = await this.provider.select(null,this._filter,this.expand);
		//	this.value = v;
		//}
	}

	@Input()
	set filter(value){
		this._filter = value;
	}

	get filter(){
		return this._filter;
	}

	@Input()
	set extraCols(value){
		this._extraCols = value;
	}

	get extraCols(){
		return this._extraCols;
	}
	
}
