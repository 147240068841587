import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams } from '@ionic/angular';

import { LanguageModule } from '../language/module';

import { Modal } from './modal';
import { Page } from './page';
export * from './modal';
export * from './page';
export * from './guard';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		LanguageModule,
	],
	declarations: [
		Modal,
		Page,
	],
	entryComponents:[
	],
	providers:[
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [
	]
})
export class Module {}