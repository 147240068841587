import { Injectable,EventEmitter, Optional, Inject } from '@angular/core';
import { HTTPService } from './http';

		

declare var window;

window['$'] = function(...args){
	return null;
}

export class NoJQPromise{
	constructor(protected prom : Promise<any>){

	}

	done(...args : any[]){
		return new NoJQPromise(this.prom.then(...args));
	}

	fail(...args : any[]){
		return new NoJQPromise(this.prom.catch(...args));
	}
}

@Injectable({providedIn:'root'})
export class NoJQ{

	public readonly events : {[key:string]:EventEmitter<any>} = {};

	constructor(
		protected http : HTTPService
	){
		//bind caretaker events
		window['$'] = (...args)=>this;
		let proto = Object.getPrototypeOf(this);
		//proto = this;
		console.log('proto',proto);
		for(let i of Reflect.ownKeys(proto)){
			console.log(i);
			if(typeof proto[i] === 'function' || proto[i] instanceof Function)
				window['$'][i] = (<any>proto[i]).bind(this);
		}
	}

	on(event : string, func : (...args)=>any) : this{
		if(!(event in this.events)) this.events[event] = new EventEmitter();
		this.events[event].subscribe((args:any[])=>func(...args));
		return this;
	}

	hide(...args) : this{
		return this;
	}

	trigger(event : string, ... args : any[]) : this{
		if(event in this.events){
			this.events[event].emit(args);
		}
		return this;
	}

	post(url : string, body : object){
		return new NoJQPromise(this.http.post(url,body).then(r=>r.body));
	}
}


