import { Component, OnInit, ViewChild, AfterViewInit, Inject, Optional, ElementRef, Renderer2 } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { LoadingController, ToastController, IonInput, NavParams } from '@ionic/angular';
import { Subscription } from 'dissys';

import { APIService } from '../api/module';
import { LanguageService } from '../language/module';
import { BaseComponent } from '../object-editor/module';

import { InstanceSelector } from '../instance-selector/module';

import { MenuController,ModalController } from '@ionic/angular';

@Component({
	selector: 'auth-page',
	templateUrl: './page.html',
	styleUrls: ['./page.scss'],
})
export class Page extends BaseComponent /*implements AfterViewInit*/ {

	//public translate : TranslateService;

	public redirectPage : string;
	protected authSub : Subscription = null;
	@ViewChild('username',{static:true}) usernameInput : IonInput;
	@ViewChild('password',{static:true}) passwordInput : IonInput;

	constructor(
		activatedRoute: ActivatedRoute,
		translate: LanguageService,
		modalController : ModalController,
		loadingctrl : LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		public api : APIService,
		protected isel : InstanceSelector,
		@Inject('OMCloud.allow_disconnect') @Optional() protected allowDisconnect ?: boolean,
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
		);
		this.allowDisconnect = typeof allowDisconnect !== 'boolean' ? true : this.allowDisconnect;
		//this.translate = tc.translate;
		activatedRoute.queryParams.subscribe(params => {
			if(params["redirect"])
				this.redirectPage = atob(params["redirect"]);
		});
	}


	/*
	ngAfterViewInit() {
		//this.folder = this.activatedRoute.snapshot.paramMap.get('id');
		this.api.ready.then(()=>{
			//let id = this.api.identity;
			//if(id){
			//  this.usernameInput.value = id.data.name;
			//console.log('ng after login page init',this.passwordInput  )
			//  this.passwordInput.setFocus(); //TODO: does not set focus
			//}
			//else
			//  this.usernameInput.setFocus(); //TODO: does not set focus
		});
	}*/

	ionViewWillEnter(){
		this.mctrl.enable(false, 'main-navgation-menu');
		this.authSub = this.api.auth.onAuth.subscribe(auth=>{
		if(this.redirectPage)
			this.router.navigateByUrl(this.redirectPage,{ replaceUrl: true });
		else
			this.router.navigate(['/'],{ replaceUrl: true });
		});
	}

	ionViewWillLeave(){
		if(this.authSub){
			this.authSub.unsubscribe();
			this.authSub = null;
		}
	}

	login(cred){
		this.showLoading();
		this.api.auth.authorizeByCredentials(cred.form.value.username,cred.form.value.password)
		.then(d=>{
			this.passwordInput.value = '';
			this.hideLoading();
			
		}).catch(e=>{
			this.hideLoading();
			if(e.error && e.error.error){
				console.error(e.error.error.msg);
				this.presentToast(`msg_error_${e.error.error.tag}`);
			}
			else{
				console.log(e);
				this.passwordInput.value = '';
				this.presentToast('msg_error_bad_auth_cred');
			}
		});
	}

	async presentToast(tag : string) {
		let txt = await this.translate.get(tag);
		const toast = await this.toastCtrl.create({
			message: txt,
			duration: 2000
		});
		toast.present();
	}

	disconnectCloud(){
		this.api.auth.logout();
		this.isel.clear();
		this.router.navigateByUrl('/select-instance');
	}
}
