import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy,ToastController,NavParams,Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { AppRoutingModule } from './app-routing.module';
import { PreloadingPage,LoadIndicatorComponent,LoadController,NotFoundPage } from './common';
import { AppComponent } from './app.component';

import { HTTP } from '@ionic-native/http/ngx';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Storage,IonicStorageModule } from '@ionic/storage-angular';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { NgxRerenderModule } from 'ngx-rerender';

import { LanguageModule } from './language/module';
import { Module as AuthModule } from './auth/module';
import { HTTPService } from './http';
import { NoJQ } from './nojq';
import { APIService, APISocketService } from './api/api.service';

import { Module as InspectorModule } from './inspector/module';
import { Module as ObjectEditorModule } from './object-editor/module';
import { Module as InstanceSelectorModule } from './instance-selector/module';
import { NavigationMenuModule } from './navigation-menu/module';
import { TestComponent,TestPage, MyContainerDirective } from './test.component';
//import { StartPage,RedirectComponent } from './start.page';

@NgModule({
	declarations: [
		AppComponent,
		TestComponent,
		TestPage,
		MyContainerDirective,
		PreloadingPage,
		LoadIndicatorComponent,
		NotFoundPage,
		//StartPage,
		//RedirectComponent,
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		IonicStorageModule.forRoot(),
		ObjectEditorModule,
		LanguageModule.forRoot(),
		AuthModule,
		InspectorModule,
		NavigationMenuModule,
		InstanceSelectorModule,
		NgxRerenderModule,
	],
	providers: [
		AppVersion,
		Storage,
		StatusBar,
		SplashScreen,
		{provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
		ToastController,
		NavParams,
		AppRoutingModule,
		{provide:'googleApiKey',useValue:'AIzaSyDQDILC5U7timnFIH9P3Su9CJ7grTd7lUA'},
		{provide:'AppVersion',useValue:'2.1.28'},
		//{provide:'LoadIndicatorComponent',useValue:PreloadingPage},
		//{provide:'InstancePort',useValue:'8080'},
		//{provide:'InstanceProtocol',useValue:'http:'},
		//{provide:'InstanceHost',useValue:'192.168.179.17'},
		//{provide:'InstanceID',useValue:''},
		//{provide:'InstanceID',useValue:'sikotec'},
		//{provide:'InstanceHost',useValue:'localhost'},
		{provide:'InstancePort',useValue:'443'},
		{provide:'InstanceProtocol',useValue:'https:'},
		//{provide:'InstanceHost',useValue:'usl-gmbh.de'},
		//{provide:'InstanceHost',useValue:'mbt-rfid.de'},
		//{provide:'InstanceHost',useValue:'myrapport.de'},
		HTTP,
		HttpClient,
		HTTPService,
		NoJQ,
		//{ provide: APIService, useFactory:(http:HTTPService)=>new APIService('http://localhost:8080/',http)},
		APIService,
		APISocketService,
		LoadController,
	],
	bootstrap: [AppComponent]
})
export class AppModule {}