import { Injectable,Inject,Optional } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { MenuController } from '@ionic/angular';

import { EventEmitter } from 'dissys';

import { HTTPService } from '../http';
import { Guard as AuthGuard } from '../auth/guard';

export type InstanceInfo = {
	modules: {name:string,icon:string,hidden?:boolean}[];
	name: string;
	dns_name: string;
};

@Injectable({providedIn: 'root'})
export class InstanceSelector{

	public readonly onChange = new EventEmitter<[string,InstanceInfo]>();
	public readonly onChanged = new EventEmitter<[string,InstanceInfo]>();

	protected loadedInstanceURI : string;
	protected isPreDefined = false;
	protected predefInstanceID : string;
	protected predefPort : string;
	protected predefProto : string;
	protected predefHost : string;
	
	private initPromRes;
	private _inited = false;
	private readonly initProm : Promise<void> = new Promise(res=>{this.initPromRes=res});

	get inited(){return this.initProm};

	constructor(
		protected storage : Storage
		,protected http : HTTPService
		,protected router : Router
		,@Optional() @Inject('InstanceStorageName') protected storageName : string
		,@Optional() @Inject('InstanceSelectPageRoute') protected selectPageRoute : string
		,@Optional() @Inject('InstanceHost') protected instanceHost : string
		//,@Optional() @Inject('InstanceAllowedHost') protected allowedHosts : string[]
		,@Optional() @Inject('InstancePort') protected instancePort : string
		,@Optional() @Inject('InstanceID') protected instanceID : string
		,@Optional() @Inject('InstanceProtocol') protected protocol ?: string

	){
		this.predefPort = instancePort;
		this.predefProto = protocol;
		this.predefHost = instanceHost;
		this.predefInstanceID = instanceID;
		!storageName && (this.storageName = 'selectedOMCloudInstanceName');
		!selectPageRoute && (this.selectPageRoute = 'select-instance');
	}

	async loadInstanceData(name : string){
		return (await this.http.request('GET',`${this.getProtocol()}//${name}.${this.instanceHost}:${this.instancePort}`,undefined,{'Accept':'application/json'},null,'json')).body as InstanceInfo;
	}

	async loadInstanceDataByURL(url : string){
		return (await this.http.request('GET',url,undefined,{'Accept':'application/json'},null,'json')).body as InstanceInfo;
	}

	async init(){
		if(this._inited !== false) return this.initProm;
		this._inited = null;
		try{
			let d = await this.storage.get(this.storageName);
			if(d){
				try{
					let idata = await this.loadInstanceDataByURL(d);
					this.select(d,idata);
					return;
				}
				catch(e){
					console.error(`could not select previous selected instance ${d}`);
					console.error(e);
				}
			}
			if(!this.protocol){
				if(this.predefProto)
					this.protocol = this.predefProto
				else if(window.location.protocol == 'ionic:'){
					this.protocol = 'https:';
				}
				else{
					this.protocol = window.location.protocol;
				}
			}

			let domainsplit = window.location.hostname.split('.');
			if(!this.instanceHost){
				let hostmainparts = [];
				for(let i=Math.min(domainsplit.length,2);i>0;--i){
					hostmainparts.push(domainsplit[domainsplit.length-i]);
				}
				this.instanceHost = hostmainparts.join('.');
			}

			if(!this.instancePort){
				if(this.predefPort)
					this.instancePort = this.predefPort
				else if(!window.location.port){
					if(this.protocol == 'https:')
						this.instancePort = '443'
					else
						this.instancePort = '80'
				}
				else
					this.instancePort = window.location.port;
			}

			if(this.instanceID === null || this.instanceID === undefined){
				if(this.predefInstanceID)
					this.instanceID = this.predefInstanceID;
				else if(domainsplit.length > 2){
					let instanceidparts = [];
					for(let i=0;i<domainsplit.length-2;++i){
						instanceidparts.push(domainsplit[i]);
					}
					this.instanceID = instanceidparts.join('.');
				}
				else{
					this.instanceID = this.instanceHost == 'localhost' ? '' : undefined;
				}
			}
			
			if(!this.loadedInstanceURI && this.hasURI()){
				let url = this.getURI();
				try{
					this.isPreDefined = true;
					await this.select(url);
				}
				catch(e){
					console.error('could not connect to instance');
					console.error(e);
					this.instanceID = undefined;				
				}
			}
			this._inited = true;
			this.initPromRes();
		}
		catch(e){
			this._inited = false;
			throw e;
		}
	}

	hasURI() : boolean{
		return this.loadedInstanceURI !== undefined || (this.instanceHost && this.instancePort && this.protocol && this.instanceID !== undefined);
	}

	getURI() : string{
		return this.loadedInstanceURI ? this.loadedInstanceURI : `${this.protocol}//${this.instanceID ? this.instanceID + '.' : ''}${this.instanceHost}:${this.instancePort}`;
	}

	getSelectPageRoute() : string{
		return this.selectPageRoute;
	}

	getProtocol() : string{
		return this.protocol;
	}

	getHost() : string{
		return this.instanceHost;
	}

	async select(url : string, info ?: InstanceInfo){
		await this.onChange.emit([url,info]);
		this.loadedInstanceURI = url;
		if(!this.isPreDefined) this.storage.set(this.storageName,this.loadedInstanceURI);
		await this.onChanged.emit([url,info]);
	}

	clear(){
		this.instanceHost = null;
		this.instancePort = null;
		this.instanceID = null;
		this.protocol = null;
		this.storage.set(this.storageName,undefined);
		this.init();
	}
}
