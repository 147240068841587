import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, Renderer2, ViewChildren, ComponentFactoryResolver,forwardRef,Type, Directive, ComponentRef, ViewChild, ElementRef, TemplateRef, ViewContainerRef, TypeDecorator } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController, MenuController } from '@ionic/angular'
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClassMeta, ObjectMeta, objectMeta, Loader, ObjDB, ClassMapperMeta,ExpandDesc } from 'dissys';
import { ObjectVisualizerBaseComponent } from './object-visualizer.component';
import { BaseComponent, Constructor } from './common';
import * as common from './common';
import { LanguageService } from '../language/module';

export type EditorSelector = (meta:ClassMeta)=>boolean;
export const EditorSelectors = {
	Type : function(...types : Array<ClassMeta|string|{new(...args):any}>) : EditorSelector{
		return (meta:ClassMeta)=>types.reduce((a,v)=>a||meta.isSubtypeOf(v),false);
	},
	Or : function(...selectors : EditorSelector[]){
		return (meta:ClassMeta)=>selectors.reduce((a,v)=>a||v(meta),false);
	},
	And : function(...selectors : EditorSelector[]){
		return (meta:ClassMeta)=>selectors.reduce((a,v)=>a&&v(meta),true);
	}
};

type RegistryEntry = {
	component: Type<ObjectEditorComponent>,
	selectors: Array<EditorSelector>
};

class NotFound extends Error{}

@Component({})
export abstract class ObjectEditorBaseComponent<T = any> extends ObjectVisualizerBaseComponent{

	

}

@Component({})
export abstract class ObjectEditorComponent<T = any> extends ObjectEditorBaseComponent implements ControlValueAccessor{

	private static componentRegistry : RegistryEntry[] = [];
	private static configRegistry : {config:{[key:string]:any},selectors:EditorSelector[]}[] = [];

	static registerComponent(ctor, ...selectors : EditorSelector[]){
		ObjectEditorComponent.componentRegistry.push({
			component : ctor,
			selectors : selectors,
		});
	}

	static getComponent(meta:ClassMeta){
		for(let entry of ObjectEditorComponent.componentRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta),true)) return entry.component;
		}
		console.error('meta:');
		console.error(meta);
		throw new NotFound(`no component found for ${meta.qualname}`);
	}

	static getConfig(meta:ClassMeta){
		for(let entry of ObjectEditorComponent.configRegistry){
			if(entry.selectors.reduce((a,v)=>a&&v(meta),true)) return entry.config;
		}
		throw new NotFound(`no component found for ${meta.qualname}`);
	}

	static registerConfig(config : {[key:string]:any}, ...selectors : EditorSelector[]){
		ObjectEditorComponent.configRegistry.push({
			config : config,
			selectors : selectors,
		});
	}

	protected createTypeInstanceFromInput(){
		let value = this.value;
		//this.mode = 'edit';
		return new this.typeMeta.codeObject(this.value)
	}

}

export function ObjectEditor(...selectors : EditorSelector[]){
	return <T>(ctor : T)=>{
		ObjectEditorComponent.registerComponent(ctor,...selectors);
		return ctor
	};
} 

@Component({
	selector:'object-editor',
	template:'<ng-container #compslot></ng-container>',
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectEditorComponentLoader),
		}
	],
})
export class ObjectEditorComponentLoader<T = any> extends ObjectEditorComponent{

	protected cfg;
	protected componentInstance : ComponentRef<ObjectEditorComponent> = null;
	protected _value;
	protected __overrides__;

	@ViewChild('compslot', {static: true, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;

	@Input()
	set config(c){
		this.cfg = c;
	}

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		componentFactoryResolver: ComponentFactoryResolver
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
			componentFactoryResolver
		);
	}

	protected async init(){
		await super.init();
		this.loadPlugins();
	}

	reset(){
		this.loadPlugins();
	}

	writeValue(value){
		this._value = value;
		if(this.componentInstance) this.componentInstance.instance.writeValue(value);
	}

	readValue(){
		return this.componentInstance ? this.componentInstance.instance.value : this._value;
	}

	get db(){
		return this.componentInstance ? this.componentInstance.instance.db : this._db;
	}

	set db(value){
		this._db = value;
		if(this.componentInstance)
			this.componentInstance.instance.db = value;
	}

	protected loadPlugins() {
		let comp : Type<ObjectEditorComponent>;
		if(this.typeMeta){
			comp = ObjectEditorComponent.getComponent(this.typeMeta);
		}		
		if(comp && this.pluginHost){
			if(this.componentInstance){
				for(let i in this.__overrides__) try{delete this[i];}catch(e){}
				this.componentInstance.destroy();
			}
			const componentRef = this.loadComponent(this.pluginHost,comp);
			this.componentInstance = componentRef;
			//componentRef.instance.data = adItem.data;
			this.componentInstance.instance.onOpenIntend.subscribe((d)=>{
				/*let subComp : Type<any>;
				if(!('component' in d)){
					if(d.type instanceof ClassMeta){
						subComp = ObjectEditorComponent.getByMeta(d.type);
					}
					else{
						subComp = ObjectEditorComponent.getByType(d.type);
					}
				}
				else subComp = d.component;*/
				this.showModal(
					ObjectEditorModalComponentLoader,
					false,
					(val)=>{
						if(val && val.data !== undefined){
							d.cb(val.data);
						}
					},
					{type:d.type,db:this.db,...(d.config||{})}
				);
			});
			let d = {};



			(<any>this.componentInstance.instance).onChange = this.onChange;
			(<any>this.componentInstance.instance).onTouched = this.onTouched;
			this.componentInstance.instance.setOptions({
				db:this._db,
				value:this._value,
				type:this.typeMeta,
				...this.cfg
			})
			
			/*for(let i of Object.getOwnPropertyNames(componentRef.instance)){
				//console.log('define',i)
				if(i.substring(0,2) == '__' || i in d) continue;
				try{
					Object.defineProperty(this,i,{
						get:()=>{
							console.log('getter override',this,i,componentRef.instance[i]);
							if(typeof componentRef.instance[i] == 'function' || componentRef instanceof Function){
								return (...args)=>{
									return componentRef.instance[i](...args)
								}
							}
							else
								return componentRef.instance[i];
						},
						set:(value)=>{componentRef.instance[i] = value; this['_'+name] = componentRef.instance[i];},
					});
					d[i] = true;
					console.debug(`patched attribute ${i}`);
				}
				catch(e){
					console.error(`failed patching ${i}`);
				}
			}
			let stack = [componentRef.instance.constructor];
			while(stack.length > 0){
				let j = stack.shift();
				console.log('proto',j)
				//if(j.constructor && j.constructor != Object) stack.push(j.constructor);
				if(Object.getPrototypeOf(j)) stack.push(Object.getPrototypeOf(j));
				if(j.prototype) for(let i of Object.getOwnPropertyNames(j.prototype)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__'|| i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{
								console.log('getter override base',this,i,componentRef);
								if(typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function){
									return (...args)=>{
										return componentRef.instance[i](...args)
									}
								}
								else
									return componentRef.instance[i];
							},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}
				for(let i of Object.getOwnPropertyNames(j)){
					//console.log('base define',i)
					if(i.substring(0,2) == '__' || i in d) continue;
					try{
						Object.defineProperty(this,i,{
							get:()=>{console.log('getter override',this,i,componentRef);return typeof componentRef.instance[i] == 'function' || componentRef.instance[i] instanceof Function ? (...args)=>{return componentRef.instance[i](...args);} : componentRef.instance[i];},
							set:(value)=>{componentRef.instance[i] = value;this['_'+name] = componentRef.instance[i];},
						});
						d[i] = true;
						console.debug(`patched attribute ${i}`);
					}
					catch(e){
						console.error(`failed patching ${i}`);
					}
				}				
			}
			this.__overrides__ = d;
			//if(cfg) for(let i in cfg) componentRef.instance[i] = cfg[i];*/
		}
	}

}

@Component({
	selector:'object-editor-modal',
	templateUrl:'object-editor-modal.component.html',
	styleUrls:[
		'./common.scss',
		'./object-editor-modal.component.scss',
	],
	providers:[
		{ 
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ObjectEditorModalComponentLoader),
		}
	],
})
export class ObjectEditorModalComponentLoader<T = any> extends ObjectEditorComponentLoader<T>{
	@ViewChild('compslot', {static: false, read: ViewContainerRef})
	protected pluginHost: ViewContainerRef;
	protected _dataloaded: Promise<any>;
	protected addNew : boolean = false;
	protected len = 0;
	protected componentProps : any;
	protected allowSelect : boolean = true;
	protected _allowAdd : boolean = true;

	//ionViewWillEnter(){
	//	if(this.db && this.typeMeta) this.provider = this.db.getLoader(this.typeMeta);
	//}

	@Input()
	set allowAdd(b : boolean){
		this._allowAdd = b;
	}

	get allowAdd(){
		return this._allowAdd;
	}

	async init(){
		await (<any>ObjectEditorComponentLoader.prototype).init.call(this);
		//this.subscriptions.push(this.pluginHost.cha);
		if(!this.allowSelect) this.provider = null;
		if(this.provider){
			if(this.provider instanceof Array || this.provider instanceof Set){
				this._dataloaded = new Promise(res=>res(this.provider));
			}
			else{
				this._dataloaded = this.provider.select().then(d=>{
					this.len = d.length;
					this.addNew = d.length==0?true:false;
					setTimeout(()=>this.loadPlugins(),200);return d;
				});
			}
		}
		else{
			this.allowSelect = false;
			this.addNew = true;
			setTimeout(()=>this.loadPlugins(),1000);
		}
	}

	showAddNew(){
		this.addNew = true;
		setTimeout(()=>this.loadPlugins(),1000);
	}
}
