import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RouteReuseStrategy,RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams,MenuController } from '@ionic/angular';

import { NavigationMenuComponent } from './navigation-menu';
import { NavigationSubMenuComponent } from './navigation-sub-menu.component';
import { LanguageModule } from '../language/module';

export * from './common';
export * from './navigation-menu';
export * from './navigation-sub-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    LanguageModule,
  ],
  providers:[
  	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  	MenuController
  ],
  declarations: [NavigationMenuComponent,NavigationSubMenuComponent],
  exports:[NavigationMenuComponent,NavigationSubMenuComponent]
})
export class NavigationMenuModule {}
