import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ModalController,LoadingController,AlertController,ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { APIService } from './api.service';

@Component({
	selector: 'api-module-component',
	templateUrl: './module.component.html',
	//styleUrls: ['./module.component.scss'],
})
export class ModuleComponent {

	protected apiStatus : string = 'unloaded';

	constructor(protected api : APIService){
		//api.ready.then(()=>{
		//	this.apiStatus = 'ready';
		//}).catch(e=>{
		//	this.apiStatus=e[1].toString();
		//	console.log(e);
		//})
	};
}
