import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy,ToastController,NavParams } from '@ionic/angular';
import { Storage,IonicStorageModule } from '@ionic/storage-angular';

import { ModuleComponent } from './module.component';
import { RoutingModule } from './routing.module';
import { APIService } from './api.service';
import { HTTPService } from '../http';

export * from './module.component';
export * from './routing.module';
export * from './api.service';
export * from '../http';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RoutingModule,
		IonicStorageModule.forRoot(),
	],
	declarations: [
		ModuleComponent
	],
	entryComponents:[
		ModuleComponent
	],
	providers:[
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [
		ModuleComponent
	]
})
export class Module {}
