import { HttpClientModule, HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable,EventEmitter, Optional, Inject } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { ClassMeta, ExpandDesc, ObjDB, mergeDeep, PropertyMeta, ClassMapperMeta } from 'dissys';
import { PropRule, Validator, TypeConfig, PropRules } from 'dissys';
export { PropRule, Validator, TypeConfig, PropRules } from 'dissys';

@Injectable({providedIn:'root'})
export class TypeConfigService{
	/*constructor(
		@Optional() protected db ?: ObjDB
	){

	}*/

	get(type : ClassMeta) : TypeConfig{
		const qname = typeof type === 'string' ? type : type.qualname;
		let cl = [];
		let cmap = new Set();
		let stack = [type];
		while(stack.length > 0){
			let i = stack.shift();
			if(cmap.has(i)) continue;
			cl.push(i);
			cmap.add(i);
			for(let b of i.bases) stack.push(b);
		}
		cl = cl.reverse();
		let nc = {};
		for(let i of cl){
			nc = mergeDeep(nc,TypeConfig.get(i));
		}
		return <any>nc;
	}
}
