import { Component, OnInit, ViewChild, AfterViewInit, Inject, Optional, ElementRef, Renderer2 } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { LoadingController,ToastController,IonInput,MenuController,ModalController, NavParams } from '@ionic/angular';

import { BaseComponent } from '../object-editor/module';
import { LanguageService } from '../language/module';
import { Storage } from '@ionic/storage-angular';

import { InstanceSelector } from './service';
import { HTTPService } from '../http';

@Component({
	selector: 'instance-select-page',
	templateUrl: './page.html',
	styleUrls: ['./page.scss'],
})
export class InstanceSelectorPage extends BaseComponent {

	@ViewChild('instanceName',{static:true}) instanceNameInput : IonInput;

	protected redirectPage : string;
	public foundInstanceData;

	constructor(
		activatedRoute: ActivatedRoute,
		translate: LanguageService,
		modalctrl : ModalController,
		loadingctrl : LoadingController,
		toastctrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		navparam : NavParams,
		protected isel : InstanceSelector,
		protected storage : Storage,
		protected http : HTTPService,
	){
		super(
			activatedRoute,
			translate,
			modalctrl,
			loadingctrl,
			toastctrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam
		);
	}

	protected async loadInstance(cred){
		this.showLoading();
		this.foundInstanceData = undefined;
		try{
			let d = await this.isel.loadInstanceData(this.instanceNameInput.value.toString());
			this.foundInstanceData = d;
		}
		catch(e){
			this.presentToast('instance_not_found_error');
		}
		finally{
			this.hideLoading();
		}
	}

	protected selectInstance(e?:Event){
		if(e){
			e.stopPropagation();
			e.preventDefault();
		}
		if(!this.foundInstanceData){
			this.presentToast('no_instance_selected')
			return;
		}
		this.isel.select(`${this.isel.getProtocol()}//${this.instanceNameInput.value}.${this.isel.getHost()}`,this.foundInstanceData);
		if(this.redirectPage)
			this.router.navigateByUrl(this.redirectPage,{ replaceUrl: true });
		else
			this.router.navigate(['/'],{ replaceUrl: true });
	}

	reset(){
		this.foundInstanceData = undefined;
	}
}
