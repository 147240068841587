import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, Renderer2, ComponentFactoryResolver,forwardRef,Type, Directive, ComponentRef, ViewChild, ElementRef, TemplateRef, ViewContainerRef, TypeDecorator } from '@angular/core';
import { ModalController,LoadingController,NavParams,ToastController, MenuController } from '@ionic/angular'
import { ActivatedRoute,Router } from '@angular/router';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClassMeta, PropertyMeta, ObjectMeta, objectMeta, StrMeta, IntMeta, FloatMeta, ListMeta, UniformList, staticPropMeta } from 'dissys';
import { BaseComponent } from './object-editor/module';
import { APIService } from './api/api.service';
const ShortStr = new ClassMeta('Str[1:16]','wackytype.structure',null,[StrMeta],{__max_length__:new PropertyMeta('__max_length__',IntMeta,{'9':staticPropMeta},16)});
import { LanguageService } from './language/module';

@Directive({
  selector: '[myContainer]'
})
export class MyContainerDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
	selector:'test-component',
	template: '<ng-template myContainer></ng-template>'
})
export class TestComponent extends BaseComponent{

	@Input() set myContainer(templateRef: TemplateRef<any>) {
	    this.viewContainerRef.createEmbeddedView(templateRef);
	  }

	constructor(
		activatedRoute: ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl: LoadingController,
		toastCtrl : ToastController,
		router : Router,
		navparam : NavParams,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		protected api : APIService,
		private viewContainerRef: ViewContainerRef
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
		);
		
	}

}

@Component({
	selector:'test-page',
	template:'<test-component><span>asdasd</span></test-component>'
})
export class TestPage extends BaseComponent{

}