import { Injectable, Pipe, PipeTransform, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { LanguageService } from './service';

@Injectable()
@Pipe({name: 'translate',pure:false})
export class TranslatePipe extends AsyncPipe implements OnDestroy{

	constructor(_ref: ChangeDetectorRef,protected translate: LanguageService) {
		super(_ref);
	}

	transform(value: any): any {
		return this.translate.getSync(value);
		//return super.transform(this.translate.get(value));
	}

	ngOnDestroy(){
		super.ngOnDestroy();
	}
}