import { ModalController, LoadingController, ToastController, MenuController } from '@ionic/angular';
import { Component, OnInit, Renderer2, ElementRef, Inject, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from './language/module';
import { LoadIndicatorComponent } from './common';
//import { OverlayController } from './common';

export class PageBase{

	protected app_menu_modal : HTMLIonModalElement;
	protected menuComponentClass : any;
	protected current_modal : HTMLIonModalElement;
	protected loading_modal : Promise<HTMLIonModalElement|HTMLIonLoadingElement>;
	protected pleaseWait : string = 'Please wait';
	protected toasts : HTMLIonToastElement[] = [];
	

	public constructor(
		protected activatedRoute: ActivatedRoute,
		protected translate : LanguageService,
		protected modalController : ModalController,
		protected loadingctrl: LoadingController,
		protected toastCtrl : ToastController,
		protected mctrl : MenuController,
		protected ele : ElementRef,
		protected renderer : Renderer2,
		protected router : Router,
		//protected overlayCtrl : OverlayController,
		@Optional() @Inject('LoadIndicatorComponent') protected loadIndicatorComponent ?: {new(...args):any},
	){
	}

	public async showAppMenu(dismissCallback:(data)=>void = undefined){
		return this.showModal(this.menuComponentClass,true);
	}

	public async hideAppMenu(){
		return this.hideModal();
	}

	async presentToast(tag : string, data ?: Array<any>, dismissOld : boolean = true) {
		if(dismissOld){
			for(let i of this.toasts) i.dismiss();
			this.toasts.slice(0,this.toasts.length);
		}
		let txt = tag;
		if(data)
			for(let i in data)
				txt = txt.replace(`{{${i}}}`,data[i])
		const toast = await this.toastCtrl.create({
			message: txt,
			duration: 2000
		});
		this.toasts.push(toast);
		toast.present();
	}

	public async showModal(component,closeable=true,dismissCallback:(data)=>void = undefined,param = {}){
		if(this.current_modal !== undefined) return;
		this.current_modal = null;
		if(closeable)
			this.current_modal = await this.modalController.create({
				component: component
				,componentProps: {...param,config:param}
			});
		else
			this.current_modal = await this.modalController.create({
				component: component
				,backdropDismiss:false
				,swipeToClose:false
				,keyboardClose: false
				,componentProps: {...param,config:param}
			});

		this.current_modal.onDidDismiss().then(data=>{
			this.current_modal = undefined;
			if(dismissCallback)
				dismissCallback(data);
		});
		return this.current_modal.present().then(()=>this.current_modal);
	}

	public async hideModal(){
		let r = this.current_modal.dismiss();
		this.current_modal = undefined;
		return r;
	}

	public showLoading() {
		if(!this.loading_modal){
			this.loading_modal = new Promise(async (r,e)=>{
				let modal : HTMLIonModalElement|HTMLIonLoadingElement;
				if(this.loadIndicatorComponent){
					modal = await this.modalController.create({
						component: this.loadIndicatorComponent,
						canDismiss:false,
					});					
				}
				else{
					modal = await this.loadingctrl.create({
						message: this.translate.getSync('please_wait'),
					});
				}
				await modal.present();
				r(modal);
			});
		}
		return this.loading_modal;
	}

	public async hideLoading(){
		if(this.loading_modal){
			let modal = await this.loading_modal;
			this.loading_modal = null;
			return modal.dismiss();
		}
		return new Promise<void>(r=>r());
	}

	openMenu(){
		const menu = this.findFirstIonMenuAboveCurrentNode();
		if(menu){
			this.mctrl.enable(true,menu.menuId);
			this.mctrl.open(menu.menuId);
		}
	}

	closeMenu(){
		const menu = this.findFirstIonMenuAboveCurrentNode();
		if(menu){
			this.mctrl.enable(true,menu.menuId);
			this.mctrl.close(menu.menuId);
		}
	}

	toggleMenu(){
		const menu = this.findFirstIonMenuAboveCurrentNode();
		if(menu){
			this.mctrl.enable(true,menu.menuId);
			this.mctrl.toggle(menu.menuId);
		}
	}

	findFirstIonMenuAboveCurrentNode() {
		let currentNode = this.ele.nativeElement;
		
		while (currentNode) {
			if (currentNode.tagName === 'ION-MENU') {
				// Das erste ion-menu-Element über dem aktuellen Knoten gefunden
				console.log('Gefundenes ion-menu:', currentNode);
				return currentNode;
			}
			let res = currentNode.querySelector('ion-menu');
			if(res) {
				// Das erste ion-menu-Element über dem aktuellen Knoten gefunden
				console.log('Gefundenes ion-menu:', res);
				return res;
			}
			currentNode = this.renderer.parentNode(currentNode);
		}
	}


	goBack(){
		//const router = this.activatedRoute.parent.snapshot.router;
		const router = this.router;
		const navigation = router.getCurrentNavigation();

		if (navigation && navigation.previousNavigation) {
			const previousUrl = navigation.previousNavigation.finalUrl;
			router.navigateByUrl(previousUrl);
		} else {
			router.navigateByUrl('/');
		}
	}
}