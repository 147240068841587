import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuController } from '@ionic/angular';

import { APIService } from '../api/module';
import { InstanceSelector } from '../instance-selector/module';

@Injectable({
	providedIn: 'root'
})
export class Guard implements CanActivate {

	constructor(
		protected api : APIService,
		protected router : Router,
		protected menuctrl : MenuController,
		protected isel : InstanceSelector,
	){
		api.auth.onDeauth.subscribe(()=>{
			const isGuardActive = this.isGuardActive(this.router.routerState.snapshot.root);

			 if (isGuardActive) {
				 // Führen Sie die Umleitung durch, da der Guard aktiv ist
				 this.router.navigate(["auth"], { queryParams: { redirect: btoa(this.router.routerState.snapshot.url) } });
			 }
		});
	}
	
	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		try{
			await this.isel.inited;
			if(!this.isel.getURI()) return false;
			await this.api.ready;
			await this.api.auth.ready;
			let d = await this.api.auth.checkAuth();
			if(d) return true;
		}
		catch(e){
			console.error('error in AuthGuard');
			console.log(e)
		}
		this.router.navigate(["auth"],{queryParams:{redirect:btoa(state.url)}});
		return false;
	}

	// Hilfsfunktion, um zu überprüfen, ob der Guard auf der aktuellen Route aktiv ist
	private isGuardActive(route: ActivatedRouteSnapshot): boolean {
		if (route.routeConfig && route.routeConfig.canActivate && route.routeConfig.canActivate.includes(Guard)) {
			return true;
		}
		// Überprüfen Sie die Elternroute, wenn vorhanden
		if (route.parent) {
			return this.isGuardActive(route.parent);
		}
		return false;
	}
}
