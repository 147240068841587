import { Component, Renderer2, OnInit, ViewChild, ElementRef, AfterViewInit, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController,LoadingController,AlertController,ToastController,MenuController,NavParams,CheckboxChangeEventDetail } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Location } from "@angular/common";
import { GoogleMap } from '@capacitor/google-maps';

import { ClassMeta, ObjDB, objectMeta } from 'dissys';
import { LanguageService } from '../language/module';
import { BaseComponent } from './common';

@Component({
	selector:'types-selector',
	template:`
	<ion-list>
		<ion-item *ngFor="let i of types">
			<ion-label>{{i.qualname+'_list'|translate}}</ion-label>
			<ion-checkbox slot="end" (ionChange)="toggleItem(i,$event)" [indeterminate]="!selectedTypes.has(i) && baseSelected(i)" [checked]="selectedTypes.has(i)"></ion-checkbox>
		</ion-item>
	</ion-list>
	`,
	styles:[`
	`],
	providers:[
		{ 
	      provide: NG_VALUE_ACCESSOR,
	      multi: true,
	      useExisting: forwardRef(() => TypesSelector),
	    }
	],
})
export class TypesSelector extends BaseComponent{

	protected _db : ObjDB;
	protected _base : Array<string | ClassMeta> = [objectMeta];
	protected base : ClassMeta[] = [objectMeta];
	protected types : Array<ClassMeta> = [];
	protected selectedTypes = new Set<ClassMeta>([objectMeta]);

	constructor(
		activatedRoute : ActivatedRoute,
		translate : LanguageService,
		modalController : ModalController,
		loadingctrl : LoadingController,
		toastCtrl : ToastController,
		mctrl : MenuController,
		ele : ElementRef,
		renderer : Renderer2,
		router : Router,
		public alertController: AlertController,
		location : Location,
		navparam : NavParams,
		protected changes : ChangeDetectorRef
	){
		super(
			activatedRoute,
			translate,
			modalController,
			loadingctrl,
			toastCtrl,
			mctrl,
			ele,
			renderer,
			router,
			navparam,
		);
		this.pullRouteArgs();
	}

	@Input()
	set db(db : ObjDB){
		this._db = db;
	}

	get db(){
		return this._db;
	}

	@Input()
	set value(t : Array<string|ClassMeta>){
		this.selectedTypes = new Set();
		for(let i of t){
			if(typeof i === 'string'){
				if(!this.db) continue;
				this.selectedTypes.add(this.db.getLoader(i).meta);
			}
			else{
				this.selectedTypes.add(i);
			}
		}
	}

	get value(){
		return this.getBaseTypes();
	}

	@Input()
	set baseTypes(t : Array<string | ClassMeta>){
		if(this._base === t) return;
		this._base = t
		this.reload();
	}

	get baseTypes() : ClassMeta[]{
		return this.base;
	}

	reload(){
		this.base = [];
		this.types = [];
		let oldSelection = this.selectedTypes;
		this.selectedTypes = new Set();
		for(let base of this._base){
			if(typeof base === 'string'){
				if(!this.db) continue;
				this.base.push(this.db.getLoader(base).meta);
			}
		}
		if(this.base.length == 0) return;
		this.types = [];
		let types = new Set<ClassMeta>();
		let stack = [...this.base];
		while(stack.length > 0){
			let i = stack.shift();
			if(types.has(i)) continue;
			types.add(i);
			for(let j of i.children) if(!types.has(j)) stack.push(j);
		}
		for(let i of oldSelection){
			if(types.has(i)) this.selectedTypes.add(i);
		}
		if(this.selectedTypes.size == 0) this.selectedTypes = new Set(this.base);
		this.types = Array.from(types);
	}

	ngOnInit(){
		super.ngOnInit();
		this.reload();
	}

	ionViewWillEnter(){
		this.reload();
	}

	getBaseTypes() : ClassMeta[]{
		let types = Array.from(this.selectedTypes);
		let bases = [types.shift()];
		while(types.length > 0){
			let i = types.shift()
			if(!bases.some(j=>j.isBasetypeOf(i))){
				bases.push(i);
			}
		}
		return bases;
	}

	protected toggleItem(t : ClassMeta, e : CustomEvent<CheckboxChangeEventDetail>){
		e.preventDefault();
		e.stopPropagation();
		const checkboxElement = e.target as HTMLIonCheckboxElement; // Zugriff auf das HTML-Element der Checkbox
		const checkboxComponent = checkboxElement.closest('ion-checkbox'); // Zugriff auf die IonCheckbox-Komponente
		console.log(checkboxComponent)
		if(e.detail.checked){
			this.selectedTypes.add(t);
		}
		else{
			this.selectedTypes.delete(t);
			e.detail.checked = null;
			checkboxComponent.checked = null;
			checkboxComponent.indeterminate = !this.selectedTypes.has(t) && this.baseSelected(t);
		}
		//this.changes.detectChanges();
	}

	protected baseSelected(t : ClassMeta){
		return [...this.selectedTypes].some(j=>j.isBasetypeOf(t));
	}
}